html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content*/
    -webkit-overflow-scrolling: touch;
}

body {
    position: relative;
    color: #48494c;
}

@media screen and (max-width: 767px){
   body{
       -webkit-text-size-adjust: 104%;
   }
}

.page-wrapper {
    background: #ffffff;
    position: relative;
    z-index: 1;
}
.container {
    max-width: 1230px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}
.container-md {
    max-width: 1160px;
    margin: 0 auto;
}
.container-lg {
    max-width: 1340px;
    margin: 0 auto;
}
@media screen and (max-width: 767px) {
    .container-md {
        padding: 0 15px;
    }
}
.container-narrow {
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
}
.container-narrow-lg {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
}
.content-block {
    margin-bottom: 20px;
    margin-top: 20px;
}
@media screen and (min-width: 768px){
    .main-content {
        position: relative;
        padding-left: 90px;
    }
    .content-block {
        margin-bottom: 30px;
        margin-top: 30px;
    }
    .main-content.main-content--no-console{
        padding-left: 0;
    }
}

.content-block--lg + .content-block--lg{
    margin-top: 40px;
}
@media screen and (min-width: 768px){
    .content-block--lg + .content-block--lg{
        margin-top: 100px;
    }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    body {
        width: 982px;
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: 320px;
        max-width: 100%;
        overflow-x: hidden;
    }
}

.bulletinDisk{
    border-bottom: 1px solid #0082A4;
    margin-bottom: 25px;
}

.areaLink span{
    margin-left: 8px;
    font-size: 21px;
    top: 2px;
    position: relative;
}

#lmb{
    background: #ffffff;
    margin: 0 auto;
    display: inherit;
}

.oax .oax_detail_main > .oax_tile_teaser {display: none !important}
.oax .oax_region_container {display: none !important;}

@media screen and (max-width: 767px){
    .container--no-padding-xs {
        padding: 0;
        overflow: hidden;
    }
}

