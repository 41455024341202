.scroll-top {
    position: absolute;
    bottom: 0;
    right: 15px;
    background: #fff;
    width: 45px;
    height: 35px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 22px;
    padding: 6px 0;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}