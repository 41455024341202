.img-text-block {
    margin: 0 -20px;
}
@media screen and (min-width: 768px) {
    .img-text-block {
        padding: 14px 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    }
}
.img-text-block__img {
    width: 100%;
}
@media screen and (min-width: 768px) {
    .img-text-block__img {
        width: 83%;
    }
    .img-text-block__content {
        width: 17%;
        position: relative;
        z-index: 1;
    }
}
.img-text-block__content__text {
    position: relative;
    padding: 45px 15px 30px;
    font-size: 16px;
    line-height: calc(24/16);
}
@media screen and (min-width: 768px) {
    .img-text-block__content__text {
        padding: 60px 45px 40px;
        width: 386px;
        transform: translateX(-48%);
        margin-top: 87%;
    }
}
.img-text-block__content__text__count {
    position: absolute;
    top: -70px;
    left: 15px;
    font-size: 100px;
    line-height: calc(120/100);
    text-shadow: 0 0 5px rgba(0,0,0,.25);
}
@media screen and (min-width: 768px) {
    .img-text-block__content__text__count {
        top: -71px;
        left: -6px;
    }
}

/* centered content */
.img-text-block--centered {
    padding: 30px 0 50px;
    margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
    .img-text-block--centered {
        margin-bottom: 0;
        align-items: center;
        padding: 50px 0;
    }
    .img-text-block--centered .img-text-block__content__text {
        margin-top: 0;
    }
}
.img-text-block--centered .img-text-block__content {
    padding: 0 15px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}
@media screen and (min-width: 768px) {
    .img-text-block--centered .img-text-block__content {
        padding: 0;
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;
    }
}
.img-text-block--centered .img-text-block__content__text {
    padding: 20px 20px 25px;
}
@media screen and (min-width: 768px) {
    .img-text-block--centered .img-text-block__content__text {
        padding: 70px 90px;
        width: 350px;
        transform: translateX(-50%);
    }
}

/* text box left */
@media screen and (min-width: 768px) {
    .img-text-block--text-left {
        justify-content: flex-end;
    }
    .img-text-block--text-left .img-text-block__img {
        order: 2;
    }
    .img-text-block--text-left .img-text-block__content {
        order: 1;
        text-align: right;
    }
    .img-text-block--text-left .img-text-block__content__text {
        transform: translateX(0);
    }
    .img-text-block--text-left .img-text-block__content__text__count {
        left: auto;
        right: -6px;
    }
}

/* small */
@media screen and (min-width: 768px) {
    .img-text-block--sm .img-text-block__img {
        width: 75%;
    }
    .img-text-block--sm .img-text-block__content{
        width: 25%;
        padding-top: 13%;
    }
}

.img-text-block__content .btn {
    padding: 10px;
}

.img-text img{
    width: 100%;
}