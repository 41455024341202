.category-list {
    padding: 25px 0;
    margin-left: -12px;
    font-size: 16px;
}
@media screen and (min-width: 768px) {
    .category-list {
        font-size: 17px;
        padding: 25px 0 40px;
    }
}
.category-list > li {
    padding-right: 12px;
    padding-left: 12px;
}
@media screen and (max-width: 767px) {
    .category-list li {
        display: block;
    }
    .category-list li + li {
        display: block;
        margin-top: 20px;
    }
}
.category-list__icon {
    font-size: 20px;
    margin-right: 10px;
    vertical-align: -.15em;
}