.slide {
    position: relative;
    background: #fff;
}
.slide h2,
.slide .h2 {
    font-size: 34px;
}
.slide-img__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px 0;
}
@media screen and (min-width: 768px) and (max-width: 992px){
    .slide-img__content .btn-xl {
        padding: 10px;
    }
    .slide-img__content h2 {
        font-size: 40px;
    }
    .slide-img__content .btn-xl .hidden-xs {
        font-size: 14px;
    }
    .slide-img__content .btn-xl .fz24{
        font-size: 16px;
    }
    .slide-img__content .row.mt20 {
        margin-top: 15px;
    }
    /*.interactive-map-infobox {*/
        /*width: 410px;*/
    /*}*/
}
.map-slide {
    padding: 30px 0 20px 0;
}
.map-slide__typeahead .btn-grey {
    border: 1px solid #ced2d9;
    border-left: 0;
}
.map-slide__typeahead .btn-grey:hover, .map-slide__typeahead .btn-grey:focus {
    cursor: default;
    background: #eeeff2;
    color: #86898d;
    outline: none;
}
.map-slide__typeahead {
    position: relative;
}
.map-slide__typeahead>.btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 50px;;
}
.map-slide__typeahead .map-slide__typeahead-submit-button {
    width: 50px;
    height: 50px;
}
.map-slide__typeahead .tt-menu {
    right: 0;
    background: #fafbfb;
    font-size: 16px;
    color: #48494c;
    padding: 12px 20px;
    border: 1px solid #ced2d9;
    border-top: 0;
}
.map-slide__typeahead .tt-highlight {
    font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    font-weight: 400;
    color: #00a4d0;
}
.map-slide__typeahead .tt-suggestion {
    cursor: pointer;
    padding: 3px 0;
}
.map-slide__typeahead .form-control {
    padding-left: 20px;
    padding-right: 55px;
}
.map-slide .twitter-typeahead {
    width: 100%;
    display: block !important; /*typeahead overwrite*/
    height: 50px;
}
@media screen and (min-width: 768px){
    .map-slide {
        background-image: url(/static/img/content/bgdoveandare.png);
        background-repeat: no-repeat;
        background-position: left bottom;
    }
    .slide h2,
    .slide .h2 {
        font-size: 48px;
    }

    /*Interactive map*/
    .interactive-map-regions a path,
    .interactive-map-regions--hover path,
    .js-map-info-text {
        cursor: pointer;
        opacity: 0;
        transition: 300ms ease;
    }
    .js-map-info-text {
        pointer-events: none;
    }
    /*disable hover when region is active*/
    .interactive-map-regions--hover a.is-active:hover, .interactive-map-regions--hover a.is-active {
        opacity: 0;
    }
    .interactive-map-regions a.is-active path,
    .interactive-map-regions--hover a path:hover ~ .js-map-info-text,
    .interactive-map-regions--hover a path:hover ~ path,
    .interactive-map-regions--hover a path:hover ~ .js-map-info-text>path{
        opacity: 1;
    }
    .interactive-map-regions--hover a path:hover {
        opacity: .6;
    }
    .interactive-map.interactive-map {
        overflow: visible;
    }
    .interactive-map svg {
        overflow: visible;
        z-index: 1;
        position: relative;
        max-height: 100%;
    }
    .interactive-map-info {
        position: relative;
        z-index: 5;
    }
    .interactive-map-infobox {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 410px;
        display: none;
        font-size: 16px;
    }
    .interactive-map-infobox.is-active {
        display: block;
    }
    .interactive-map-infobox__top {
        background: #e7e9ec;
        padding: 30px;
    }
    .interactive-map-infobox__top .btn {
        padding: 8px 20px;
    }
    .interactive-map-infobox__bottom {
        padding: 15px 30px;
    }
    .interactive-map-infobox__bottom ul li {
        padding-left: 15px;
        position: relative;
        margin-bottom: 5px;
    }
    .interactive-map-infobox__bottom ul li:before{
        content: "\E028";
        font-family: "iconfont";
        speak: none;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        text-rendering: auto;
        position: absolute;
        left: 0;
        top: 5px;
        font-size: 11px;
    }
    .interactive-map-infobox__close {
        background: #ced2d9;
        position: absolute;
        top: 0;
        right: -40px;
        width: 40px;
        height: 40px;
        padding: 10px;
        cursor: pointer;
        font-size: 17px;
    }
    .interactive-map-infobox__close:hover {
        background: #00A4D0;
    }
    .interactive-map-text text, .interactive-map-text tspan,
    .interactive-map-city tspan, .interactive-map-city text {
        font-family: 'RobotoLight', 'RobotoLight-latin-ext', 'RobotoLight-cyrillic', sans-serif;
        font-weight: 400;
        font-size: 18px;
    }
    .interactive-map-text tspan, .interactive-map-city tspan {
        font-size: 14px;
    }
    .interactive-map-city text, .interactive-map-city tspan {
        font-size: 12px;
    }
    .interactive-map-text tspan {
        text-transform: uppercase;
    }
    .js-map-info-text text, .js-map-info-text text tspan {
        font-family: 'RobotoLight', 'RobotoLight-latin-ext', 'RobotoLight-cyrillic', sans-serif;
        font-weight: 400;
        font-size: 16px;
    }
    .interactive-map-text g>g>path {
        transform: translateY(-2px);
    }
    .interactive-map-city {
        opacity: 0;
        transition: opacity 300ms ease-in-out;
    }
    .interactive-map-city.is-visible {
        opacity: 1;
    }
}
@media screen and (min-width: 1200px){
    .interactive-map-infobox {
        width: 475px;
    }
}

/* slide heads*/
.slide__head {
    margin-bottom: 55px;
}

/* slides with padding */
.slide--padding{
    padding: 50px 0;
}
@media screen and (min-width: 768px) {
    .slide--padding{
        padding: 85px 0;
    }
}
.slide--padding-sm {
    padding: 15px 0;
}
@media screen and (min-width: 768px) {
    .slide--padding-sm {
        padding: 30px 0;
    }
}
.slide--padding-md {
    padding: 30px 0;
}
@media screen and (min-width: 768px) {
    .slide--padding-md {
        padding: 50px 0;
    }
}

@media screen and (max-width: 767px) {
    .slide--no-bottom-padding-xs {
        padding-bottom: 0;
    }
}
.slide--no-top-padding.slide--no-top-padding {
    padding-top: 0;
}

.pre-footer-slide {
    padding: 50px 0 25px;
    @media screen and (min-width: 768px) {
        padding: 100px 0 50px;
    }
}
.pre-footer-slide__booking {
    @media screen and (max-width: 1199px){
        margin-bottom: 20px;
    }
}
.pre-footer-slide__booking a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 768px){
        padding: 5px 0;
    }
}