@media screen and (max-width: 767px){
    html.console-is-open, html.console-is-open body {
        overflow: hidden;
    }
}
.console {
    width: 100%;
    transform: translateZ(0);
}
.console__item {
    position: relative;
    width: 25%;
}
.console__item a.is-active {
    background: #00a4d0;
}
.console__item>a{
    display: block;
    padding: 10px 21px 21px 21px;
    border-right: 1px solid #fff;
}

p.mclable {
    margin: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 33px;
}

.console__item:nth-child(4) > a {
    border-right: 0;
}
.console-close {
    font-size: 40px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}
.console__item .icon {
    font-size: 20px;
}
.console__item>a:hover {
    background: #00a4d0;
}
.console__item--highlight {
    background: #00a4d0;
}
.console-outer.is-open .console__item--highlight {
    background: #00405e;
}
@media screen and (min-width: 768px){
    .console-outer {
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 1005;
    }
    .console-outer.is-open {
        width: 100%;
    }
    .console-outer.affix {
        z-index: 2;
    }
    .console-outer.affix.is-open {
        width: 100%;
        z-index: 999;
    }
    .console {
        width: 89px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1004;
    }
    .console__item {
        width: auto;
    }
    .console__item .icon {
        font-size: 25px;
    }
    .console__item>a{
        padding: 14px 0;
        border-right: 0;
        border-bottom: 1px solid #003148;
        font-size: 12px;
    }
}
@media screen and (min-width: 1400px){
    /*affix console*/
    html.console-is-open, html.console-is-open body {
        overflow: hidden;
    }
    .console-outer.affix {
        top: 75px;
        z-index: 2;
        position: fixed;
    }

}
@media screen and (max-width: 991px) and (min-width: 768px) {
    .console-outer.affix {
        top: 0;
    }
}
@media screen and (max-height: 870px) and (min-width: 768px) {
    .console__item>a {
        padding: 15px 0;
        font-size: 12px;
    }
    .console__item>a>.icon {
        font-size: 25px;
    }
}
@media screen and (max-height: 690px) and (min-width: 768px) {
    .console__item>a {
        padding: 10px 0;
        font-size: 12px;
    }
    .console__item>a>.icon {
        font-size: 20px;
        margin-bottom: 10px;
    }
}
@media screen and (max-height: 510px) and (min-width: 768px){
    .console__item>a {
        padding: 5px 0;
    }
}
@media screen and (min-width: 768px) and (max-width: 992px){
    .console__item>a {
        padding: 14px 0;
        font-size: 15px;
    }
}


.offers-block{
    position: relative;
}

.rowbuttons-green{
    position: absolute;
    top: 0;
    right:0;
}

.rowbuttons-blue a{
    background: #0083a6;
    padding: 10px 17px;
}

.rowbuttons-blue a:hover{
    background: #006390;
    color: #fff;
}

.rowbuttons-green a{
    background: #83b81a;
    padding: 10px 17px;
}

.rowbuttons-green a:hover{
    background: #9cc648;
    color: #fff;
}

.rowbuttons-blue-light a{
    background: #58c4e0;
    padding: 10px 17px;
}

.rowbuttons-blue-light a:hover{
    background: #006390;
    color: #fff;
}

/* placeholder */
@media screen and (min-width: 768px) {
    .console-placeholder {
        width: 89px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
    }
}
.console-is-open .console-placeholder {
    position: static;
}