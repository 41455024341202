.img-teaser__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    padding: 0 10px;
    z-index: 1;
}
@media screen and (min-width: 768px) {
    .img-teaser__content {
        padding: 0 30px;
    }
}
@media screen and (min-width: 1200px) {
    .img-teaser__content {
        padding: 0 70px;
    }
}
.img-teaser__content__hl {
    margin-bottom: 10px;
    line-height:calc(44/50);
    font-size: 25px;
    text-shadow: 0 0 6px rgba(0,0,0, .7);
    color: #fff;
}
@media screen and (min-width: 768px) {
    .img-teaser__content__hl {
        font-size: 32px;
    }
}
@media screen and (min-width: 1200px) {
    .img-teaser__content__hl {
        font-size: 50px;
    }
}
.img-teaser__content__subline {
    font-size: 14px;
    line-height: 1;
    margin-top: 15px;
}
@media screen and (min-width: 768px) {
    .img-teaser__content__subline {
        font-size: 20px;
        margin-top: 25px;
    }
}
@media screen and (min-width: 1200px) {
    .img-teaser__content__subline {
        font-size: 25px;
    }
}
.img-teaser__content__btn {
    position: relative;
    font-size: 16px;
    padding: 10px 35px 8px 15px;
    margin-top: 15px;
}
.img-teaser__content__btn-sm {
    padding: 10px 15px;
}
@media screen and (min-width: 768px) {
    .img-teaser__content__btn {
        font-size: 18px;
        padding: 14px 55px 12px 40px;
        margin-top: 40px;
    }
    .img-teaser__content__btn-sm {
        padding: 14px 24px;
        font-size: 16px;
    }
}
.img-teaser__content__btn>.icon {
    position: absolute;
    right: 10px;
    font-size: 15px;
    top: 50%;
    transform: translateY(-50%);
}
@media screen and (min-width: 768px) {
    .img-teaser__content__btn>.icon {
        right: 25px;
    }
}
.img-teaser:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(to bottom,transparent 0,rgba(0,0,0,.8) 100%);
    opacity: .7;
    transition: opacity 250ms ease-in-out;
}
.img-teaser:hover:after {
    opacity: 0;
}