/*Backgrounds*/
.bg-primary {
    background-color: #00a4d0;
}
a.bg-primary:hover {
    background-color: #006390;
    color: #fff;
}
.bg-primary-dark {
    background-color: #0083a6;
}
.bg-secondary {
    background-color: #00405e;
}
.bg-grey {
   background-color: #eeeff2;
}
.bg-grey-light {
    background-color: #f0f2f5;
}
.bg-grey-md {
    background: #ced2d9;
}
.bg-blue {
    background-color: #006390;
}
.bg-green {
    background-color: #83b81a;
}
.bg-red {
    background: #b81a1a;
}
.bg-gradient-blueyellow {
    background: #cbd300;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2NiZDMwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMGE0ZDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #cbd300 0%, #00a4d0 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#cbd300), color-stop(100%,#00a4d0));
    background: -webkit-linear-gradient(top,  #cbd300 0%,#00a4d0 100%);
    background: -o-linear-gradient(top,  #cbd300 0%,#00a4d0 100%);
    background: -ms-linear-gradient(top,  #cbd300 0%,#00a4d0 100%);
    background: linear-gradient(to bottom,  #cbd300 0%,#00a4d0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbd300', endColorstr='#00a4d0',GradientType=0 );
}
.bg-gradient-blue {
    background: #004f73;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwNGY3MyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMGE0ZDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #004f73 0%, #00a4d0 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#004f73), color-stop(100%,#00a4d0));
    background: -webkit-linear-gradient(top,  #004f73 0%,#00a4d0 100%);
    background: -o-linear-gradient(top,  #004f73 0%,#00a4d0 100%);
    background: -ms-linear-gradient(top,  #004f73 0%,#00a4d0 100%);
    background: linear-gradient(to bottom,  #004f73 0%,#00a4d0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#004f73', endColorstr='#00a4d0',GradientType=0 );
}

.bg-white {
    background: #fff;
}

.bg-dark {
    background: #262626;
    color: #fff;
}
.bg-green-light {
    background: #8BBE50;
}

/*Colors*/
.text-primary { color: #00a4d0; }
.text-primary-dark { color: #0082a4; }
.text-secondary { color: #013e59; }
.text-blue { color: #006390; }
.text-blue-light { color: #006b87; }
.text-white { color: #fff; }
.text-grey { color: #86898d; }
.text-grey-md { color: #A5A8AE; }
.text-grey-light { color: #ced2d9; }
.text-yellow { color: #ffaa00; }
.text-green { color: #82b80d; }
.text-red { color: #d51f0c; }
.text-black { color: #000; }
.text-dark { color: #262626; }

.text-green-light { color: #9FC54A; }
.text-yellow-light { color: #FFFF33; }


.font-soho{
    font-family: 'SohoPro', sans-serif;
}

.hidden-sm{
    @media screen and (min-width: 768px){
       display: none;
    }
}
/*Helper*/
.strong, strong {
    font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    font-weight: 400;
}
.italic { font-style: italic; }
.d-ib { display: inline-block; }
.d-b { display: block; }

.text-middle { vertical-align: middle; }

@media screen and (min-width: 992px) {
    .text-right-md {
        text-align: right;
    }
}

.isClickable {
    cursor: pointer;
}

.text-transform-none{
    text-transform: none;
}
.icon-rotate-25 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(25deg);
    -ms-transform: rotate(25deg);
    transform: rotate(25deg);
    display: inline-block;
}

/*Margins*/
.mt5 { margin-top: 5px; }
.mt10 { margin-top: 10px; }
.mt15 { margin-top: 15px; }
.mt20 { margin-top: 20px; }
.mt25 { margin-top: 25px; }
.mt30 { margin-top: 30px!important; }
.mt30-xs { @media screen and (max-width: 767px) {
    margin-top: 30px!important;
} }

.mb0  { margin-bottom: 0; }
.mb5  { margin-bottom: 5px; }
.mb10 { margin-bottom: 10px; }
.mb15 { margin-bottom: 15px; }
.mb20 { margin-bottom: 20px; }
.mb40 { margin-bottom: 40px; }
.mb50-xs { @media screen and (max-width: 767px) {
    margin-bottom: 50px;
} }

.mr5 { margin-right: 5px; }
.mr10 { margin-right: 10px; }
.mr30 { margin-right: 30px; }
.mr50 { margin-right: 50px; }
.mr80 { margin-right: 80px; }
@media screen and (max-width: 767px) {
    .mr50 { margin-right: 10px; }
}

.mx-auto{
    margin-left: auto;
    margin-right: auto;
}
@media screen and (min-width: 768px) {
    .mr-md-0{
        margin-right: 0;
    }
}

.ml15 { margin-left: 15px; }


.mb30 { margin-bottom: 20px; }
.mb60 { margin-bottom: 30px; }
.mb90 { margin-bottom: 60px; }
.mt60 { margin-top: 30px; }
.mt90 { margin-top: 60px; }
@media screen and (min-width: 768px){
    .mb30 { margin-bottom: 30px; }
    .mb60 { margin-bottom: 60px; }
    .mb90 { margin-bottom: 90px; }
    .mt60 { margin-top: 60px; }
    .mt90 { margin-top: 90px; }
}

@media screen and (max-width: 767px){
    .mb10-xs{
        margin-bottom: 10px;
    }
    .text-center-xs {
        text-align: center;
    }
}
.no-margin {margin: 0;}

/*Paddings*/
.pr10 { padding-right: 10px; }
.pr20 { padding-right: 20px; }
.pt20 { padding-top: 20px; }
.pt30 { padding-top: 30px; }
.pb30 { padding-bottom: 30px; }


/*Embed Responsive*/
.embed-responsive { width: 100%; }
.embed-responsive.embed-responsive-portal-slider {
    padding-top: calc(11/16*100%);
}
.embed-responsive.embed-responsive-1by1 {
    padding-top: 100%;
}
.embed-responsive.embed-responsive-1by2 {
    padding-top: 150%;
}
.embed-responsive.embed-responsive-9by6 {
    padding-top: calc(6/9*100%);
}
.embed-responsive.embed-responsive-9by8{
    padding-top: calc(8/9*100%);
}
.embed-responsive.embed-responsive-11by4{
    padding-top: calc(4/11*100%);
}
.embed-responsive.embed-responsive-14by5{
    padding-top: calc(5/14.5*100%);
}
.embed-responsive.embed-responsive-14by7{
    padding-top: calc(7/14.24*100%);
}
.embed-responsive.embed-responsive-16by3 {
    padding-top: calc(3/16.01*100%);
}
.embed-responsive.embed-responsive-9by5 {
    padding-top: calc(5/9*100%);
}
.embed-responsive.embed-responsive-7by2 {
    padding-top: calc(2/7*100%);
}
.embed-responsive.embed-responsive-9by4 {
    padding-top: calc(4/9*100%);
}
.embed-responsive.embed-responsive-29by14 {
    padding-top: calc(14/29*100%);
}
.embed-responsive.embed-responsive-gallery-highlights{
    height: auto;
}
.embed-responsive.embed-responsive-2by1 {
    padding-top: calc(1/2*100%);
}
.embed-responsive.embed-responsive-21by3 {
    padding-top: calc(3.5/21.5*100%);
}
.embed-responsive.embed-responsive-25by12 {
    padding-top: calc(12/24.5*100%);
}
.embed-responsive.embed-responsive-27by16 {
    padding-top: calc(16/27*100%);
}
.embed-responsive.embed-responsive-10by9 {
    padding-top: calc(9/10*100%);
}
@media screen and (max-width: 767px) {
    .embed-responsive.embed-responsive-16by9-xs {
        padding-top: calc(9/16*100%);
    }
    .embed-responsive.embed-responsive-3by2-xs {
        padding-top: calc(2/3*100%);
    }
}
@media screen and (min-width: 768px){
    .embed-responsive.embed-responsive-portal-slider {
        padding-top: 44%;
    }
    .embed-responsive.embed-responsive-gallery-highlights{
        padding-top: 105.1%;
    }
}
@media screen and (max-width: 767px){
    .embed-responsive-change.embed-responsive-1by1,
    .embed-responsive-change.embed-responsive-14by7,
    .embed-responsive-change.embed-responsive-29by14,
    .embed-responsive-change.embed-responsive-21by3,
    .embed-responsive-change.embed-responsive-21by3,
    .embed-responsive.embed-responsive-29by59 {
        padding-top: calc(1/2*100%);
    }
    .embed-responsive-change.embed-responsive-16by3 {
        padding-top: calc(6/7*100%);
    }
    .embed-responsive-change--header-img.embed-responsive-16by3{
        padding-top: calc(3 / 8 * 100%);
    }
    .embed-responsive.embed-responsive-gallery-highlights .embed-responsive-item {
        position: relative;
    }
    .gallery__border .embed-responsive-change.embed-responsive-4by3 {
        padding-bottom: 30%;
    }
    .embed-responsive.embed-responsive-change-to-8by7 {
        padding-top: calc(7/8*100%);
    }
    .embed-responsive.embed-responsive-change-to-8by13 {
        padding-top: calc(13/8*100%);
    }
    .embed-responsive.embed-responsive-change-to-1by1 {
        padding-top: 100%;
    }
    .embed-responsive.embed-responsive-change-to-16by9 {
        padding-top: calc(9/16*100%);
    }
    .embed-responsive.embed-responsive-change-2by1-xs {
        padding-bottom: 0;
        padding-top: calc(1/2*100%);
    }
}

.embed-responsive.embed-responsive-content-video {
    padding-top:57.85714285714286%;
}
.embed-responsive.embed-responsive-hero-img {
    padding-top:68.75%;
}
@media screen and (min-width: 768px){
    .embed-responsive.embed-responsive-hero-img {
        padding-top:46.52777777777778%;
    }
}
.embed-responsive.embed-responsive-hero-img-md {
    padding-top:75%;
}
@media screen and (min-width: 768px){
    .embed-responsive.embed-responsive-hero-img-md {
        padding-top:34.07142857142857%;
    }
}
.embed-responsive.embed-responsive-hero-video {
    padding-top:51.07142857142857%;
}

.embed-responsive.embed-responsive-img-teaser-half,
.embed-responsive.embed-responsive-img-teaser,
.embed-responsive.embed-responsive-img-teaser-lg {
    padding-top:68.02325581395349%;
}
@media screen and (min-width: 768px) {
    .embed-responsive.embed-responsive-img-teaser-half {
        padding-top:34%;
    }
    .embed-responsive.embed-responsive-img-teaser {
        padding-top:58.65184155663655%;
    }
    .embed-responsive.embed-responsive-img-teaser-lg {
        padding-top:50.71428571428571%;
    }
}

.embed-responsive.embed-responsive-content-img {
    padding-top:63.15789473684211%;
}
.embed-responsive.embed-responsive-14by9 {
    padding-top: calc(9/14*100%);
}

.embed-responsive.embed-responsive-17by6 {
    padding-top: calc(6/17*100%);
}
.embed-responsive.embed-responsive-29by21 {
    padding-top: calc(21/29*100%);
}
.embed-responsive.embed-responsive-2by3 {
     padding-top: calc(3/2*100%);
}
.embed-responsive.embed-responsive-29by59 {
     padding-top: calc(59/29*100%);
}
.embed-responsive.embed-responsive-8by13 {
    padding-top: calc(13/8*100%);
}
.embed-responsive.embed-responsive-32by29 {
    padding-top: calc(29/32*100%);
}
.embed-responsive.embed-responsive-20by9{
    padding-top: calc(9/20*100%);
}

.embed-responsive:before {
    display: block;
    content: "";
}

.object-fit-cover{
    object-fit: cover;
}

/*Row gutters*/
.row--gutter-with-10 {
    margin-right: -5px;
    margin-left: -5px;
}
.row--gutter-with-10>.col {
    padding-left: 5px;
    padding-right: 5px;
}
.row--gutter-with-20 {
    margin-left: -10px;
    margin-right: -10px;
}
.row--gutter-with-20>.col {
    padding-left: 10px;
    padding-right: 10px;
}
.moodboard .col>.row--gutter-with-10 .col {
    padding-bottom: 5px;
    padding-top: 5px;
}
.row--gutter-with-1 {
    margin-left: -.25px;
    margin-right: -.25px;
}
.row--gutter-with-1>.col {
    padding-left: .5px;
    padding-right: .5px;
}
.webcams .col>.row--gutter-with-1>.col {
    padding-bottom: .5px;
    padding-top: .5px;
}
.row--gutter-with-2 {
    margin-left: -1px;
    margin-right: -1px;
}
.row--gutter-with-2>.col {
    padding-left: 1px;
    padding-right: 1px;
}
.row--gutter-with-0 {
    margin-left: 0;
    margin-right: 0;
}
.row--gutter-with-0>.col {
    padding-left: 0;
    padding-right: 0;
}
.row.width-100 {
    width: 100%;
}
.row--gutter-width-4 {
    margin-left: -2px;
    margin-right: -2px;
}
.row--gutter-width-4 > .col {
    padding-left: 2px;
    padding-right: 2px;
}
.row--gutter-width-10 {
    margin-left: -5px;
    margin-right: -5px;
}
.row--gutter-width-10 > .col {
    padding-left: 5px;
    padding-right: 5px;
}

.row--gutter-flexible {
    margin-left: -7px;
    margin-right: -7px;
}
.row--gutter-flexible>.col {
    padding-left: 7px;
    padding-right: 7px;
}
@media screen and (min-width: 1200px) {
    .row--gutter-flexible {
        margin-left: -10px;
        margin-right: -10px;
    }
    .row--gutter-flexible>.col {
        padding-left: 10px;
        padding-right: 10px;
    }
}
.row--gutter-flexible-sm {
    margin-left: -5px;
    margin-right: -5px;
}
.row--gutter-flexible-sm>.col {
    padding-left: 5px;
    padding-right: 5px;
}
@media screen and (min-width: 1200px) {
    .row--gutter-flexible-sm {
        margin-left: -7px;
        margin-right: -7px;
    }
    .row--gutter-flexible-sm>.col {
        padding-left: 7px;
        padding-right: 7px;
    }
}

.row--vertical-gutter-flexible {
    margin-top: -14px;
}
.row--vertical-gutter-flexible > .col {
    margin-top: 14px;
}
@media screen and (min-width: 1200px) {
    .row--vertical-gutter-flexible {
        margin-top: -20px;
    }
    .row--vertical-gutter-flexible > .col {
        margin-top: 20px;
    }
}

.row--vertical-gutter-flexible-sm {
    margin-top: -10px;
}
.row--vertical-gutter-flexible-sm > .col {
    margin-top: 10px;
}
@media screen and (min-width: 1200px) {
    .row--vertical-gutter-flexible-sm {
        margin-top: -14px;
    }
    .row--vertical-gutter-flexible-sm > .col {
        margin-top: 14px;
    }
}

.row--vertical-gutter-4 {
    margin-top: -4px;
}
.row--vertical-gutter-4 > .col {
    margin-top: 4px;
}

.row--vertical-gutter-10 {
    margin-top: -10px;
}
.row--vertical-gutter-10 > .col {
    margin-top: 10px;
}


/*Center cols*/
.row--inline-block {
    font-size: 0;
    text-align: center;
}
.row--inline-block>.col {
    display: inline-block;
    float: none;
    font-size: 1rem;
    vertical-align: middle;
    text-align: left;
}
.row--inline-block--bottom>.col {
    vertical-align: bottom;
}

/*vertical gutter*/
.row--vertical-gutter-20 > .col {
    margin-top: 20px;
}
.row.row--vertical-gutter-20{
    margin-top: -20px;
}
@media screen and (max-width: 767px){
    .row--vertical-gutter-20 > .col {
        margin-top: 15px;
    }
    .row.row--vertical-gutter-20{
        margin-top: -15px;
    }
}

/*btn group with 1px padding*/
.btn-group-with-1>.btn {
    margin-right: 1px;
}
.btn-group-with-1>.btn:last-child {
    margin-right: 0;
}

.collapse__more {
    display: none;
}
.collapsed>.collapse__less {
    display: none;
}
.collapsed>.collapse__more {
    display: block;
}

/*Arrange*/
.arrange-xs {
    display: table;
    min-width: 100%;
    table-layout: auto;
}
.arrange__item-xs {
    display: table-cell;
    vertical-align: middle;
    float: none;
}
.arrange__item-xs--size-fill {
    width: 100%;
}
.arrange__item--bottom-xs {
    vertical-align: bottom;
}
.arrange__item--top-xs {
    vertical-align: top;
}

@media screen and (min-width: 768px){
    .arrange {
        display: table;
        min-width: 100%;
        table-layout: auto;
    }
    .arrange__item {
        display: table-cell;
        vertical-align: middle;
        float: none;
    }
    .arrange__item--size-fill {
        width: 100%;
    }
    .arrange__item--bottom {
        vertical-align: bottom;
    }
    .arrange__item--top {
        vertical-align: top;
    }
    .row--same-height__item {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }
    /*
     * the .row--same-height selector has to be after
     * .row--same-height__item to increase the specificity
     * in case of chaining the two selectors
     * (which is needed for nested rows)
     */
    .row--same-height {
        display: flex;
        flex-direction: row;
        /*align-items: stretch;*/
        /*justify-content: space-between;*/
        /*overflow: hidden;*/
    }
    .row--same-height:before,
    .row--same-height:after {
        display: none;
    }
    .row--same-height > .col {
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .row--same-height__item--fixed-height {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
    }
    .row--same-height__item__variable-height {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
    }
    .row--same-height--center {
        justify-content: center;
    }

    .flex-wrap {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 767px){

    .row--same-height__item-xs {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }
    /*
     * the .row--same-height selector has to be after
     * .row--same-height__item to increase the specificity
     * in case of chaining the two selectors
     * (which is needed for nested rows)
     */
    .row--same-height-xs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        /*align-items: stretch;*/
        /*justify-content: space-between;*/
        /*overflow: hidden;*/
    }
    .row--same-height-xs:before,
    .row--same-height-xs:after {
        display: none;
    }
    .row--same-height-xs > .col {
        display: flex;
        flex-direction: column;
        height: auto;
    }
}
.row--align-center {
    display: flex;
    align-items: center;
}
.row--flex {
    display: flex;
    flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
    .order-xs-first {
        order: -1;
    }
}

/*Divider*/
.divider {
    border-bottom: 1px solid #ced2d9;
    margin: 15px 0 20px 0;
}
/*top event badge*/
.top-event-badge {
    padding: 10px 22px;
    font-size: 16px;
}
/*dropdown*/
.dropdown-menu {
    border-radius: 0;
    border: 0;
    background-color: #FAFBFB;
    min-width: 100px;
    box-shadow: 0 4px 10px rgba(0,0,0,.175);
}

input.form-control.childageinput {
    width: 21%;
    float: left;
    margin-right: 5%;
}

input.form-control.childageinput.last {
    margin-right: 0;
}

label.childrenages{
    display: block;
}

.static-point{
    position: absolute;
    bottom: 17px;
    right: 15px;
}

/*cookie police*/
#pc-cookie-notice#pc-cookie-notice {
    position: fixed !important;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: #83b81a;
}
#pc-cookie-notice#pc-cookie-notice #pc-button button {
    color: #00A4D0;
    background: #FFFFFF;
    border: 1px solid #FFF;
}

.cse-link{
    display: block;
    margin-bottom: 10px;
}

/* fix for container in container */
.main-content .container .container,
.main-content .container .container .container-md {
    padding: 0;
}

@media screen and (min-width: 768px) {
    .row--offset-cols > .col:nth-child(even) {
        margin-top: 50px;
    }
}
.justify-content-center { justify-content: center; }
.justify-content-xs-center {
    @media screen and (max-width: 767px){
        justify-content: center;
    }
}
.justify-content-end { justify-content: flex-end; }


.state-badge {
    border: 1px solid #00A4D0;
    position: relative;
    font-family: 'RobotoLight', 'RobotoLight-latin-ext', 'RobotoLight-cyrillic', sans-serif;
    font-weight: 400;
    padding: 12px 12px 12px 34px;
    display: inline-block;
    font-size: 16px;
}
.state-badge:before {
    content: '';
    position: absolute;
    left: 13px;
    top: 50%;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: currentColor;
    transform: translateY(-50%);
}
.state-badge--danger {
    border-color: #ca2522;
    color: #ca2522;
}

.popover>.arrow {
    pointer-events: none;
}


/* overwrite old bootstrap css for new bootstrap functions :-) */
.fade.show:not(.modal-backdrop) {
    opacity: 1;
}
.h-100{
    height: 100%;
}
.d-flex{
    display: flex;
}
.d-none{
    display: none;
}
@media screen and (min-width: 768px) {
    .d-md-none{
        display: none;
    }
}
[hidden] {
    display: none!important;
}

.loading-overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255,255,255,0.5);
    z-index: 1;
}

.position-relative{
    position: relative;
}
.border-0{
    border: none;
}