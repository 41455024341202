.hero-img--lg-margin {
    margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
    .hero-img--lg-margin {
        margin-bottom: 40px;
    }
}
.hero-img__content__hl {
    font-size: 36px;
}
@media screen and (min-width: 768px) {
    .hero-img__content__hl {
        font-size: 64px;
    }
}
.hero-img__content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 10px;
    font-size: 20px;
    text-shadow: 0 0 6px rgba(0,0,0,.5);
}
.hero-img__content:before {
    content: '';
    position: absolute;
    left: 5%;
    top: -10px;
    bottom: -10px;
    right: 5%;
    opacity: 0.5;
    border-radius: 100%;
    z-index: -1;
    background: radial-gradient(ellipse at center,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 71%,rgba(0,0,0,0) 100%);
}
@media screen and (min-width: 768px) {
    .hero-img__content {
        font-size: 25px;
        padding: 0 10%;
    }
    .hero-img__content:before {
        left: 20%;
        top: -40px;
        bottom: -40px;
        right: 20%;
    }
}
@media screen and (min-width: 1200px) {
    .hero-img__content {
        padding: 0 25%;
    }
}
.hero-img__scroll {
    background: transparent;
    border: 0;
    padding: 0;
    position: absolute;
    bottom: 45px;
    left: 50%;
    transform: translateX(-50%);
}
.hero-img__scroll:hover {
    color: #00A4D0;
}