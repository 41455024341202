.sidebar .address__links a{
    line-height: 30px;
}
.sidebar .moodboard__item {
    margin-bottom: 10px;
}
.address__links .icon {
    position: relative;
    top: 3px;
}
@media screen and (max-width: 767px){
    .sidebar {
        margin-top: 60px;
    }
}