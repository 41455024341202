@media screen and (min-width: 768px){
    .navbar-main .visible-affix {
        visibility: hidden;
    }
    .nav-wrapper.affix .navbar-main .visible-affix {
        visibility: visible;
    }
    .nav-wrapper.affix .navbar-main {
        height: 75px;
        width: 100%;
        top: 0;
        position: fixed;
    }

    .sticky-tablet,
    .nav-wrapper.affix.sticky-tablet .navbar-main{
        top: -1px;
        position: -webkit-sticky;
        position: sticky;
        z-index:99980;
    }
    .nav-wrapper.affix .navbar-main .navbar-nav>li>a {
        font-size: 16px;
        padding-bottom: 5px;
    }
    .nav-wrapper.affix .navbar-main .navbar-collapse {
        padding-top: 8px;
        padding-right: 0;
    }
    .nav-wrapper.affix .navbar-main .navbar-nav>li {
        padding: 0 8px 22px;
    }
    .nav-wrapper.affix .navbar-main .navbar-brand {
        height: 75px;
        padding: 13px 25px;
        max-width: 180px;
    }
    .nav-wrapper.affix .navbar-main .navbar-brand>img {
        max-width: 100%;
        height: auto;
    }
    .nav-wrapper.affix .navbar-main .nav-right {
        padding-top: 12px;
    }
    .nav-wrapper.affix .navbar-main .navbar-nav>li>a:hover:after, .nav-wrapper.affix .navbar-nav>li:hover>a:after {
        bottom: -23px;
    }
}