@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1739284918746/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1739284918746/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
                    display: inline-block;
                    font-family: "iconfont";
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    transform: translate(0, 0);
                }

.icon-lg {
                    font-size: 1.3333333333333333em;
                    line-height: 0.75em;
                    vertical-align: -15%;
                }
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
                    width: 1.2857142857142858em;
                    text-align: center;
                }

.icon-ul {
                    padding-left: 0;
                    margin-left: 2.14285714em;
                    list-style-type: none;
                }
.icon-ul > li {
                    position: relative;
                }
.icon-li {
                    position: absolute;
                    left: -2.14285714em;
                    width: 2.14285714em;
                    top: 0.14285714em;
                    text-align: center;
                }
.icon-li.icon-lg {
                                        left: -1.85714286em;
                                    }

.icon-rotate-90 {
                    transform: rotate(90deg);
                }
.icon-rotate-180 {
                    transform: rotate(180deg);
                }
.icon-rotate-270 {
                    transform: rotate(270deg);
                }
.icon-flip-horizontal {
                    transform: scale(-1, 1);
                }
.icon-flip-vertical {
                    transform: scale(1, -1);
                }
:root .icon-rotate-90,
                      :root .icon-rotate-180,
                                            :root .icon-rotate-270,
                                                                  :root .icon-flip-horizontal,
                                                                                        :root .icon-flip-vertical {
                                                                                                                  filter: none;
                                                                                                              }

.icon-icon-logout:before { content: "\EA01" }
.icon-icon-profile:before { content: "\EA02" }
.icon-logo-white:before { content: "\EA03" }
.icon-profile:before { content: "\EA04" }
.icon-elements:before { content: "\E001" }
.icon-3d-map:before { content: "\E002" }
.icon-advice:before { content: "\E003" }
.icon-arrow-big:before { content: "\E004" }
.icon-nature:before { content: "\E005" }
.icon-wencams:before { content: "\E006" }
.icon-booking:before { content: "\E007" }
.icon-butterfly:before { content: "\E008" }
.icon-cable-car:before { content: "\E009" }
.icon-calendar:before { content: "\E00A" }
.icon-circle-arrow:before { content: "\E00B" }
.icon-circle-close:before { content: "\E00C" }
.icon-email:before { content: "\E00D" }
.icon-events:before { content: "\E00E" }
.icon-facebook:before { content: "\E00F" }
.icon-favourite:before { content: "\E010" }
.icon-fax:before { content: "\E011" }
.icon-google-plus:before { content: "\E012" }
.icon-grid-view:before { content: "\E013" }
.icon-home:before { content: "\E014" }
.icon-link:before { content: "\E015" }
.icon-list-view:before { content: "\E016" }
.icon-map-view:before { content: "\E017" }
.icon-menu-mobile:before { content: "\E018" }
.icon-newsletter:before { content: "\E019" }
.icon-offers:before { content: "\E01A" }
.icon-museum:before { content: "\E01B" }
.icon-pinterest:before { content: "\E01C" }
.icon-search:before { content: "\E01D" }
.icon-share:before { content: "\E01E" }
.icon-ski-map:before { content: "\E01F" }
.icon-skier:before { content: "\E020" }
.icon-snowflake:before { content: "\E021" }
.icon-sorting:before { content: "\E022" }
.icon-twitter:before { content: "\E023" }
.icon-weather:before { content: "\E024" }
.icon-webcams:before { content: "\E025" }
.icon-youtube:before { content: "\E026" }
.icon-zoom-in:before { content: "\E027" }
.icon-arrow-small:before { content: "\E028" }
.icon-comano:before { content: "\E029" }
.icon-culture:before { content: "\E02A" }
.icon-download:before { content: "\E02B" }
.icon-duration:before { content: "\E02C" }
.icon-early-booking:before { content: "\E02D" }
.icon-altipiano-di-pine:before { content: "\E02E" }
.icon-itinerary:before { content: "\E02F" }
.icon-dolomiti-paganella:before { content: "\E030" }
.icon-kids:before { content: "\E031" }
.icon-madonna-di-campiglio:before { content: "\E032" }
.icon-like:before { content: "\E033" }
.icon-location:before { content: "\E034" }
.icon-garda:before { content: "\E035" }
.icon-valsugana:before { content: "\E036" }
.icon-panorama:before { content: "\E037" }
.icon-pin:before { content: "\E038" }
.icon-star:before { content: "\E039" }
.icon-val-di-fiemme:before { content: "\E03A" }
.icon-val-di-fassa:before { content: "\E03B" }
.icon-theme:before { content: "\E03C" }
.icon-top:before { content: "\E03D" }
.icon-music:before { content: "\E03E" }
.icon-tour:before { content: "\E03F" }
.icon-checkbox:before { content: "\E040" }
.icon-close-lupe:before { content: "\E041" }
.icon-close:before { content: "\E042" }
.icon-conference:before { content: "\E043" }
.icon-phone:before { content: "\E044" }
.icon-food:before { content: "\E045" }
.icon-alpe-cimbra:before { content: "\E046" }
.icon-happy-face:before { content: "\E047" }
.icon-humidity:before { content: "\E048" }
.icon-info:before { content: "\E049" }
.icon-moodboard_early-booking:before { content: "\E04A" }
.icon-market:before { content: "\E04B" }
.icon-mezzocorona:before { content: "\E04C" }
.icon-moodboard_face:before { content: "\E04D" }
.icon-moodboard_itinerary:before { content: "\E04E" }
.icon-others:before { content: "\E04F" }
.icon-piana-rotaliana:before { content: "\E050" }
.icon-play:before { content: "\E051" }
.icon-plus:before { content: "\E052" }
.icon-precipitation:before { content: "\E053" }
.icon-rovereto:before { content: "\E054" }
.icon-s-mart:before { content: "\E055" }
.icon-show:before { content: "\E056" }
.icon-sport:before { content: "\E057" }
.icon-moodboard_journey:before { content: "\E058" }
.icon-moodboard_kids:before { content: "\E059" }
.icon-moodboard_last-minute:before { content: "\E05A" }
.icon-val-di-non:before { content: "\E05B" }
.icon-val-di-sole:before { content: "\E05C" }
.icon-valle-dei-LAGHI:before { content: "\E05D" }
.icon-valle-dei-mocheni:before { content: "\E05E" }
.icon-valle-del-chiese:before { content: "\E05F" }
.icon-valle-di-ledro:before { content: "\E060" }
.icon-valli-giudicarie:before { content: "\E061" }
.icon-moodboard_of-the-week:before { content: "\E062" }
.icon-vigolana:before { content: "\E063" }
.icon-moodboard_restaurant:before { content: "\E064" }
.icon-moodboard_smiling-face:before { content: "\E065" }
.icon-moodboard_snow-report:before { content: "\E066" }
.icon-moodboard_top:before { content: "\E067" }
.icon-moodboard_weekend-special:before { content: "\E068" }
.icon-altitude:before { content: "\E069" }
.icon-moodboard_advice:before { content: "\E06A" }
.icon-moodboard_booking:before { content: "\E06B" }
.icon-moodboard_by-car:before { content: "\E06C" }
.icon-moodboard_by-plane:before { content: "\E06D" }
.icon-moodboard_by-train:before { content: "\E06E" }
.icon-moodboard_destination:before { content: "\E06F" }
.icon-moodboard_highlight:before { content: "\E070" }
.icon-moodboard_instagram:before { content: "\E071" }
.icon-moodboard_shuttle:before { content: "\E072" }
.icon-moodboard_top-quality:before { content: "\E073" }
.icon-moodboard_transfer:before { content: "\E074" }
.icon-moodboard_traveling:before { content: "\E075" }
.icon-moodboard_webcams:before { content: "\E076" }
.icon-moodboards_offers:before { content: "\E077" }
.icon-great:before { content: "\E078" }
.icon-okay:before { content: "\E079" }
.icon-italian_hand:before { content: "\E07A" }
.icon-instagram:before { content: "\E07B" }
.icon-langlauf:before { content: "\E07C" }
.icon-map-toggle:before { content: "\E080" }
.icon-restaurant:before { content: "\E081" }
.icon-info-blank:before { content: "\E082" }
.icon-calender-detail:before { content: "\E083" }
.icon-watch:before { content: "\E084" }
.icon-arrow-right:before { content: "\E085" }
.icon-show-map:before { content: "\E087" }
.icon-stage:before { content: "\E089" }
.icon-skype:before { content: "\E090" }
.icon-whatsapp:before { content: "\E091" }
.icon-play-simple:before { content: "\E092" }
.icon-winter:before { content: "\E093" }
.icon-summer:before { content: "\E094" }
.icon-spring:before { content: "\E095" }
.icon-mountain:before { content: "\E096" }
.icon-autumn:before { content: "\E097" }
.icon-plus-bold:before { content: "\E098" }
.icon-minus-bold:before { content: "\E099" }
.icon-external-link:before { content: "\E100" }
.icon-langlauf-2:before { content: "\E101" }
.icon-arrow-left-right:before { content: "\E102" }
.icon-clock-thin:before { content: "\E103" }
.icon-filter:before { content: "\E104" }
.icon-wheelchair:before { content: "\E106" }


        :root {
        --icon-icon-logout: "\EA01";
        --icon-icon-profile: "\EA02";
        --icon-logo-white: "\EA03";
        --icon-profile: "\EA04";
        --icon-elements: "\E001";
        --icon-3d-map: "\E002";
        --icon-advice: "\E003";
        --icon-arrow-big: "\E004";
        --icon-nature: "\E005";
        --icon-wencams: "\E006";
        --icon-booking: "\E007";
        --icon-butterfly: "\E008";
        --icon-cable-car: "\E009";
        --icon-calendar: "\E00A";
        --icon-circle-arrow: "\E00B";
        --icon-circle-close: "\E00C";
        --icon-email: "\E00D";
        --icon-events: "\E00E";
        --icon-facebook: "\E00F";
        --icon-favourite: "\E010";
        --icon-fax: "\E011";
        --icon-google-plus: "\E012";
        --icon-grid-view: "\E013";
        --icon-home: "\E014";
        --icon-link: "\E015";
        --icon-list-view: "\E016";
        --icon-map-view: "\E017";
        --icon-menu-mobile: "\E018";
        --icon-newsletter: "\E019";
        --icon-offers: "\E01A";
        --icon-museum: "\E01B";
        --icon-pinterest: "\E01C";
        --icon-search: "\E01D";
        --icon-share: "\E01E";
        --icon-ski-map: "\E01F";
        --icon-skier: "\E020";
        --icon-snowflake: "\E021";
        --icon-sorting: "\E022";
        --icon-twitter: "\E023";
        --icon-weather: "\E024";
        --icon-webcams: "\E025";
        --icon-youtube: "\E026";
        --icon-zoom-in: "\E027";
        --icon-arrow-small: "\E028";
        --icon-comano: "\E029";
        --icon-culture: "\E02A";
        --icon-download: "\E02B";
        --icon-duration: "\E02C";
        --icon-early-booking: "\E02D";
        --icon-altipiano-di-pine: "\E02E";
        --icon-itinerary: "\E02F";
        --icon-dolomiti-paganella: "\E030";
        --icon-kids: "\E031";
        --icon-madonna-di-campiglio: "\E032";
        --icon-like: "\E033";
        --icon-location: "\E034";
        --icon-garda: "\E035";
        --icon-valsugana: "\E036";
        --icon-panorama: "\E037";
        --icon-pin: "\E038";
        --icon-star: "\E039";
        --icon-val-di-fiemme: "\E03A";
        --icon-val-di-fassa: "\E03B";
        --icon-theme: "\E03C";
        --icon-top: "\E03D";
        --icon-music: "\E03E";
        --icon-tour: "\E03F";
        --icon-checkbox: "\E040";
        --icon-close-lupe: "\E041";
        --icon-close: "\E042";
        --icon-conference: "\E043";
        --icon-phone: "\E044";
        --icon-food: "\E045";
        --icon-alpe-cimbra: "\E046";
        --icon-happy-face: "\E047";
        --icon-humidity: "\E048";
        --icon-info: "\E049";
        --icon-moodboard_early-booking: "\E04A";
        --icon-market: "\E04B";
        --icon-mezzocorona: "\E04C";
        --icon-moodboard_face: "\E04D";
        --icon-moodboard_itinerary: "\E04E";
        --icon-others: "\E04F";
        --icon-piana-rotaliana: "\E050";
        --icon-play: "\E051";
        --icon-plus: "\E052";
        --icon-precipitation: "\E053";
        --icon-rovereto: "\E054";
        --icon-s-mart: "\E055";
        --icon-show: "\E056";
        --icon-sport: "\E057";
        --icon-moodboard_journey: "\E058";
        --icon-moodboard_kids: "\E059";
        --icon-moodboard_last-minute: "\E05A";
        --icon-val-di-non: "\E05B";
        --icon-val-di-sole: "\E05C";
        --icon-valle-dei-LAGHI: "\E05D";
        --icon-valle-dei-mocheni: "\E05E";
        --icon-valle-del-chiese: "\E05F";
        --icon-valle-di-ledro: "\E060";
        --icon-valli-giudicarie: "\E061";
        --icon-moodboard_of-the-week: "\E062";
        --icon-vigolana: "\E063";
        --icon-moodboard_restaurant: "\E064";
        --icon-moodboard_smiling-face: "\E065";
        --icon-moodboard_snow-report: "\E066";
        --icon-moodboard_top: "\E067";
        --icon-moodboard_weekend-special: "\E068";
        --icon-altitude: "\E069";
        --icon-moodboard_advice: "\E06A";
        --icon-moodboard_booking: "\E06B";
        --icon-moodboard_by-car: "\E06C";
        --icon-moodboard_by-plane: "\E06D";
        --icon-moodboard_by-train: "\E06E";
        --icon-moodboard_destination: "\E06F";
        --icon-moodboard_highlight: "\E070";
        --icon-moodboard_instagram: "\E071";
        --icon-moodboard_shuttle: "\E072";
        --icon-moodboard_top-quality: "\E073";
        --icon-moodboard_transfer: "\E074";
        --icon-moodboard_traveling: "\E075";
        --icon-moodboard_webcams: "\E076";
        --icon-moodboards_offers: "\E077";
        --icon-great: "\E078";
        --icon-okay: "\E079";
        --icon-italian_hand: "\E07A";
        --icon-instagram: "\E07B";
        --icon-langlauf: "\E07C";
        --icon-map-toggle: "\E080";
        --icon-restaurant: "\E081";
        --icon-info-blank: "\E082";
        --icon-calender-detail: "\E083";
        --icon-watch: "\E084";
        --icon-arrow-right: "\E085";
        --icon-show-map: "\E087";
        --icon-stage: "\E089";
        --icon-skype: "\E090";
        --icon-whatsapp: "\E091";
        --icon-play-simple: "\E092";
        --icon-winter: "\E093";
        --icon-summer: "\E094";
        --icon-spring: "\E095";
        --icon-mountain: "\E096";
        --icon-autumn: "\E097";
        --icon-plus-bold: "\E098";
        --icon-minus-bold: "\E099";
        --icon-external-link: "\E100";
        --icon-langlauf-2: "\E101";
        --icon-arrow-left-right: "\E102";
        --icon-clock-thin: "\E103";
        --icon-filter: "\E104";
        --icon-wheelchair: "\E106";
        
        }