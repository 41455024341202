.account:after {
    content: '';
    position: absolute;
    right: -22px;
    top: 5px;
    bottom: 0;
    background-color: #fff;
    width: 1px;
}

@media screen and (min-width: 768px) {
    .account {
        font-size: 15px;
    }
}

.account-overlay{
    color: #fff;
    visibility: hidden;
    opacity: 0;
    padding: 25px 15px 15px;
    position: fixed;
    left: 0;
    right: 0;
    top: 75px;
    bottom: 0;
    z-index: 5000;
    text-transform: none;
    transform: translateX(100%);
    transition: transform 250ms ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;

    @media screen and (max-width: 768px) {
        top: 60px;
    }
}
.account-overlay.is-open {
    transform: translate(0);
    visibility: visible;
    opacity: 1;
}
.account-overlay .close{
    color: #FFF;
    opacity: 1;
    font-size: 40px;
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
    border: none;
    background-color: transparent;

    @media screen and (min-width: 768px) {
        right: 30px;
        top: 30px;
        font-size: 50px;
    }
}

.account-overlay .h1{
    font-family: 'SoHoPro', sans-serif;
    font-size: 60px;
    line-height: calc(72/60);
    padding-bottom: 25px;

    @media screen and (max-width: 768px) {
        font-size: 31px;
        line-height: calc(37/31);
        padding-bottom: 15px;
    }
}
.account-overlay .btn{
    font-family: 'RobotoBold', sans-serif;
    padding: 15px 25px;
    display: inline-flex;
    align-items: center;
}

.account-overlay .account-login .icon, .account-overlay .account-register .icon{
    padding-right: 11px;

    @media screen and (max-width: 768px) {
        padding-right: 10px;
    }
}

.account-overlay .account-login{
    margin-bottom: 85px;

    @media screen and (max-width: 768px) {
        margin-bottom: 50px;
    }
}

.account-overlay .account-register{
    margin-top: 85px;

    @media screen and (max-width: 768px) {
        margin-top: 50px;
    }
}

.profile{
    margin: 40px 0;

    @media screen and (max-width: 768px) {
        margin: 20px 0;
    }
}

.profile h1{
    color: var(--color-secondary);
    margin-bottom: 3px;

    @media screen and (max-width: 768px){
        margin-bottom: 10px;
    }
}

.profile h2{
    font-size: 40px;
    line-height: calc(48/40);

    @media screen and (max-width: 768px) {
        font-size: 30px;
        line-height: calc(36/40);
        margin-bottom: 20px;
    }
}

.profile-header {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.profile-header__headline {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: calc(15rem/16);
}

.profile-header__actions {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: calc(15rem/16);

    @media screen and (min-width: 992px) {
        flex-direction: row;
        justify-content: end;
    }
}

.profile-header__change-data-btn {
    padding: calc(13rem/16) calc(24rem/16);
}

.profile-header__mail {
    display: flex;
    gap: calc(10rem/16);
    align-items: center;
}

.profile-header__mail-icon {
    font-size: calc(10rem/16);
}
