body {
    font-family: 'RobotoLight', 'RobotoLight-latin-ext', 'RobotoLight-cyrillic', sans-serif;
    font-weight: 400;
    font-size: 1em;
    color: #404040;
}
html {
    font-size: 15px;
}
@media screen and (min-width: 768px) {
    html {
        font-size: 17px;
    }
}

h1, h2, .h2, h3, .h3, h4 {
    margin-top: 0;
}


h1 { font-size: 31px; font-family: 'SoHoPro', sans-serif; font-weight: 400; color: #013e59; }
h2, .h2 { font-size: 30px; font-family: 'SoHoPro', sans-serif; font-weight: 400; color: #0082a4; margin-bottom: 15px;}
h2.subline { font-size: 17px; line-height: calc(20/17); }
h2.font-light {
    color: #404040;
    font-family: 'RobotoLight', 'RobotoLight-latin-ext', 'RobotoLight-cyrillic', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 5px;
}
h3, .h3 { font-size: 20px; font-family: 'SoHoPro', sans-serif; font-weight: 400; color: #006390; margin-bottom: 10px; }
h6 { font-size: 1em; }

@media screen and (min-width: 768px) {
    h1 { font-size: 60px; line-height: calc(70/60);}
    h2, h2.subline, .h2 { font-size: 36px; line-height: calc(44/36); }
    h3, .h3 { font-size: 24px; line-height: calc(28/24); }
}

/*Font-sizes*/
.fz12 { font-size: 12px; }
.fz14 { font-size: 12px; }
.fz18 { font-size: 14px; }
.fz20 { font-size: 16px; }
.fz24 { font-size: 17px; }
.fz30 { font-size: 24px; }
.fz36 { font-size: 30px; }


/*fz for icons*/
.fz15 { font-size: 15px; }
.fz37 { font-size: 37px; }
.fz80 { font-size: 80px; }
.fz110 { font-size: 110px; }

@media screen and (min-width: 768px) {
    .fz18 { font-size: 16px; }
    .fz20 { font-size: 18px; }
    .fz24 { font-size: 20px; }
    .fz30 { font-size: 26px; }
}

@media screen and (min-width: 1200px) {
    .fz14 { font-size: 14px; }
    .fz18 { font-size: 18px; }
    .fz20 { font-size: 20px; }
    .fz24 { font-size: 24px; }
    .fz36 { font-size: 36px; }
    .fz30 { font-size: 30px; }
}

.text-underline {
    text-decoration: underline;
}
.font-bold, b, strong {
    font-family:'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    font-weight: 400;
}
.font-light {
    font-family: 'RobotoLight', 'RobotoLight-latin-ext', 'RobotoLight-cyrillic', sans-serif;
    font-weight: 400;
}