.stage {
    background: #fff;
    border: 1px solid #E9E9E9;
    box-shadow: 0 2px 20px 0 rgba(0,0,0,0.06);
    color: #49494C;
}
@media screen and (max-width: 767px) {
    .stage {
        margin: 0 -20px;
    }
}
.stage + .stage {
    margin-top: 20px;
}
@media screen and (min-width: 768px) {
    .stage + .stage {
        margin-top: 60px;
    }
}
.stage__head {
    position: relative;
    text-shadow: 0 0 1px rgba(0,0,0,.2);
}
.stage__head__count {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 20px;
    background: #9fc54a;
    width: 44px;
    height: 44px;
    padding: 9px;
    z-index: 2;
    text-shadow: none;
}
.stage__head__title {
    position: absolute;
    left: 60px;
    top: 8px;
    font-size: 24px;
    z-index: 2;
}
.stage__head__location {
    position: absolute;
    z-index: 2;
    font-size: 14px;
    bottom: 30px;
    left: 32px;
}
.stage__head__location__icon {
    font-size: 16px;
    vertical-align: -.15em;
    margin-right: 5px;
}
.stage__body {
    padding: 20px;
    line-height: calc(26/17);
}
@media screen and (min-width: 768px) {
    .stage__body {
        padding: 15px;
    }
}
@media screen and (min-width: 1200px) {
    .stage__body {
        padding: 30px 35px;
    }
}
.stage__interests {
    font-size: 14px;
}
@media screen and (min-width: 768px) {
    .stage__interests {
        padding: 24px;
    }
}
@media screen and (min-width: 1200px){
    .stage__interests {
        padding: 34px;
    }
}
.stage__interests__list {
    margin-top: 15px;
}
.stage__interests__list li {
    position: relative;
    padding-left: 20px;
}
.stage__interests__list li:before {
    content: "\E010";
    font-family: iconfont;
    position: absolute;
    top: -1px;
    left: 0;
    color: #9fc54a;
}
.stage__interests__list li + li {
    margin-top: 8px;
}
.stage__sidebar {
    position: relative;
    font-size: 14px;
    padding: 7px 7px 7px 48px;
    background: #FBFBFB;
}
.stage__sidebar + .stage__sidebar {
    margin-top: 3px;
}
.stage__sidebar__category {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 37px;
}
.icon.stage__sidebar__category__icon {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
}
@media screen and (min-width: 768px) {
    .icon.stage__sidebar__category__icon {
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}
.stage__sidebar__category__icon.icon-booking {
    font-size: 13px;
}
.stage__sidebar__category__icon.icon-favourite {
    font-size: 14px;
}
.stage__head:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: .8;
    pointer-events: none;
    background: linear-gradient(0deg,transparent 0%,#013e59 100%);
    bottom: auto;
    height: 90px;
    z-index: 1;
}
@media screen and (min-width: 768px) {
    .stage__head:after {
        height: 70px;
    }
}