.poi-directions{
    background: var(--color-secondary);
    padding: calc(40rem/17) calc(55rem/17);
    color: #FFFFFF;
    @media screen and (min-width: 768px) {
        padding: calc(65rem/17) calc(110rem/17);
    }
}
.poi-directions__title{
    color: #FFFFFF;
    font-size: calc(25rem/17);
    line-height: calc(43/36);
    @media screen and (min-width: 768px) {
        font-size: calc(36rem/17);
    }
}
.poi-directions__link{
    color: #FFFFFF;
    display: flex;
    align-items: center;
}
.poi-directions__link-text{
    text-decoration: underline;
}
.poi-directions__link:hover .poi-directions__link-text{
    text-decoration: none;
}
.poi-directions__link + .poi-directions__link{
    margin-top: calc(10rem/17);
}
.poi-directions__maps{
    margin-top: calc(10rem/17);
    @media screen and (min-width: 768px) {
        margin-top: calc(20rem/17);
    }
}