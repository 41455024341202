.moodboard__item-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 35px 10px 10px;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC40NSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.8)));
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
    background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
    background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#73000000',GradientType=0 );
    z-index: 1;
}
.moodboard__item-text__link {
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.moodboard__item-text__link > a {
    display: block;
}
.moodboard__item-headline,
h3.moodboard__item-headline {
    font-size: 22px;
    color: #fff;
    font-family: RobotoLight, sans-serif;
}

.moodboard__item-subline{

}
@media screen and (max-width: 767px){
    .moodboard__item-headline {
        line-height:1.2;
    }
}

.moodboard__item-headline a:hover{
    color: #fff;
}
.moodboard__item {
    cursor: pointer;
}
.moodboard__item.no-link {
    cursor: default;
}
.moodboard__item-icon {
    font-size: 80px;
}
.special-mark {
    position: absolute;
    right: 0;
    top: 0;
    padding: 7px 10px;
}
.special-mark.special-mark.top-event {
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 7px 30px;
}

.special-mark.expired-event {
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 7px 30px;
    display: inline-flex;
    align-items: center;

    .icon {
        margin-right: 6px;
    }
}

.moodboard__item:after {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: #00a4d0;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYTRkMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjYmQzMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(left,  #00a4d0 0%, #cbd300 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,#00a4d0), color-stop(100%,#cbd300));
    background: -webkit-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: -o-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: -ms-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: linear-gradient(to right,  #00a4d0 0%,#cbd300 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a4d0', endColorstr='#cbd300',GradientType=1 );
    opacity: 0;
    transition: 250ms ease-in-out;
}
.moodboard__item:hover:after {
    opacity: 0.4;
}
.moodboard__item.no-link:after {
    content: none;
}
.moodboard__item__arrange {
    height: 100%;
}
.moodboard__item--video:before {
    content: "\E051";
    font-size: 60px;
    width: 62px;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -30px;
    position: absolute;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    color: #fff;
    z-index: 5;
    opacity: 0.7;
}

.moodboard__item--offer-label{
    position: absolute;
    z-index: 1;
    left: 15px;
    top: 15px;
    font-size: 26px;
    color: #fff;
}

@media screen and (min-width: 768px){
    .moodboard__item-text{
        padding: 30px 45px 15px 15px;
    }
    .moodboard__item-text__link {
        position: absolute;
        right: 15px;
        bottom: 15px;
    }
    .moodboard__item-icon {
        font-size: 100px;
    }
    /*Large Moodboard Item*/
    .moodboard__item--lg .moodboard__item-headline{
        font-size: 50px;
        line-height: 50px;
        margin-bottom: 5px;
    }
    /*Medium Moodboard Item*/
    .moodboard__item--md .moodboard__item-headline {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 5px;
    }
    /*Small Moodboard Item*/
    .moodboard__item--sm .moodboard__item-headline {
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 5px;
    }
}
@media screen and (max-width: 992px) and (min-width: 768px){
    .moodboard__item--sm .moodboard__item-headline {
        font-size: 20px;
        line-height: 22px;
    }
    .moodboard__item--lg .moodboard__item-headline {
        font-size: 45px;
        line-height: 46px;
    }
    .moodboard__item--md .moodboard__item-headline {
        font-size: 26px;
        line-height: 27px;
    }
}
@media screen and (min-width: 1200px){
    .moodboard__item-icon {
        font-size: 120px;
    }
}

.lightbox-download{
    background: #83b81a;
    color: #ffffff;
    padding: 10px 15px;
    display: inline-block;
    margin-bottom: 10px;
    margin-top: 15px;
}

.lightbox-download:hover{
    color: #ffffff;
    border-radius: 5px;
}

.moodboard {
    position: relative;
}
.hero + .main-content .pimcore_area_moodboardbig > .moodboard {
    padding-top: 50px;
}
.moodboard__loading-more {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding-bottom: 15px;
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) -30%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) -30%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(255,255,255,0) -30%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}
.moodboard__loading-more__btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}