.range-slider .noUi-target{
    border: none;
    box-shadow: none;
    border-radius: 0;
    background: #EEEFF2;
}

.range-slider .noUi-horizontal {
    height: 2px;
}

.range-slider .noUi-connect{
    background: var(--color-primary);
}

.range-slider .noUi-horizontal .noUi-handle{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 0;
    box-shadow: none;
    background-color: var(--color-primary);
    cursor: pointer;
    top: -11px;
    right: -19px;
}

.range-slider .noUi-horizontal .noUi-handle.noUi-handle-upper{
    right: 0;
}

.range-slider .noUi-handle:after,
.range-slider .noUi-handle:before{
    display: none;
}

.range-slider__values{
    font-size: 14px;
    color: #87898D;
    margin-top: 15px;
}

.range-slider .form-control-label{
    font-size: 16px;
    margin-bottom: 18px;
}