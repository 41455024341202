:root {
    --color-primary: #00A4D0;
    --color-primary-light: #00A4D0;
    --color-secondary: #013E59;
    --color-text-default: #404040;

    --color-primary-dark: #006390;
    --color-secondary-dark: #00283a;

    --color-grey: #eeeff2;
    --color-grey-dark: #555555;
    --color-green: #CAD200;
    --color-green-dark: #82B81A;

    --color-btn-primary: #006390;
    --color-btn-primary--hover: #00405E;
    --color-btn-secondary: #00405E;
    --color-btn-secondary--hover: #006390;

    --font-default: 'RobotoLight', 'RobotoLight-latin-ext', 'RobotoLight-cyrillic', sans-serif;
    --font-bold: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    --font-soho: 'SoHoPro', sans-serif;
}
