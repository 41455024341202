.accessipoi-row{
    display: flex;
    gap: 10px;
    @media (min-width: 768px) {
        flex-wrap: wrap;
        gap: 20px;
    }
    @media (max-width: 767px) {
        flex-direction: column;
    }
}
.accessipoi-row__item-content{
    display: flex;
    gap: 15px;
    align-items: center;

}
.list-inline > li.accessipoi-row__item{
    padding: 10px 20px;
    background: #00000008;
    color: var(--color-text-default);
    height: fit-content;
}
.accessipoi-row__item-body{
    display: flex;
    gap: calc(40rem/16);
    align-items: center;
    justify-content: space-between;
}
.accessipoi-row__image{
    width: 30px;
}
.accessipoi-row__item-button{
    background: none;
    border: none;
    outline: none;
    padding: 0;
}
.accessipoi-row__item-button:hover{
    color: var(--color-secondary);
}
.accessipoi-row__item-icon{
    font-size: 11px;
    transition: transform 100ms ease-in-out;
}
.accessipoi-row__item-button.collapsed > .accessipoi-row__item-icon{
    transform: rotate(180deg);
}
.accessipoi-row__item-button:not(.collapsed) > .accessipoi-row__item-icon:before{
    content: var(--icon-minus-bold);
}
.accessipoi-row__item-collapse{
    padding-top: 20px;
    padding-left: 15px;
}
.accessipoi-row__item-collapse > .accessipoi-row__item + .accessipoi-row__item{
    margin-top: 10px;
}
.accessipoi-row__item-info{
    padding: 10px;
}
.accessipoi-row__item-info:hover{
    color: var(--color-secondary);
}