.social-icons__item {
    width: 100%;
}
.social-icons__item .icon {
    position: relative;
    top: 2px;
}

@media screen and (max-width: 767px){
    .social-icons__item .icon {
        top: 5px;
    }

}
.social-icons__item {
    color: #fff;
    height: 50px;
    margin-bottom: 10px;
}
.social-icons__item.social-icons__item:hover,
.social-icons__item.social-icons__item:focus {
    color: #fff;
}
.social-icons__item--facebook {
    background: #3b5998;
}
.social-icons__item--twitter {
    background: #000000;
}
.social-icons__item--googleplus {
    background: #dd4b39;
}
.social-icons__item--youtube {
    background: #cd201f;
}
.social-icons__item--pinterest {
    background: #cc2127;
}

.social-icons__item--tiktok {
    background: #000;
}

.social-icons__item--instagram {
    background:
    radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}
.social-icons__item--skype {
    background: #00AFF0;
}
.social-icons__item--whatsapp {
    background: #25D366;
}

/*social share dropdown*/
.share-dropdown .dropdown-menu {
    padding: 0 5px 5px 10px;
}
.share-dropdown__icon {
    vertical-align: -.2em;
    font-size: 25px;
    margin-left: 5px;
}
@media screen and (max-width: 767px) {
    .share-dropdown {
        margin-top: -40px;
        margin-bottom: 20px;
    }
    .demi2015_responsive + .container .share-dropdown {
        margin-top: -10px;
    }
}