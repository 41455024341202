.header-img {
    position: relative;
}
.header-img__text {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 15px;
    z-index: 1;
}
.header-img__text:after {
    content: '';
    position: absolute;
    opacity: 0.4;
    left: 20%;
    top: -40px;
    bottom: -40px;
    right: 20%;
    border-radius: 100%;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI3MSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAiLz4KICA8L3JhZGlhbEdyYWRpZW50PgogIDxyZWN0IHg9Ii01MCIgeT0iLTUwIiB3aWR0aD0iMTAxIiBoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 71%, rgba(0,0,0,0) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(0,0,0,1)), color-stop(71%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0)));
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 71%,rgba(0,0,0,0) 100%);
    background: -o-radial-gradient(center, ellipse cover,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 71%,rgba(0,0,0,0) 100%);
    background: -ms-radial-gradient(center, ellipse cover,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 71%,rgba(0,0,0,0) 100%);
    background: radial-gradient(ellipse at center,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 71%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 );
    z-index: -1;
}
.header-img__text .h2, .header-img__text h2  {
    margin-bottom: 0;
    font-size: 30px;
    text-shadow: 0 0 5px rgba(0,0,0,.25);
}
.header-img__text .arrange-xs {
    height: 100%;
}
@media screen and (min-width: 768px){
    .header-img__text .h2, .header-img__text h2 {
        font-size: 75px;
        text-shadow: none;
    }
}
@media screen and (min-width: 992px){
    .header-img__text .h2, .header-img__text h2 {
        font-size: 90px;
    }
}

.header-img__text--centered {
    top: 50%;
    transform: translateY(-50%);
    height: auto;
}

.header-img__scroll {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 110px 0 12px;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,16,23,0.5) 100%);
    pointer-events: none;
}
@media screen and (min-width: 768px){
    .header-img__scroll {
        padding: 30px 0 12px;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,16,23,0.5) 100%);
    }
}
.header-img__scroll__arrow {
    font-size: 40px;
    text-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    line-height: 1;
    pointer-events: auto;
}
@media screen and (min-width: 768px){
    .header-img__scroll__arrow {
        font-size: 58px;
    }
}

/* large header img */
@media screen and (max-width: 767px) {
    .header-img--lg .header-img__text h2 {
        font-size: 40px;
    }
}

/* header img video */
.header-img--video .header-img__text--centered {
    top: 35px;
    transform: translateY(0);
}
@media screen and (min-width: 768px) {
    .header-img--video .header-img__text--centered {
        top: 50%;
        transform: translateY(-50%);
        margin-top: -120px;
    }
}
@media screen and (max-width: 767px) {
    .header-img--video .header-img__scroll {
        padding: 30px 0 10px;
    }
}

.header-img--video .video-js .is-finished ~ .vjs-poster,
.header-img--video .video-js .is-finished ~ .vjs-big-play-button {
    display: block !important; /* overwrite inline style */
}