.hero {
    position: relative;
    box-shadow: 0 0 8px rgba(0,0,0,.6);
    z-index: 2;
}
.hero-slider__item {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
@media screen and (min-width: 768px) {
    .hero-slider__item {
        width: 100%;
        height: calc(100vh - 100px);
    }
}
.hero__content {
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    font-size: 18px;
    line-height: calc(29/24);
    padding: 5px 10px;
    display: flex;
    align-items: center;
}
@media screen and (min-width: 768px) {
    .hero__content {
        font-size: 24px;
        padding: 5px 10px 120px;
    }
}
.hero__content__poster {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-position: center;
    background-size: cover;
    transition: opacity 200ms ease-in-out;
    z-index: -1;
}
.hero__content__btn--video {
    transition: opacity 200ms ease-in-out;
}
.is-playing .hero__content__poster,
.is-playing .hero__content__btn--video{
    opacity: 0;
}
.is-playing .hero__content {
    pointer-events: none;
}
.hero--hide-text.is-playing .hero__content {
    opacity: 0;
}
.hero.is-ended .vjs-poster {
    display: block !important;
}
.hero__content__title {
    font-size: 50px;
    line-height: calc(54/50);
    position: relative;
    color: #fff;
}
@media screen and (min-width: 768px){
    .hero__content__title {
        font-size: 70px;
        margin-bottom: 15px;
    }
}
@media screen and (min-width: 1200px){
    .hero__content__title {
        font-size: 120px;
        line-height: calc(130/120);
    }
}
.hero__content__text-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 2px 20px #000000;
}
@media screen and (min-width: 768px) {
    .hero__content__text-container {
        max-width: 690px;
    }
}
.hero__content__btn {
    margin-top: 10px;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border: 3px solid #FFFFFF;
    color: #fff;
    background-color: #00A4D0;
    position: relative;
    display: inline-block;
    font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    font-weight: 400;
    white-space: nowrap;
}
@media screen and (min-width: 768px) {
    .hero__content__btn {
        margin-top: 70px;
        height: 78px;
        width: 78px;
        border: 5px solid #FFFFFF;
    }
}
.hero__content__btn__icon {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
@media screen and (min-width: 768px) {
    .hero__content__btn__icon {
        font-size: 38px;
    }
}
.hero__content__btn__icon.icon-play-simple {
    transform: translateX(-30%) translateY(-50%);
}
.hero__content__btn__text {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 15px;
    padding-top: 5px;
}
@media screen and (min-width: 768px) {
    .hero__content__btn__text {
        font-size: 20px;
        padding-top: 20px;
    }
}
.hero__content__btn:hover,
.hero__content__btn:focus {
    background: #006390;
    color: #fff;
}

/* scroll info */
.hero__scroll {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 3px solid #FFFFFF;
    color: #fff;
    background-color: #82B81A;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
}
@media screen and (min-width: 768px) {
    .hero__scroll {
        height: 78px;
        width: 78px;
        border: 5px solid #FFFFFF;
        bottom: 40px;
    }
}
.hero__scroll__icon {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
}
@media screen and (min-width: 768px) {
    .hero__scroll__icon {
        font-size: 38px;
    }
}
.hero__scroll:hover,
.hero__scroll:focus {
    background: #8BBE50;
    color: #fff;
}

/* hero background video */
.hero__video {
    overflow: hidden;
}
.hero--placeholder {
    height: calc(60vh - 50px);
    width: 100%;
    position: relative;
}
.hero__video .hero__video__video {
    height: calc(60vh - 50px) !important;
    width: 100% !important;
    position: relative;
    max-height: none;
}
@media screen and (min-width: 768px) {
    .hero__video .hero__video__video {
        min-height: 690px;
        height: calc(100vh - 100px) !important;
    }
    .hero__video .video-js .vjs-tech {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        transform: translateX(-50%) translateY(-50%);
        background-size: cover;
        width: auto;
        height: auto;
    }
}
.hero__video__video .vjs-big-play-button {
    display: none;
}

.hero-slider .slick-dots {
    margin-bottom: 8px;
}

.hero-slider .swiper-slide + .swiper-slide{
    display: block;
}


@media screen and (min-width: 768px) {
    .hero-slider .slick-next:before,
    .hero-slider .slick-prev:before,
    .hero-slider .swiper-button--prev:before,
    .hero-slider .swiper-button--next:before{
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
    .hero-slider .slick-next,
    .hero-slider .slick-prev,
    .hero-slider .swiper-button--prev,
    .hero-slider .swiper-button--next{
        font-size: 70px;
        transform: translateY(-50%);
    }
    .hero-slider .slick-prev,
    .hero-slider .swiper-button--prev {
        left: 35px;
        transform: translateY(-50%) scaleX(-1);
    }
    .hero-slider .slick-next,
    .hero-slider .swiper-button--next {
        left: auto;
        right: 35px;
    }
}


.hero__badge {
    border: 2px solid #FFF;
    color: #FFF;
    position: fixed;
    hyphens: auto;
    bottom: calc(13rem/16);
    left: calc(13rem/16);
    z-index: 1100;
    box-sizing: border-box;
    text-align: center;
    font-family: RobotoBold,RobotoBold-latin-ext,RobotoBold-cyrillic,sans-serif;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 8.25rem;
    height: 8.25rem;
    padding: 0.6rem;
    font-size: 0.9rem;
    line-height: 1.2;
    transform: rotate(-12deg);
    transition: .3s;
}

@media screen and (min-width: 767px) {
    .hero__badge {
        bottom: calc(48rem/16);
        left: calc(48rem/16);
        font-size: 1.1rem;
        width: 9rem;
        height: 9rem;
    }
}


@media screen and (min-width: 991px){
    .hero__badge {
        font-size: 1.2rem;
        width: 11rem;
        height: 11rem;
        padding: calc(14rem/16) calc(18rem/16);
        bottom: calc(62rem/16);
        left: calc(62rem/16);
    }
}

.hero__badge:hover{
    color: #FFF;
}

.hero__badge--green-background{
    background-color: #83b81a;
}

.hero__badge--green-background:hover{
    background-color: #9cc648;
}

.hero__badge--darkblue-background{
    background-color: #006390;
}

.hero__badge--darkblue-background:hover{
    background-color: #004f73;
}

.hero__badge.hero__badge--blue-border{
    border: 1px solid #00a4d0;
    background-color: #FFF;
    color: #00a4d0;
}

.hero__badge.hero__badge--blue-border:hover{
    color: #0083a6;
    border: 1px solid #0083a6;
}

.hero__badge--lightblue-background{
    background-color: #00a4d0;
}

.hero__badge--lightblue-background:hover{
    background-color: #59c4e0;
}

