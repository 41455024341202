.decorated-hr {
    overflow: hidden;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 60px;
    position: relative;
}

.decorated-hr__icon {
    line-height: 1.2;
    position: relative;
    font-size: 36px;
    padding: 0 20px;
    color: #006390;
}

.decorated-hr__left,
.decorated-hr__right {
    display: block;
    position: absolute;
    top: 50%;
    left: 100%;
    width: 1000px;
    height: 0;
    border-top: 1px solid #96c7da;
    margin-top: -1px;
    color: inherit;
}
.decorated-hr__right {
    left: auto;
    right: 100%;
}