.text-with-media__media-container{
    margin: 20px 0;
}
@media screen and (min-width: 768px) {
    .text-with-media {
        overflow: hidden;
        margin-top: 50px;
        margin-bottom: 80px;
    }
    .text-with-media__media-container {
        float: right;
        margin-left: 70px;
        width: 49%;

    }
    .text-with-media__media {
        display: block;
        margin-bottom: 10px;
        max-width: 100%;
        height: auto;
    }
    .text-with-img--media-left .text-with-media__media-container {
        float: left;
        margin-right: 24px;
        margin-left: 0;
    }
    .text-with-media__media.text-with-media__media--video {
        width: 285px;
        height: auto;
    }
}

