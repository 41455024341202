.expandable-block {
    position: relative;
    overflow: hidden;
}
.expandable-block.is-expandable {
    padding-bottom:2rem;
}
.expandable-block.is-collapsed:not(.is-not-expandable) {
    display: block;
    height:200px;
}
.expandable-block.is-expandable.is-collapsed:after {
    content:'';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+62,1+100 */
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 62%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 62%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 62%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}
.expandable-block__more {
    display: none;
    padding:1rem 0;
    position: absolute;
    bottom: -1rem;
    left: 0;
    right: 0;
    z-index: 1;
}
.expandable-block.is-not-expandable .expandable-block__content,
.expandable-block.is-not-expandable {
    height: auto;
    overflow: visible;
}
.expandable-block.is-expandable .expandable-block__more {
    display: block;
}
.expandable-block.is-not-expandable .expandable-block__more {
    display: none;
}
.expandable-block.is-not-expandable:after {
    display: none;
    visibility: hidden;
}


.expandable-block.is-collapsed .expandable-block__more-less {
    display: none;
}
.expandable-block:not(.is-collapsed) .expandable-block__more-more {
    display: none;
}
