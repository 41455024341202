.webcams--lg .webcams__item-headline {
    font-size: 24px;
}
.webcams--sm .webcams__item-headline {
    font-size: 16px;
}
.webcams__item__text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 25px 20px 20px;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4zIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.4)));
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 100%);
    background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 100%);
    background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#4d000000',GradientType=0 );
}
.webcams__item__logo {
    position: absolute;
    left: 20px;
    top: 20px;
}
.webcams--sm .webcams__item__text {
    padding: 7px 10px;
}
.webcams--md .webcams__item__text {
    top: 0;
    padding: 20px;
    background: transparent;
}
.webcams--md .icon-arrow-small {
    color: #59c4e0;
}
.webcams__item__overlay {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYTRkMCIgc3RvcC1vcGFjaXR5PSIwLjYiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2NiZDMwMCIgc3RvcC1vcGFjaXR5PSIwLjYiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
    background: -moz-linear-gradient(left,  rgba(0,164,208,0.6) 0%, rgba(203,211,0,0.6) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,164,208,0.6)), color-stop(100%,rgba(203,211,0,0.6)));
    background: -webkit-linear-gradient(left,  rgba(0,164,208,0.6) 0%,rgba(203,211,0,0.6) 100%);
    background: -o-linear-gradient(left,  rgba(0,164,208,0.6) 0%,rgba(203,211,0,0.6) 100%);
    background: -ms-linear-gradient(left,  rgba(0,164,208,0.6) 0%,rgba(203,211,0,0.6) 100%);
    background: linear-gradient(to right,  rgba(0,164,208,0.6) 0%,rgba(203,211,0,0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9900a4d0', endColorstr='#99cbd300',GradientType=1 );
    transition: 250ms ease-in-out;
}
.webcams__item__overlay .icon {
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
}
.webcams--sm .webcams__item__overlay .icon {
    font-size: 50px;
    margin-left: -25px;
    margin-top: -25px;
}
.webcams__item:hover .webcams__item__overlay{
    opacity: 1;
}
.webcams--md__logo {
    font-size: 50px;
}
.webcams--md__name {
    position: relative;
    height: 34px;
}
.webcams--md__name>span {
    position: absolute;
    left: 0;
    top: 0;
    height: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
/*webcams row*/
.webcams-row {
    padding: 20px 0;
    border-bottom: 1px solid #ced2d9;
}
.webcams-row__view {
    position: absolute;
    left: 5px;
    bottom: 0;
}
.webcams-row__link {
    position: absolute;
    right: 5px;
    bottom: 0;
}
/*webcams slider*/
.gallery--webcams .no-webcam {
    position: relative;
    top:60%;
    left:0;
    right:0;
    padding:0 10px;
    width:auto;
    text-align:center;
}
.gallery--webcams__nav {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
}

@media screen and (min-width: 768px){
    .webcam-gallery-nav-slider{
        display: flex;
    }

    .gallery--webcams__nav{
        width: calc(100% - 80px);
    }
    .gallery--webcams__nav .swiper-wrapper{
/*        padding-left: 42px;*/
    }
}
.gallery--webcams__play, .gallery--webcams__btn,
.gallery--webcams__arrow {
    height: 40px;
    padding: 5px;
    text-align: center;
    margin-top: 0;
    top: 0;
}

.gallery--webcams__play .icon{
    pointer-events: none;
}
.gallery--webcams__arrow:focus, .gallery--webcams__arrow:focus-visible, .gallery--webcams__arrow:focus:active,
.gallery--webcams__play:focus, .gallery--webcams__play:focus-visible, .gallery--webcams__play:focus:active,
.gallery--webcams__btn:focus, .gallery--webcams__btn:focus-visible, .gallery--webcams__btn:focus:active {
    outline: none;
    background: var(--color-primary-dark);
}

.gallery--webcams__arrow.swiper-button--prev{
    left: 0;
    transform: translateY(0%) scaleX(-1);
}
.gallery--webcams__arrow.swiper-button--next{
    right: 0;
}
.gallery--webcams__play.is-active .icon:before {
    content: "\E042";
}
.gallery--webcams__play {
    padding: 10px;
    width: 83px;
    margin-right: 1px;
    position: relative;
    z-index: 50;
}
.gallery--webcams__btn {
    width: 98%;
    width: calc(100% - 1px);
}
.webcam-nav__item {
    position: relative;
}
.webcam-nav__item.disabled{
    pointer-events: none;
}

.gallery--webcams__arrow {
    padding: 14px 0;
    width: 40px;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2NiZDMwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMGE0ZDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #cbd300 0%, #00a4d0 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#cbd300), color-stop(100%,#00a4d0));
    background: -webkit-linear-gradient(top,  #cbd300 0%,#00a4d0 100%);
    background: -o-linear-gradient(top,  #cbd300 0%,#00a4d0 100%);
    background: -ms-linear-gradient(top,  #cbd300 0%,#00a4d0 100%);
    background: linear-gradient(to bottom,  #cbd300 0%,#00a4d0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbd300', endColorstr='#00a4d0',GradientType=0 );
    z-index: 2;
}
.gallery--webcams__nav .btn.disabled {
    background: #d7dae2;
    display: block;
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    opacity: 1;
}
.gallery--webcams__nav .btn {
    background: #64d0e9;
}
.gallery--webcams__nav .btn:focus, .gallery--webcams__nav .btn:focus-visible {
    background: var(--color-primary-dark);
    outline: none;
}
.gallery--webcams__nav .btn:hover {
    background: #59c4e0;
}
.gallery--webcams__nav .is-active .btn,
.gallery--webcams__nav .swiper-slide-thumb-active .btn{
    background: #006b87;
}
.webcam-slide__prev, .webcam-slide__next {
    position: absolute;
    bottom: 0;
    top: 0;
    left: -43px;
    width: 42px;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2NiZDMwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMGE0ZDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #cbd300 0%, #00a4d0 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#cbd300), color-stop(100%,#00a4d0));
    background: -webkit-linear-gradient(top,  #cbd300 0%,#00a4d0 100%);
    background: -o-linear-gradient(top,  #cbd300 0%,#00a4d0 100%);
    background: -ms-linear-gradient(top,  #cbd300 0%,#00a4d0 100%);
    background: linear-gradient(to bottom,  #cbd300 0%,#00a4d0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbd300', endColorstr='#00a4d0',GradientType=0 );
    cursor: pointer;
}

@media screen and (max-width: 767px){
    .webcam-slide__prev{
        transform: translateY(0%) scaleX(-1);
        top: 0;
        margin-top: 0;
        left: 0;
    }
    .webcam-slide__next{
        transform: translateY(0%);
        top: 0;
        margin-top: 0;
        right: 0;
    }
}
.webcam-slide__next {
    left: auto;
    right: -43px;
}
.webcam-slide__next .icon, .webcam-slide__prev .icon {
    position: absolute;
    top: 50%;
    margin-top: -11px;
    left: 50%;
    margin-left: -8.75px;
}
.webcam-slide__text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 7px 18px;
}
.webcam-slide .slick-slider.row--gutter-with-1 .col {
    padding-left: 0;
    padding-right: 0;
}
@media screen and (min-width: 768px){
    .webcams--md__logo {
        font-size: 70px;
    }
    .webcams--md .webcams__item__text {
        padding: 14px 20px 14px 0;
    }
    .webcams--sm .webcams__item__text {
        padding: 7px 20px 7px 19px;
    }
    .webcams--md .webcams__item__text .text-center {
        text-align: right;
    }
    .webcam-slide__prev, .webcam-slide__next {
        left: -63px;
        width: 62px;
    }

    .webcam-slider .webcam-slide__prev, .webcam-slider .webcam-slide__next {
        left: 0;
        width: 62px;
        transform: translateY(0) scaleX(-1);
        margin-top: 0;
    }
    .webcam-slide__next {
        left: auto;
        right: -63px;
    }
    .webcam-slider .webcam-slide__next {
        left: auto;
        right:0;
        transform: translateY(0) scaleX(1);
    }
    .h2.webcams-row__weather-hl {
        font-size: 34px;
    }
    .webcams-row__logo {
        margin-top: -20px;
    }
    .webcam-slide .slick-slider.row--gutter-with-1 .col:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 1px;
        background: #fff;
    }
}

.webcam-slider{
    padding: 0 62px;
    position: relative;
}

@media screen and (max-width: 767px){
    .webcam-slider{
        padding: 0 43px;
    }
}

.webcam-slider .swiper-container{
    position: unset;
}