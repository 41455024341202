.nav-tabs.nav-tabs {
    position: relative;
    border-bottom: 1px solid #ccd1da;
}
.nav-tabs>li>a.active, .nav-tabs>li>a.active:focus, .nav-tabs>li>a.active:hover {
    border: 0;
    color: #006390;
    background-color: transparent;
    border-bottom: 3px solid #006390;
}
.slide-tabs .nav-tabs>li>a.active, .slide-tabs .nav-tabs>li>a.active:focus, .slide-tabs .nav-tabs>li>a.active:hover{
    border: 0;
    border-bottom: 1px solid;
    border-color: #ddd #ddd transparent;
}

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover{
    border: none;
}

.nav-tabs>li.active>a.btn-grey{
    background: #eeeff2;
    color: #86898d;
}

.nav-tabs>li {
    padding-right: 15px;
}
.nav-tabs>li>a {
    border: 0;
    border-radius: 0;
    padding: 8px 0 0 0;
    border-bottom: 3px solid transparent;
}
.nav-tabs>li>a:hover {
    background: none;
}

/*Button group tabs*/
.btn-group.nav-tabs {
    border-bottom: 0;
}
.btn-group.nav-tabs>li {
    padding-right: 1px;
}
.btn-group.nav-tabs>li>a.active, .btn-group.nav-tabs>li>a.active:focus, .btn-group.nav-tabs>li>a.active:hover, .btn-group.nav-tabs>li>a:hover, .btn-group.nav-tabs>li>a:focus {
    background: #006390;
    color: #fff;
}
.btn-group.nav-tabs>li.active>a.btn-white-border{
    border: 1px solid #fff;
}
.slide-tabs li>a:focus {
    outline: none;
    background: none;
}
@media screen and (min-width: 768px){
    .slide-tabs__active {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        background-color: #006390;
        z-index: 5;
        transition: 250ms cubic-bezier(0.65, 0.05, 0.36, 1);
    }
    .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
        border-bottom: 0;
    }
    .nav-tabs>li>a {
        padding: 0 0 5px 0;
        border-bottom: 0;
    }
    .nav-tabs>li {
        padding-right: 28px;
    }
}

.nav-tabs--sm {
    font-size: 14px;
    display: flex;
    margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
    .nav-tabs--sm {
        justify-content: space-between;
        white-space: nowrap;
        overflow: auto;
        overflow-y: hidden;
    }
    .container--no-padding-xs .nav-tabs--sm {
        padding: 0 20px;
    }
}
@media screen and (min-width: 1200px) {
    .nav-tabs--sm {
        font-size: 16px;
    }
}
.nav-tabs.nav-tabs--sm>li {
    padding: 0;
    text-align: center;
}
.nav-tabs.nav-tabs--sm > li:not(:last-child) {
    margin-right: 10px;
}
@media screen and (min-width: 1200px) {
    .nav-tabs.nav-tabs--sm > li:not(:last-child) {
        margin-right: 16px;
    }
}

.tab-content>.tab-pane:not(.active):first-child {
    display: none;
}

.tab-input{
    position: absolute;
    width: 0.0625rem;
    height: 0.0625rem;
    margin: -0.0625rem;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.btn-tab-input-toggle.active,
.btn-tab-input-toggle:hover,
.btn-tab-input-toggle:focus{
    background: #00a4d0;
    color: #fff;
}

.btn-tab-input-toggle .icon{
    pointer-events: none;
}