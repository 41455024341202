.oax.oax,
.oax.oax button,
.oax.oax input,
.oax.oax select,
.oax.oax textarea {
    font-family: 'RobotoLight', 'RobotoLight-latin-ext', 'RobotoLight-cyrillic', sans-serif;
    font-weight: normal;
}
.oax.oax .oax_h3, .oax.oax b,
.oax.oax strong, .oax.oax .oax_s2,
.oax.oax .oax_h4, .oax.oax .oax_bold,
.oax.oax .oax-map-panel-cont .oax-mcp-section-title,
.oax.oax .oax-map-panel-cont .oax-mlp-section-title {
    font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    font-weight: 400;
}
.oax.oax .oax_icon_font {
    font-family: "WebfontRegular";
    text-transform: none;
}
.oax .oax-gallery-further-btn.oax-gallery-further-btn,
.oax .oax-list-further-btn.oax-list-further-btn{
    background-image: none;
    width: 200px;
    padding: 11px 0;
    line-height: 24px;
    background-color: #00405E;
    font-size: 16px;
    color: #fff;
    font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    margin-top: 40px;
}
.oax .oax-gallery-further-btn.oax-gallery-further-btn {
    line-height: 28px;
}
.oax.oax h3.oax_h2 {
    font-weight: 400;
    font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
}
.oax.oax .oax_singleView_title h1, .oax.oax .oax_detail_right h2.oax_h2, .oax.oax .oax_detail_extended h3.oax_h2,
.oax.oax h3.oax_h4 {
    font-family: 'SohoPro', sans-serif;
    color: #0082a4;
    font-weight: 400;
    font-size: 32px;
}
.oax.oax .oax_detail_right h2.oax_h2,
.oax.oax .oax_detail_extended h3.oax_h2 {
    font-size: 26px;
    margin-bottom: 20px;
}
.oax.oax h3.oax_h4 {
    /*font-style: 24px;*/
    margin-bottom: 20px;
}
.oax.oax .oax_region_container h3.oax_marg_top_12 {
    margin-top: 40px;
}
.oax .oax-gallery-further-btn.oax-gallery-further-btn:hover,
.oax .oax-list-further-btn.oax-list-further-btn:hover{
    background-color: #006390;
}
.oax.oax .oax-gallery-snippet .oax_gallery_snippet {
    padding: 8px;
    padding-bottom: 0;
    margin-left: 0;
    margin-top: 0;
}
.oax.oax .oax-gallery-snippet .oax_image img {
    left: 15px;
    top: 7px;
}
.oax.oax .oax-gallery-snippet .oax_fix_title_height {
    height: 62px;
}
.oax.oax .oax_quickfacts .oax_h4 img {
    margin-top: -12px;
}
.oax .oax_quickfacts .oax_h4 .oax_topLabelIs {
    position: relative;
    top: -1px;
}
.oax.oax .oax_quickfacts .oax_table_container {
    margin-right: 10px;
    margin-left: -7px;
}
.oax-gallery-snippet-tmpl .oax_h4 .oax_dark.oax_font_16.oax_bold{
    display: block;
    font-family: 'RobotoLight', 'RobotoLight-latin-ext', 'RobotoLight-cyrillic', sans-serif;
    font-size: 18px !important;
}
.oax-gallery-snippet-tmpl .oax_quickfacts {
    padding-top: 2px;
}
.oax-gallery-snippet-tmpl .oax_quickfacts,.oax-gallery-snippet-tmpl .oax_ellipsis, .oax-gallery-snippet-tmpl.oax-gallery-snippet-tmpl .oax_h4 {
    padding-left: 4px;
}
.oax .oax-gallery-snippet-tmpl th, .oax .oax-gallery-snippet-tmpl td {
    border-bottom: 0;
}
.oax.oax .oax_flex_view_filter_elements,
.oax.oax .oax_show_gradient .oax-view-controls-box,
.oax.oax .oax_flex_view_filter,
.oax.oax .oax_show_block .oax-view-controls-box {
    background: #eeeff2;
}
.oax.oax .oax-filter-toggle .oax_filter_toggle_click .oax-filter-close-click,
.oax.oax .oax-filter-toggle .oax_filter_toggle_click .oax-filter-open-click {
    background-color: #fff;
    color: #404040;
}
.oax.oax .oax-filter-selectors {
    background: #82b80d;
}
.oax.oax .oax_show_gradient .oax-view-controls-box,
.oax.oax .oax_show_block .oax-view-controls-box {
    height: 55px;
}
.oax.oax .oax_filter_shadow, .oax.oax .oax_gallery_snippet_shadow {
    display: none;
}
.oax.oax .oax_selector_button.oax-what-has-selection,
.agMapFullScreen .oax.oax .oax_selectors .oax_selector_button,
.oax.oax .oax_smallFlexView .oax_selectors .oax_selector_button {
    background: #fafbfb !important;
    border: 1px solid #ced2d9;
    border-radius: 0;
    color: #404040;
    box-shadow: none;
}
.oax.oax .oax_selectors .oax_selector_input {
    height: 32px;
}
.oax.oax .oax_selectors .oax_selector_input:focus {
    box-shadow: none;
}
.oax.oax .oax_selectors .oax_selector_text {
    padding-top: 4px;
}
.oax.oax .oax_smallFlexView .oax_selectors .oax_selector_button {
    width: 100px;
}
.oax.oax .oax_space_block, .oax.oax .oax_what_dropdown,
.oax.oax .oax_selector_button.has-alp-view-tooltip,
.oax.oax .oax_selector_button {
    background: #fafbfb !important;
    box-shadow: none !important;
    border: 1px solid #ced2d9;
    border-radius: 0;
}
.oax.oax .oax-what-dropdown-wrapper .oax_space_block {
    border-top: 0;
    border-bottom: 0;
    position: relative;
    padding-top: 10px;
    padding-bottom: 5px;
}
.oax.oax .oax_what_dropdown .oax_what_dropdown_group a {
    font-style: normal;
}
.oax.oax .oax_what_dropdown .oax_what_dropdown_group a.oax_maingroup {
    font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    font-weight: 400;
}
.oax.oax .oax_selector_button .oax-activity, .oax.oax .oax_selector_text {
    font-style: normal;
    font-size: 16px;
}
.oax.oax .oax_selector_button .oax_dropdown_down,
.oax.oax .oax_selector_button.has-alp-view-tooltip .oax_dropdown_up {
    color: #0097cf;
    font-size: 20px;
}
.oax.oax .oax_selector_button .oax-activity.oax_ellipsis {
    width: 230px;
}
.oax.oax .oax_selector_button {
    width: 270px;
}
.oax.oax .oax_selector_input, .oax.oax .oax_smallFlexView .oax_selectors .oax_selector_input {
    background: #fafbfb;
    border: 1px solid #ced2d9;
    font-style: normal;
    color: #48494c;
    box-shadow: none;
    border-radius: 0 !important;
    font-size: 16px;
    height: 35px;
}
.oax.oax .oax_smallFlexView .oax_selectors .oax_selector_input {
    font-size: 11px;
    height: auto;
    width: 100px;
}
.oax.oax .oax_hoverlink:hover {
    color: #0097cf !important;
}
.oax.oax .oax_view_controls button.oax_selected, .oax.oax .oax_view_controls button.oax_selected:hover {
    background-color: #00a4d0;
    color: #FFF;
    font-weight: normal;
    font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    border: 0;
    margin-top: 0;
}
.oax.oax .oax_view_controls button, .oax.oax .oax-mapbtns-wrapper div button.oax_view_toggle {
    background: #006390;
    opacity: 1;
    border-radius: 0;
    height: 35px;
}
.oax.oax .oax_view_controls button:hover, .oax.oax .oax_view_controls button:active, .oax.oax .oax_view_controls button:active:hover {
    background-color: #00a4d0;
    border-radius: 0;
    height: 35px;
}
.oax.oax .oax-mapbtns-wrapper div button.oax_view_toggle {
    width: 35px;
    background: #00a4d0 !important;
    border-left: 0;
}
.oax.oax .oax-mapbtns-wrapper div button.oax_view_toggle .oax-icon-maximize, .oax.oax .oax-mapbtns-wrapper div button.oax_view_toggle .oax-icon-minimize {
    position: relative;
    top: -1px;
}
.oax.oax .oax-magazine-container {
    padding-top: 30px;
}
.oax.oax .alp-x-slider-sel {
    background-color: #00a4d0;
}
.oax.oax .oax_flex_view_filter_elements .alp-styleform-checked {
    font-weight: 400;
    color: #0097cf;
}
.oax.oax .oax_flex_view_filter_elements .oax_difficulty .alp-styleform-checked {
    color: #fff;
}
.oax-part-flexviewpage.oax-part-flexviewpage, .oax-singleviewpage.oax-singleviewpage {
    z-index: 998;
}
.oax.oax .oax-view-controls-cont {
    background: #00a4d0;
}
.oax.oax .oax-fluid-list-further-btn, .oax.oax .oax-filter-results-button {
    background: #00405e;
    color: #fff;
}
.oax.oax .oax_bold.oax_italic {
    font-style: normal;
}
.oax.oax .oax_what_wrapper .oax-cat-lnk.oax-highlight {
    color: #0097cf;
}
.oax.oax .oax_what_wrapper .oax-cat-toggle-lnk, .oax.oax .oax_sort_wrapper div .oax_icon_font {
    background: #eeeff2;
    color: #86898d;
}
.oax.oax .oax_sort_wrapper div .oax_icon_font {
    padding-top: 1px;
}
.oax.oax .oax_sort_wrapper div .oax_icon_font.oax-highlight {
    color: #fff;
    background: #00a4d0;
    border-color: #00a4d0;
}
.oax-itb-out-cont.oax-itb-out-cont, .oax.oax .oax-itb-out-cont,
.oax_itb_cont.oax_itb_cont .oax-itb-out-mouse-ctrl {
    background-color: #e7e9ec;
}
.oax_itb_cont.oax_itb_cont .oax-itb-out-mouse-ctrl .oax_itb_text,
.oax-itb-out-mouse-ctrl-one.oax-itb-out-mouse-ctrl-one, .oax.oax .oax-itb-out-close, .oax.oax .oax-itb-out-mouse-ctrl-one {
    color: #404040;
}
.oax_itb_cont.oax_itb_cont .oax-itb-out-close {
    color: #fff;
    background: #00a4d0;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYTRkMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjYmQzMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(left,  #00a4d0 0%, #cbd300 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,#00a4d0), color-stop(100%,#cbd300));
    background: -webkit-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: -o-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: -ms-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: linear-gradient(to right,  #00a4d0 0%,#cbd300 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a4d0', endColorstr='#cbd300',GradientType=1 );
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 !important;
    padding: 12px;
    text-align: center;
}
.oax.oax .oax_what_wrapper ul ul li a {
    background: none;
    font-style: normal;
    padding-left: 20px;
}
.oax.oax .oax_what_wrapper .oax-cat-toggle-exp .oax_main_cat {
    background: none;
    color: #0097cf;
    font-weight: normal;
}
.oax-itb-out-mouse-ctrl-prev {
    position: relative;
    left: -10px;
}
.oax-itb-out-mouse-ctrl-next {
    position: relative;
    right: 5px;
}
.oax.oax .oax_mobile_button {
    border-radius: 0;
}
.oax.oax .oax_mobile_button .oax_italic {
    font-style: normal;
}
.oax .oax-wrapper-desc-toggle.oax-wrapper-desc-toggle {
    background-color: #fff;
    border: 1px solid #00a4d0;
    color: #00a4d0;
}
.oax.oax .oax_detail_tabs>h2 label {
    background-color: #fff;
    border: 0;
    color: #86898d;
    font-family: RobotoBold,RobotoBold-latin-ext,RobotoBold-cyrillic,sans-serif;
    font-weight: 400;
    font-size: 17px;
    text-transform: uppercase;
    margin-bottom: 1px;
}
.oax.oax .oax_detail_tabs>h2 label:hover, .oax.oax .oax_detail_tabs input[type=radio]:checked+h2 label {
    background-color: #fff;
    color: #00a4d0;
}
.oax.oax .oax_detail_tabs input[type=radio]:checked+h2+div {
    border-top: 1px solid #ccd1da;
}
/*Affix nav*/
.oax.oax .oax-view-controls-box.oax_fix_view_controls {
    top:131px;
}
/*Affix*/
.oax.oax .oax_open_filter .oax-view-controls-box.oax_fix_view_controls {
    top: 301px;
}
.oax.oax .oax-filter.oax_fix_flex_view_filter {
    z-index: 6;
    top: 131px;
}

/*List view*/
.oax.oax .oax-list-container table td {
    padding: 15px 0;
}
.oax.oax .oax-list-container table th strong.alp-sorthead-selected {
    background: #f3f3f3;
}
.oax.oax .oax-list-container .oax_ellipsis strong.oax_s1 .oax_s1 {
    font-size: 14px;
}
.oax.oax .oax-list-container th, .oax.oax .oax-list-container td {
    border-bottom: 1px solid #ced2d9;
}
.oax.oax .oax-list-container table tr.oax_tour_body td div {
    border-right: 0;
}

/*Map view*/
.oax.oax .oax-mlp-toggle button,
.oax.oax .oax-mlp-toggle.oax-selected button,
.oax.oax .oax-toggle-placeholder button {
    height: 35px;
}
.oax.oax .oax-mapbtns-wrapper.oax-mapbtns-open div button {
    border-bottom: 0;
}
.oax.oax .oax-map-panel-cont {
    top: 43px;
    right: 45px;
}
.oax.oax .oax-mapList-toggled {
    top: 50px;
}
.oax.oax .oax-mapbtns-wrapper div.oax_no_display+div button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.alp-x-clickable.alp-x-clickable, .oax.oax .alp-x-clickable {
    margin: -2px 0 2px 2px;
}

/*Fullscreen*/
.agMapFullScreen .oax.oax .oax-view-controls-box {
    width: 100% !important;
}
.oax.oax.agMapFullScreen .oax_selectors, .agMapFullScreen .oax.oax .oax_selectors {
    top: 110px;
}
.agMapFullScreen .oax .oax_tour .oax_flex_view_filter {
    height:130px !important;
}
.oax.oax.agMapFullScreen .oax_flex_view_wrapper {
    top: 130px;
}
.oax.oax.agMapFullScreen .oax-filter.oax_fix_flex_view_filter, .agMapFullScreen .oax.oax .oax-filter.oax_fix_flex_view_filter {
    top: 130px !important;
}
.agMapFullScreen .oax.oax .oax_tour .oax_flex_view_container.oax_open_filter .oax-view-controls-box.oax_fix_view_controls {
    position: relative;
    top: 0 !important;
    width: 100% !important;
}
.agMapFullScreen .oax.oax .oax-what-dropdown-wrapper {
    top: 141px !important;
}
.agMapFullScreen .oax.oax .oax-what-dropdown-wrapper .oax_space_block {
    width: 142px;
}
.agMapFullScreen.agMapFullScreen .oax.oax .oax_flex_view_container.oax_open_filter {
    padding-bottom: 200px !important;
}
.agMapFullScreen .oax.oax .oax_tour .oax_flex_view_container.oax_open_filter .oax-list-table-head-box {
    top: 340px !important;
}
.oax.oax.agMapFullScreen .oax-gallery, .agMapFullScreen .oax.oax .oax-gallery {
    margin-left: auto;
    margin-right: auto;
    max-width: 1770px;
    float: none;
    min-width: unset;
}
.oax.oax .oax-filter-selectors.oax_fix_filter_selectors {
    top: 75px;
}
.oax_weather_text.oax_weather_text .oax_font_30 {
    font-family: 'SohoPro', sans-serif;
    font-weight: 400;
    color: #013e59;
}
.oax_hoverlink.oax_hoverlink .oax_italic {
    font-style: normal;
}
/*lightbox*/
.alp-x-lightbox-mask.alp-x-lightbox-mask {
    background: #e7e9ec;
    opacity: 1;
}
.alp-x-lightbox-close.alp-x-lightbox-close {
    color: #fff;
    background: #00a4d0;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYTRkMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjYmQzMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(left,  #00a4d0 0%, #cbd300 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,#00a4d0), color-stop(100%,#cbd300));
    background: -webkit-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: -o-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: -ms-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: linear-gradient(to right,  #00a4d0 0%,#cbd300 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a4d0', endColorstr='#cbd300',GradientType=1 );
    font-size: 50px;
    width: 100%;
    left: 0;
    height: 60px;
    text-indent: inherit;
    text-align: right;
    padding: 12px 15px;
    line-height: 50px;
    cursor: pointer;
    top: 0;
    box-sizing: border-box;
}
.alp-lightboxview-next.alp-lightboxview-next, .alp-lightboxview-prev.alp-lightboxview-prev {
    background: none;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0,0);
    font-size: 75px;
    color: #48494c;
    margin-right: 20px;
}
.alp-lightboxview-prev.alp-lightboxview-prev {
    transform: rotate(180deg);
    margin-left: 20px;
}
.alp-lightboxview-next:before, .alp-lightboxview-prev:before {
    font-family: 'iconfont' !important;
    content: "\E004";
}
.alp-lightboxview-prev:before {
    transform: rotate(180deg);
}
.oax.oax .oax_s1 {
    font-size: 14px;
}
.alp-lightboxview-thumb-top .alp-lightboxview-next.alp-lightboxview-next, .alp-lightboxview-thumb-top .alp-lightboxview-prev.alp-lightboxview-prev {
    color: #999;
    font-size: 35px;
}
.alp-lightboxview-thumb-top .alp-lightboxview-prev.alp-lightboxview-prev {
    top: 66px;
    position: relative;
}
.oax-gallery.oax-gallery-snippet-cont .oax-gallery-snippet .oax-gallery-snippet-tmpl .oax_image img {
    left: 0;
    top: 0;
}
.oax.oax .oax_open_filter .oax-list-table-head-box.oax_fix_list_table_head {
    top: 356px;
}
.agMapFullScreen #console {
    display: none;
}
.oax.oax .oax_flex_view_filter_elements .oax_abstractDifficulty {
    margin: 0;
    padding: 1px 5px 0;
}
.oax.oax .oax_flex_view_filter_elements .oax_h3 {
    margin-bottom: 5px;
}
.oax-smallscreen .oax.oax .oax-mapbtns-wrapper {
    margin-top: 4px;
}
.oax-smallscreen .oax .oax_filter_wrapper select {
    font-style: normal;
}
@media screen and (min-width: 786px){
    .oax-smallscreen .oax.oax .oax-mapbtns-wrapper + .oax_clear{
        display: none;
    }
    .oax-smallscreen .oax.oax .oax-mapbtns-wrapper ~ .oax_filter_toggle,
    .oax-smallscreen .oax.oax .oax-mapbtns-wrapper ~ .oax_sort_toggle_container {
        margin-top: 10px;
    }
    .oax-smallscreen .oax_flexView_wi_max .oax_sort_wrapper {
        top: 45px;
    }
    .oax-smallscreen .oax .oax_filter_wrapper .oax_last_block {
        width: 330px;
    }
}
.agMapFullScreen .oax.oax .oax_flex_view_filter_elements .oax_filter_reset {
    right: 20px;
}
.agMapFullScreen .oax.oax .oax-flexView {
    top: 100px;
}
.nav-wrapper.affix ~ .main-content .oax.oax .oax-flexView {
    top: 74px;
}
.nav-wrapper.affix ~ .main-content .oax.oax .oax_flex_view_wrapper {
    top: 129px;
}
.agMapFullScreen .oax.oax .oax_flex_view_wrapper {
    top: 157px;
    height: calc(100vh - 100px);
}
@media screen and (min-width: 1500px) {
    .agMapFullScreen .oax .oax_flex_view_filter_elements .oax_tour_properties {
        top: 5px !important;
        height: 110px !important;
    }
    .agMapFullScreen .oax .oax_flex_view_filter_elements {
        height: 130px !important;
    }
    .agMapFullScreen .oax .oax_flex_view_filter_elements .oax_filter_reset {
        bottom: 5px;
    }
}
@media screen and (max-width: 767px){
    .oax.oax .oax_smallFlexView.oax-selectors-wrapper {
        margin-bottom: 20px;
    }
    .oax.oax .oax-filter.oax_fix_flex_view_filter,
    .oax.oax .oax_open_filter .oax-view-controls-box.oax_fix_view_controls,
    .oax.oax .oax-filter-toggle.oax_fix_filter_toggle,
    .oax.oax .oax_selectors.oax_fix_selectors,
    .oax.oax .oax_open_filter .oax-list-table-head-box.oax_fix_list_table_head {
        position: relative;
        top: 0;
    }
    .oax.oax .oax_open_filter .oax-view-controls-box.oax_fix_view_controls,
    .oax.oax .oax_show_gradient .oax-view-controls-box {
        height: 110px;
    }
    .oax .oax-ready .oax_view_controls button {
        margin-bottom: 10px;
    }
    .oax.oax .oax-gallery-snippet:hover .oax_gallery_snippet,
    .oax.oax .oax-gallery-snippet .oax_gallery_snippet {
        border-bottom: 1px solid #ced2d9;
    }
}
@media screen and (min-width: 768px){
    .oax.oax .oax-gallery-snippet:hover .oax_gallery_snippet {
        border-right: 1px solid #ced2d9;
    }
    .oax.oax .oax-gallery-snippet .oax_gallery_snippet {
        border-right: 1px solid #ced2d9;
    }
}
.oax_title_block .oax_title.oax_h1,
.oax_section_headline h2.oax_h2,
.oax-statistics-cont h2.oax_h2,
.oax_weather_box .oax_forecast_head .oax_em,
.oax.oax .oax_h2.oax_headline_color{
    font-family: 'SohoPro', sans-serif;
    color: #0082a4;
    font-weight: 400;
    font-size: 24px;
}
.oax_weather_box.oax_weather_box .oax_font_30 {
    font-family: 'SohoPro', sans-serif;
    font-weight: 400;
    color: #013e59;
    font-size: 22px;
}

.agMapSmallScreen .main-content .slide.slide-img {
    z-index:998;
}

/*loading spinner*/
.oax-top-cont>div:first-child {
    display: none;
}
.oax-top-cont>div:last-child {
    display: block;
}

.oax .oax-where-field {
    top: -7px;
}

.linklist-detail{
    height: 0;
    overflow: hidden;
}

.oax .oax-flexmode-map .oax-filter-selectors.oax_fix_filter_selectors,
.oax .oax-flexmode-map .oax-filter.oax_fix_flex_view_filter,
.oax .oax-flexmode-map .oax_open_filter .oax-view-controls-box.oax_fix_view_controls,
.oax .oax-flexmode-listMap .oax-filter-selectors.oax_fix_filter_selectors,
.oax .oax-flexmode-listMap .oax-filter.oax_fix_flex_view_filter,
.oax .oax-flexmode-listMap .oax_open_filter .oax-view-controls-box.oax_fix_view_controls{
    position: relative;
    top: 0;
}

.agMapFullScreen .oax .oax_flex_view_filter_elements .oax_tour_state{
    top: 5px !important;
}
.agMapFullScreen .oax.oax .oax_flex_view_filter_elements .oax_filter_reset{
    bottom: 5px;
}
.agMapFullScreen .oax .oax_tour .oax_filter_container{
    height: 130px !important;
}

.oax .oax_flex_view_filter_elements .oax_filter_label.oax_filter_label {
    max-width: calc(100% - 23px);
}

.alp-x-image-zoomable .alp-x-image-img {
    padding-top: 70px;
}

.oax_statistics_container,
.oax-statistics-cont {
    display: none;
}

.oax .oax_view_controls button span {
    color: #fff;
}