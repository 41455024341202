.btn {
    border-radius: 0;
    border: 0;
    text-transform: uppercase;
    position: relative;
    font-size: 15px;
    padding: 14px 24px;
}

@media screen and (min-width: 768px){
   .btn{
       font-size: 16px;
   }
}
.btn:hover, .btn:active, .btn:focus {
    box-shadow: none;
    color: #fff;
}
.btn-icon-submit, .btn-icon-submit:focus, .btn-icon-submit:hover {
    background: none;
    padding: 0;
}
.btn.btn-link:hover{
    color: #0082a4;
}
/*Button sm*/
.btn-sm {
    padding: 8px 12px;
}

@media screen and (min-width: 768px){
    .btn-sm {
        padding: 6px 20px;
    }
}

/* Button large */
.btn-lg {
    padding: 10px 65px;
}

/*Button XL*/
.btn-xl {
    padding: 10px 20px;
    text-align: left;
}
.btn-xl .icon {
    font-size: 18px;
    top: 2px;
    position: relative;
}

/*Button Color*/
.btn-primary {
    background-color: var(--color-btn-primary);
}
.btn-primary:hover, .btn-primary:focus {
    background-color: var(--color-btn-primary--hover);
}

.btn-primary.border {
    background-color: #00a4d0;
    border: 1px solid #00a4d0;
}
.btn-primary.border:hover, .btn-primary.border:focus {
    background-color: #59c4e0;
    border: 1px solid #59c4e0;
}

.btn-secondary {
    background-color: var(--color-btn-secondary);
    color: #fff;
}
.btn-secondary:hover, .btn-secondary:focus {
    background-color: var(--color-btn-secondary--hover);
}
.btn-blue {
    background: #006390;
    color: #fff;
}
.btn-blue:hover {
    background: #004f73;
}
.btn-blue-light {
    background: #59c4e0;
    color: #fff;
}
.btn-checkbox.btn-blue-light.active,
.btn-blue-light:hover,
.btn-radio.btn-blue-light.active {
    background: #006b87;
    box-shadow: none;
}
.btn-checkbox, .btn-radio {
    margin-right: 6px;
    margin-bottom: 10px;
    padding: 9px 19px;
}
.btn-green {
    background-color: #83b81a;
    color: #fff;
}
.btn-green:hover {
    background-color: #9cc648;
}
.btn-grey {
    background: #eeeff2;
    color: #86898d;
}
.btn-grey:hover {
    background: #00a4d0;
}
.btn-white {
    background: #fff;
    color: var(--color-btn-primary);
    transition: background-color 250ms ease-in-out, color 250ms ease-in-out;
}
.btn-white:hover {
    background: var(--color-btn-primary--hover);
}
.btn-white-border.btn-white-border {
    border: 1px solid #fff;
    background-color: transparent;
    color: #ffffff;
    transition: background-color 250ms ease-in-out, color 250ms ease-in-out;
}
.btn-white-border.btn-white-border:hover {
    background-color: #fff;
    color: var(--color-btn-primary);
}
.btn-blue-border {
    border: 1px solid var(--color-btn-primary);
    background-color: transparent;
    color: var(--color-btn-primary);
    font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    font-weight: 400;
}
.btn-blue-border:hover, .btn-blue-border:focus {
    color: var(--color-btn-primary--hover);
    border: 1px solid var(--color-btn-primary--hover);
}
.btn-transparent-blue {
    background-color: rgba(0,35,50, 0.3);
    border: 1px solid #fff;
    transition: 200ms ease-in-out;
}
.btn-transparent-blue:hover {
    background-color: rgba(0,35,50,.5);
    color: #fff;
}

/*Full width button*/
.btn-full-width {
    width: 100%;
}

/*icon*/
.btn.btn-icon-text{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: calc(10rem/16);
    /*padding-top: calc(22rem/16);*/
    /*padding-bottom: calc(24rem/16);*/
}
.btn-icon-text > .btn-icon-text__icon{
    font-size: calc(16rem/16);
    /*line-height: 1;*/
}

/*Button Icon only*/
.btn.btn-icon {
    width: 50px;
    height: 50px;
    padding: 12px;
}
.btn-group .btn+.btn {
    margin-left: 0;
}
.btn-group .btn-icon {
    margin-right: 1px;
}
.btn-group .btn-icon:last-child{
    margin-right: 0;
}
.btn-group .btn-grey.btn-icon {
    border-bottom: 0;
}

/*Read more button*/
.read-more-btn.collapsed {
    visibility: visible;
}
.read-more-btn {
    visibility: hidden;
}

/*Newsletter button*/
.btn-newsletter {
    font-family: 'SohoPro', sans-serif;
    font-weight: normal;
    color: #006390;
    text-transform: none;
    padding: 16px 10px;
    font-size: 16px;
    transition: 250ms ease-in-out;
    white-space: normal;
}
.btn-newsletter>.icon-newsletter {
    margin-right: 5px;
    top: 2px;
    position: relative;
}
.btn-newsletter:hover {
    background: #00405e;
}
/*select fake button*/
.btn-select-fake {
    background-color: #FAFBFB;
    text-transform: none;
    border: 1px solid #00a4d0;
    padding: 13px 25px 13px 12px;
    width: 100%;
    color: #00a4d0;
    text-align: left;
}
.btn-select-fake.collapsed {
    border-color: #CED2D9;
    color: #404040;
}
.btn-select-fake.collapsed:after {
    transform: rotate(0deg);
}
.btn-select-fake.collapsed:focus {
    color: #404040;
}
.btn-select-fake:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0;
    border-color: #00a3d0 transparent transparent;
    transform: rotate(180deg);
    pointer-events: none;
}
.btn-select-fake:hover, .btn-select-fake:focus {
    color: #00a4d0;
}
.btn-select-fake__placeholder {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media screen and (min-width: 768px){
    .btn-xl {
        padding: 20px;
    }
    .btn-xl .icon {
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -18px;
        font-size: 37px;
    }
    .btn-newsletter {
        font-size: 30px;
        padding: 28px 10px;
    }
    .btn-newsletter>.icon-newsletter {
        margin-right: 15px;
        top: 3px;
    }
}

/*loading btn*/
.loading-btn:after {
    content: '';
    background-color: rgba(0,0,0,0);
    border: 5px solid rgba(0,164,208,0.9);
    opacity: .9;
    border-top: 5px solid rgba(0,0,0,0);
    border-left: 5px solid rgba(0,0,0,0);
    border-radius: 50px;
    width: 35px;
    height: 35px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    margin-top: -17.5px;
    right: -40px;
    -o-animation: spin .6s infinite linear;
     -webkit-animation: spin .6s infinite linear;
     -moz-animation: spin .6s infinite linear;
    animation: spin .6s infinite linear;
    visibility: hidden;
    transition-delay: 150ms;
}
.loading-btn:before {
    content: '';
    background: #00405E;
    right: -50px;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    transform: translateX(-50px);
    transition: transform 250ms ease-in-out;
    z-index: -1;
}
.is-loading .loading-btn:before, .is-loading .loading-btn:after,
.loading-btn.is-loading:before, .loading-btn.is-loading:after {
    transform: translateX(0);
    z-index: 0;
    visibility: visible;
}
.loading-btn:focus, .loading-btn:focus:active {
    outline: none;
}
.loading-btn:focus {
    background: #00405E;
}
@-moz-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.btn__icon {
    vertical-align: -.2em;
    margin-right: 10px;
}
.btn-no-style{
    background: none;
    border: none;
}
.btn-no-styling{
    background: none;
    border: none;
    padding: 0;
}
.btn-no-styling:hover,
.btn-no-styling:active,
.btn-no-styling:focus{
    outline: none;
}
.btn-fullwidth-mobile{
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}