@media screen and (max-width: 767px) {
    .mobile-sticky-bar {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        z-index: 100;
    }
    .mobile-sticky-bar__btn {
        display: block;
        width: 50px;
        height: 50px;
        box-shadow: 3px 0 6px 0 rgba(0,0,0,0.12);
        font-size: 24px;
    }
    .mobile-sticky-bar__btn__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .mobile-sticky-bar__nav {
        width: 100vw;
        position: absolute;
        right: 0;
        top: 100%;
        height: 50px;
        background: #8bbe50;
        box-shadow: 3px 0 6px 0 rgba(0,0,0,0.12);
        margin-top: 1px;
        transform: translateX(calc(100% - 50px));
        transition: transform 200ms ease-in-out;
    }
    .is-open.js-inline-nav-container {
        transform: translateX(0);
    }
    .mobile-sticky-bar__nav__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        z-index: 1;
        font-size: 20px;
        color: #fff;
        width: 50px;
        padding: 0 9px;
    }
    .is-open .mobile-sticky-bar__nav__icon,
    .mobile-sticky-bar__nav__icon:hover,
    .mobile-sticky-bar__nav__icon:focus {
        color: #fff;
    }
    .is-open .mobile-sticky-bar__nav__icon:before {
        content: "\E004";
        font-size: 17px;
    }
    .mobile-sticky-bar__nav .inline-nav {
        opacity: 0;
        padding-left: 50px;
    }
    .is-open.mobile-sticky-bar__nav .inline-nav {
        opacity: 1;
        padding-left: 35px;
    }
}