.wysiwyg a {
    color: #00a4d0;
    text-decoration: underline;
}
.wysiwyg a:hover {
    color: #0083a6;
}
.wysiwyg ul {
    overflow: auto;
    padding-left: 0;
}
.wysiwyg ul>li {
    list-style: none;
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
}
.wysiwyg ul>li:before {
    content: '';
    position: absolute;
    height: 6px;
    width: 6px;
    left: 2px;
    top: 9px;
    background: #00a4d0;
}
.wysiwyg ol li {
    margin-bottom: 10px;
}
.wysiwyg--lg {
    line-height: calc(30/16);
}

/*Table*/
.wysiwyg table {
    width: 100%;
    max-width: 100%;
}
.wysiwyg table thead {
    background: #0082a4;
    color: #fff;
    text-transform: uppercase;
}
.wysiwyg table thead tr th {
    font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    font-weight: 400;
    padding: 15px 20px;
}
.wysiwyg table tbody tr:nth-child(odd) {
    background: #b3dae4;
}
.wysiwyg table tbody tr:nth-child(even) {
    background: #d2e9ef;
}
.wysiwyg table tbody tr td, .wysiwyg table thead tr th {
    border-right: 1px solid #fff;
}
.wysiwyg table tbody tr td {
    padding: 5px 10px;
    color: #00405e;
}

/*image near wysiwyg*/
.floated-item-container {
    clear: both;
    display: inline-block;
    width: 100%;
}
.floated-item-container--margin {
    margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
    .floated-item-container--margin {
        margin-bottom: 90px;
    }
}
.floated-item {
    margin-bottom: 0.5em;
}
@media screen and (max-width: 767px) {
    .floated-item {
        /*override bootstraps important*/
        width: 100%;
        height: auto;
        margin-bottom: 15px;
    }
    .floated-item img {
        width: 100%;
        height: auto;
    }
}
@media screen and (min-width: 768px) {
    .floated-item.pull-left {
        margin-right: 25px;
    }
    .floated-item.pull-right {
        margin-left: 25px;
    }
    .wysiwyg.two-col{
        column-count: 2;
        column-gap: 50px;
    }
    .wysiwyg table tbody tr td {
        padding: 10px 20px;
    }
}