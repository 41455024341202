@font-face {
    font-family: 'RobotoBold';
    src: url('/static/fonts/Roboto-bold.woff2') format('woff2'),
    url('/static/fonts/Roboto-bold.woff') format('woff');
    font-weight: normal;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'RobotoBold';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url('/static/fonts/cyrillic/Roboto-bold.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'RobotoBold';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url('/static/fonts/latin-ext/Roboto-bold.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'RobotoLight';
    src: url('/static/fonts/Roboto-light.woff2') format('woff2'),
    url('/static/fonts/Roboto-light.woff') format('woff');
    font-weight: normal;
    font-display: swap;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
    font-family: 'RobotoLight';
    src: url('/static/fonts/cyrillic/Roboto-light.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
    font-family: 'RobotoLight';
    src: url('/static/fonts/latin-ext/Roboto-light.woff2') format('woff2');
    font-weight: normal;
    font-display: swap;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'SohoPro';
    src: url('/static/fonts/SohoPro.woff2') format('woff2'),
    url('/static/fonts/SohoPro.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

/*
@font-face {
    font-family: "iconfont";
    src: url('../icons/font/iconfont.woff2') format('woff2'),
    url('../icons/font/iconfont.woff') format('woff'),
    url('../icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}*/
