/* hide slides */
.hero-slider .swiper-container:not(.is-init) .swiper-slide+.swiper-slide{
    display: none;
}


.slick-next,
.slick-prev,
.swiper-button--prev,
.swiper-button--next{
    position: absolute;
    top: 50%;
    font-size: 39px;
    color: #fff;
    margin-top: -11px;
    cursor: pointer;
    z-index: 1;
    line-height: 0;
}
.slick-prev,
.swiper-button--prev {
    left: 10px;
    transform: translateY(-50%) scaleX(-1);
    margin-top: 7px;
}

.slick-next,
.swiper-button--next {
    right: 10px;
}
.content-slider-wide .slick-next, .content-slider-wide .slick-prev {
    font-size: 57px;
    margin-top: -28px;
}

.swiper-button-disabled{
    opacity: 0.3;
}

/*Slick dots only mobile*/
@media screen and (max-width: 767px){
    .slick-dots {
        list-style: none;
        padding: 0;
        width: 100%;
        position: absolute;
        bottom: 0;
        text-align: center;
    }
    .img-slider .slick-dots {
        bottom: 16px;
    }
    .slick-dots .slick-active button,
    .swiper-pagination-bullet.swiper-pagination-bullet-active{
        background-color: #00a1cd;
    }
    .slick-dots button,
    .swiper-pagination-bullet {
        width: 14px;
        height: 14px;
        border-radius: 100%;
        background-color: #fafafa;
        box-shadow: none;
        border: 0;
        font-size: 0;
        opacity: 1;
    }
    .slick-dots button:focus {
        outline: none;
    }
    .slick-dots li {
        display: inline-block;
        margin: 5px;
    }

    /* moodboard slider xs */
    .moodboard-slider-xs {
        padding: 25px 0 25px 15px;
    }
    .moodboard-slider-xs .slick-list {
        padding-left: 0 !important;
    }
    .moodboard-slider-xs .slick-slide {
        margin: 0 10px;
    }
}

/* moodboard slider */
.moodboard-slider {
    padding: 0 0 25px 15px;
    padding-bottom: 15px;
    margin: 0 -20px;
}
@media screen and (min-width: 768px) {
    .moodboard-slider {
        margin: 0;
        padding: 0 50px;
    }
}
@media screen and (min-width: 1500px) {
    .moodboard-slider {
        margin: 0 -50px;
    }
}

.moodboard-slider .swiper-container{
    position: unset;
}
.moodboard-slider .slick-list {
    padding-left: 0 !important;
}
.moodboard-slider .slick-slide {
    margin: 0 5px;
}

.moodboard-slider__arrow {
    left: 0;
    color: #00a4d0;
    font-size: 44px;
}
.moodboard-slider__arrow.slick-prev,
.moodboard-slider__arrow.swiper-button--prev {
    transform: scaleX(-1);
    margin-top: 0;
}
.moodboard-slider__arrow.slick-next,
.moodboard-slider__arrow.swiper-button--next {
    right: 0;
    left: auto;
}

body .moodboard-slider.is-init .swiper-wrapper{
    overflow-y: visible;
    white-space: normal;
}

body .moodboard-slider.is-init .swiper-slide {
    width: auto;
    display: inline-block;
    margin-right: 0;
}