.gallery {
    position: relative;
    padding-top: 31px; /*open-lightbox height +1px*/
}
.open-lightbox {
    position: absolute;
    top: 0;
    height: 30px;
    left: 0;
    width: 100%;
    background: #00a4d0;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYTRkMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjYmQzMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(left,  #00a4d0 0%, #cbd300 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,#00a4d0), color-stop(100%,#cbd300));
    background: -webkit-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: -o-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: -ms-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: linear-gradient(to right,  #00a4d0 0%,#cbd300 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a4d0', endColorstr='#cbd300',GradientType=1 );
    cursor: pointer;
}
.open-lightbox .icon {
    position: absolute;
    top: 50%;
    margin-top: -8px;
    left: 50%;
    margin-left: -10px;
}
.open-lightbox .icon-zoom-in {
    margin-left: -12px;
}
.open-lightbox:hover {
    color: #fff;
}
.gallery-sm .open-lightbox + .row> .special-mark {
    top: 31px; /*open lightbox bar height + 1 */
}
.image-count--arrange {
    height: 100%;
}
.image-count {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px 15px;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    cursor: pointer;
    font-size: 16px;
    z-index: 1;
}
.image-count.image-count-lg {
    padding: 23px 35px;
    font-size: calc(16rem/17);
    text-transform: uppercase;
    font-family: var(--font-bold);
    border: none;
}
.gallery__highlights {
    padding: 20px;
    font-family: 'SohoPro', sans-serif;
    font-weight: 400;
}
.gallery__highlights li {
    padding-left: 30px;
    margin-bottom: 10px;
    position: relative;
}
.gallery__highlights li:before {
    content: "\E010";
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 14px;
}
.gallery .video:before {
    content: "\E051";
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -30px;
    margin-left: -30px;
    font-size: 60px;
    width: 62px;
    color: #fff;
    z-index: 1;
}
.gallery .video:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    transition: 250ms ease-in;
}

.gallery.gallery--transparent{
    @media screen and (max-width: 767px){
       margin-top: 0;
        padding-top: 0;
    }
}

.gallery.gallery--transparent .video:after {
    opacity: 0;
}

.gallery.gallery--transparent .video:hover:after {
    opacity: 0.7;
}

.gallery .gallery__border:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    height: 1px;
    background-color: #fff;
}
.gallery .gallery__border:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    width: 1px;
    background-color: #fff;
}
.gallery .col:nth-child(1):before,
.gallery .col:nth-child(3):before,
.gallery .col:nth-child(5):before,
.gallery .col:nth-child(7):before,
.gallery .col:nth-child(9):before {
    content: none;
}
.gallery__highlights__cat {
    margin-bottom: 0;
}
.gallery__highlights .gallery__highlights__cat--headline {
    font-size: 14px;
}
.gallery__highlights__cat li:before {
    top: 22px;
    color: #00a4d0;
}
.gallery__highlights .gallery__highlights__cat__period:before {
    content: "\E00A";
}
.gallery__highlights .gallery__highlights__cat__location:before {
    content: "\E017";
}
.gallery__highlights .gallery__highlights__cat__type:before {
    content: "\E00E";
}
.gallery__highlights .gallery__highlights__cat__duration:before {
    content: "\E02C";
}
.gallery__highlights .gallery__highlights__cat__theme:before {
    content: "\E03C";
}
@media screen and (min-width: 768px){
    .gallery {
        margin-top: 0;
        padding-top: 0;
    }
    .open-lightbox {
        display: none;
        z-index: 2;
        height: 100%;
        width: 30px;
        top: 0;
        left: -62px;
        width: 62px;
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2NiZDMwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMGE0ZDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(top,  #cbd300 0%, #00a4d0 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#cbd300), color-stop(100%,#00a4d0));
        background: -webkit-linear-gradient(top,  #cbd300 0%,#00a4d0 100%);
        background: -o-linear-gradient(top,  #cbd300 0%,#00a4d0 100%);
        background: -ms-linear-gradient(top,  #cbd300 0%,#00a4d0 100%);
        background: linear-gradient(to bottom,  #cbd300 0%,#00a4d0 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbd300', endColorstr='#00a4d0',GradientType=0 );
    }
    .gallery-sm .open-lightbox {
        display: block;
        width: 42px;
        left: -43px;
    }
    .webcams-row .gallery-sm .open-lightbox {
        display: none;
    }
    .gallery--webcams .open-lightbox {
        left: -63px;
        height: auto;
        bottom: 41px;
    }
    .open-lightbox .icon {
        margin-top: -12px;
    }
    .gallery-sm .open-lightbox + .row> .special-mark {
        top: 0;
    }
    .gallery__highlights {
        padding: 10px 8px 10px 18px;
    }
    .gallery__highlights li:before {
        font-size: 20px;
    }
    .gallery__highlights li {
        padding-left: 40px;
        margin-bottom: 12px;
        font-size: 17px;
    }
    .gallery__highlights li:before {
        top: 4px;
    }
    .gallery .col:nth-child(1):before,
    .gallery .col:nth-child(3):before,
    .gallery .col:nth-child(5):before,
    .gallery .col:nth-child(7):before,
    .gallery .col:nth-child(9):before {
        content: '';
    }
    .nr-gallery-items-1 .col:after {
        content: none;
    }
    .nr-gallery-items-4 .col:nth-child(3):after,
    .nr-gallery-items-4 .col:nth-child(4):after {
        content: none;
    }
    .nr-gallery-items-9 .col:nth-child(7):after,
    .nr-gallery-items-9 .col:nth-child(8):after,
    .nr-gallery-items-9 .col:nth-child(9):after {
        content: none;
    }
}
@media screen and (min-width: 1200px){
    .image-count {
        padding: 5px 30px;
    }
    .gallery__highlights li {
        font-size: 20px;
    }
    .gallery__highlights {
        padding: 28px 10px 10px 28px;
    }
    .gallery__highlights .gallery__highlights__cat--headline {
        font-size: 16px;
    }
    .gallery__highlights__cat li:before {
        top: 21px;
    }
}
@media screen and (min-width: 1460px){
    .open-lightbox, .webcams-row .gallery-sm .open-lightbox {
       display: block;
    }
}