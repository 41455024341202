.seasons{
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 50px;
    overflow: hidden;
}
@media screen and (min-width: 768px) {
    .seasons {
        padding-bottom: 70px;
    }
}
.seasons__content{
    position: relative;
    font-size: 15px;
    line-height: calc(21/17);
    padding: 45px 0 145px;
    background-position: center;
    background-size: cover;
}
@media screen and (min-width: 768px) {
    .seasons__content{
        padding: 55px 0 275px;
        font-size: 17px;
        line-height: calc(26/17);
    }
}
.seasons__content:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #006390;
    opacity: .5;
}
.seasons__content-text {
    position: relative;
}
.seasons__content__title {
    margin-bottom: 30px;
    font-size: 30px;
}
@media screen and (min-width: 768px) {
    .seasons__content__title {
        margin-bottom: 35px;
        font-size: 48px;
    }
}
.seasons__content__bottom {
    margin: -120px -20px 0;
    position: relative;
    z-index: 1;
}
@media screen and (min-width: 768px) {
    .seasons__content__bottom {
        margin: -230px 0 0;
    }
}
.season-teaser {
    position: relative;
}
.season-teaser:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.5) 100%);
    z-index: 1;
    transition: opacity 200ms ease-in-out;
}
.season-teaser:hover:before {
    opacity: .5;
}
.season-teaser__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 20px 5px;
}
@media screen and (min-width: 768px) {
    .season-teaser__content {
        padding: 30px 8px;
    }
}
.season-teaser__content__title {
    display: inline-block;
    border-top: 1px solid #fff;
    color: #fff;
    font-size: 20px;
    line-height: calc(24/20);
    margin-bottom: 0;
    padding: 7px 5px 0;
    margin-top: 5px;
}
@media screen and (min-width: 768px) {
    .season-teaser__content__title {
        font-size: 28px;
        line-height: calc(34/28);
    }
}
.season-teaser__content__icon {
    font-size: 28px;
}
@media screen and (min-width: 768px) {
    .season-teaser__content__icon {
        font-size: 40px;
    }
}