@media screen and (max-width: 767px){
    .overlay{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 5000;
        background-color: #fff;
        padding: 50px 20px 20px;
        overflow-y: scroll;
        transform: translateX(100%);
        transition: all 350ms ease;
    }

    .overlay.is-open{
        transform: translateX(0);
    }

    .btn-close{
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: var(--color-primary);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 20px;
        top: 20px;
        padding: 0;
        font-size: 9px;
    }

    .overlay__title{
        color: var(--color-primary);
        font-family: var(--font-soho);
        font-size: 22px;
        margin-bottom: 10px;
    }
}

