.state-label {
    border: 1px solid #00a4d0;
    color: #00a4d0;
    display: inline-block;
    padding: 2px 5px;
}
.state-label--closed {
    border-color: #d51f0c;
    color: #d51f0c;
}
.state-label--open {
    border-color: #82b80d;
    color: #82b80d;
}