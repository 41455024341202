
.conditions-item  .icon-value__value{
    font-family: 'SohoPro', sans-serif;
    font-size: 18px;
}


.conditions-item  .icon-value__icon {
    min-width: 30px;
    display: inline-block;
}

.conditions-item  .icon-value__icon.icon-humidity{
    margin-right: 8px;
    position: relative;
    left: 7px;
}

.conditions-item  .icon-value__icon.icon-snowflake{
    font-size: 18px;
    position: relative;
    top: 2px;
    min-width: 22px;
}

.panel-conditions .no-border .col{
    border:none;
}

.conditions-item .icon-skier{
    font-size: 38px;
    margin-bottom: 6px;
}

.conditions-item .icon-langlauf-2{
    font-size: 40px;
    margin-bottom: 9px;
}

.conditions-item .conditions__icon {
    margin-bottom: 5px;
    height: 45px;
}

.panel-conditions .weather-condition.conditions-item .weather-icon {
    height: 60px;
    margin-top: -7px;
    margin-bottom: -3px;
}

/*.conditions__icon{*/
    /*flex-grow: 0;*/
/*}*/

.condition-main-info{
    flex-grow: 0;
}

.condition-bottom-info{
    flex-grow: 1;
    padding-bottom: 5px;

    @media screen and (max-width: 767px) {
        margin-bottom: 10px;
    }
}


.conditions-item .contact a{
    margin-left: 20px;
}

.conditions-item .text-contact{
    font-size: 18px;
}

.conditions-item .min-altitude{
    font-size: 24px;
    font-family: SoHoPro, sans-serif;

}

.panel-conditions__bottom{
    background: #f5f6f7;
    margin: 15px -15px -15px;
    padding: 10px 20px 5px;
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .panel-conditions__bottom{
        padding-top: 20px;
    }
}

@media (min-width: 768px) {
    .panel-conditions__bottom {
        margin: 0 -15px;
    }
}

@media (max-width: 767px) {
    .panel-conditions__bottom + .panel-conditions__note {
        margin-top: 15px;
    }
}

.panel-conditions .list-open-days{
    display: inline-block;
    margin-bottom: 0;
}
.panel-conditions .list-open-days>li{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #d1d5db;
    color: #d1d5db;
    position: relative;
    margin: 0 2px;
}
.panel-conditions .list-open-days>li.open{
    background: #006390;
    color: #fff;
    border: 1px solid #006390;

}

.panel-conditions .list-open-days>li>span{
    font-size: 14px;
    font-family: RobotoLight, sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.panel-conditions .text-open-days{
    font-family: SoHoPro,sans-serif;
    color: #006390;
    font-size: 18px;
   margin-bottom: 10px;
    margin-right: 20px;
    display: inline-block;
}



.panel-conditions .list-specials{
    margin-bottom: 0;
    margin-top: 9px;
}

.panel-conditions .list-specials>li{
    font-family: SoHoPro,sans-serif;
    color: #006390;

    padding-right: 10px;
    padding-left: 0;
}

.panel-conditions .list-specials>li>.icon{
    font-size: 11px;
    margin-right: 6px;
    position: relative;
    top: -2px;
}

.panel-conditions .panel-conditions__note{
    border-top: 1px solid #ced2d9;
    padding: 10px 0;
    margin: 0 -15px;
}

.panel-conditions .panel-conditions__note .note-text{
    padding-left: 20px;
}

@media screen and (min-width: 992px){
    .panel-conditions .text-open-days{
        top: -15px;
    }

}

@media screen and (min-width: 768px){

    .conditions-item .contact{
        margin-left: -10px;
    }

    .panel-conditions .text-open-days{
        position: relative;
        top: -9px;
        margin-bottom: 0;
    }

    .panel-conditions .list-specials>li {
        padding-right: 25px;
        padding-left: 25px;
    }
}

@media screen and (max-width: 767px){
    /*.panel-conditions .same-height-xs .conditions-item{*/
        /*height: 180px;*/
    /*}*/

    .panel-conditions .same-height-xs>.col{
        margin-bottom: 15px;
    }


    .panel-conditions .same-height-xs.mb30{
        margin-bottom: 0;
    }

    .panel-conditions .same-height-xs .conditions-item.condition-item--webcam{
        height: auto;
    }


    .row--same-height-bulletin .row--same-height__item {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;
    }
    /*
     * the .row--same-height selector has to be after
     * .row--same-height__item to increase the specificity
     * in case of chaining the two selectors
     * (which is needed for nested rows)
     */
    .row--same-height-bulletin{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .row--same-height-bulletin:before,
    .row--same-height-bulletin:after {
        display: none;
    }
    .row--same-height-bulletin > .col{
        display: flex;
        flex-direction: column;
        height: auto;
    }
}