.tour-teaser{
    position: relative;
    color: #fff;
    cursor: pointer;
}

.tour-teaser:after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    transition: all 150ms ease-in-out;
    opacity: 1;
}

.tour-teaser:hover:after{
    opacity: 0.8;
}

.tour-teaser__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 10px 5px;
    z-index: 1;
}


.tour-teaser__badge-list{
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
    margin-left: 0;
    display: flex;
    gap: calc(5rem/17);
}

li.tour-teaser__badge{
    font-size: 14px;
    padding: 3px 8px;
    background-color: #fff;
    color: var(--color-text-default);
    display: inline-flex;
    align-items: center;
}

.is-moderate .tour-teaser__badge-status{
    background-color: #CC0200!important;
}

.is-easy .tour-teaser__badge-status{
    background-color: #0066cc!important;
    color: #fff!important;
}

.is-difficult .tour-teaser__badge-status{
    background-color: #272727!important;
    color: #fff!important;
}

.tour-teaser__badge-status{
    width: 14px;
    height: 14px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 6px;
    margin-right: 6px;
}

.tour-teaser__badge-status.is-open{
    background-color: #82B81A;
    color: #fff;
}

.tour-teaser__badge-status.is-closed{
    background-color: #CC0200;
    color: #fff;
}

.tour-teaser__title{
    font-size: 17px;
    font-family: var(--font-bold);
    line-height: calc(20/17);
    margin-bottom: 5px;
}

.tour-teaser__pretitle{
    text-transform: uppercase;
    font-size: 14px;
}

.tour-teaser__bottom{
    display: flex;
    justify-content: space-between;
}

.tour-teaser__infos{
    margin-bottom: 0;
}

.tour-teaser__info-item{
    font-size: 14px;
}

.tour-teaser__info-item + .tour-teaser__info-item{
    margin-left: 10px;
}

.tour-teaser__info-item .icon-arrow-left-right{
    font-size: 7px;
    vertical-align: 1px;
    margin-right: 2px;
}

.tour-teaser__info-item .icon-clock-thin{
    font-size: 14px;
    margin-right: 3px;
    vertical-align: -2px;
}

.tour-teaser__link-arrow{
    font-size: 27px;
}

/*list teaser tour*/

.list-teaser--tour__title{
    font-size: 30px;
}

.list-teaser--tour .tour-teaser__infos{
    font-family: var(--font-bold);
    color: var(--color-primary-light);
}

.list-teaser--tour .tour-teaser__badge-list{
    position: relative;
    left: auto;
    top: auto;
}

.list-teaser--tour li.tour-teaser__badge{
    padding: 0 5px;
    background-color: var(--color-grey);
}