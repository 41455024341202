.form-loading{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%,-50%);
    background-color: rgba(255,255,255,0.3);
    z-index: 1;
    display: flex;
}
.form-loading[hidden]{
    display: none;
}
.form-loading .loading-spinner{
    margin: auto auto;
}