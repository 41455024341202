footer.main-footer {
    /*background: #003148;*/
    /*background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMwMDMxNDgiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAyMzMyIiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L3JhZGlhbEdyYWRpZW50PgogIDxyZWN0IHg9Ii01MCIgeT0iLTUwIiB3aWR0aD0iMTAxIiBoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);*/
    /*background: -moz-radial-gradient(center, ellipse cover,  #003148 0%, #002332 100%);*/
    /*background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,#003148), color-stop(100%,#002332));*/
    /*background: -webkit-radial-gradient(center, ellipse cover,  #003148 0%,#002332 100%);*/
    /*background: -o-radial-gradient(center, ellipse cover,  #003148 0%,#002332 100%);*/
    /*background: -ms-radial-gradient(center, ellipse cover,  #003148 0%,#002332 100%);*/
    /*background: radial-gradient(ellipse at center,  #003148 0%,#002332 100%);*/
    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003148', endColorstr='#002332',GradientType=1 );*/
    color: #ffffff;

}
footer.main-footer:not(.demi-main-footer)>div:nth-of-type(1) {
    background: var(--color-secondary-dark);
    padding: 30px 0;
    @media screen and (min-width: 768px){
        padding: 60px 0;
    }
}
footer.main-footer>div:nth-of-type(2) {
    background: var(--color-secondary);
    @media screen and (max-width: 768px){
        padding-bottom: 20px;
    }
}
footer.main-footer h3 {
    font-size: 16px;
    margin-bottom: 10px;
}
footer.main-footer ul>li {
    line-height: 35px;
}
footer.main-footer ul>li>a {
    position: relative;
}
footer.main-footer ul>li>a:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #5999b7;
    transform: scale(0);
    transition: 200ms ease-in-out;
}
footer.main-footer ul>li>a:hover:after {
    transform: scale(1);
}
.main-footer .creditlink {
    margin-right: 3px;
}

footer.main-footer.demi-main-footer {
    background-color: var(--color-secondary);
}

footer.main-footer.demi-main-footer .container {
    padding: 30px 20px;
}

.social-icons__img-tiktok{
    position: relative;
    display: inline-block;
    background: url('/static/img/social/tiktok.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 26px;
    height: 29px;
}

@media screen and (max-width: 767px){
   .social-icons__img-tiktok{
       top: 6px;
       height: 24px;
   }

}

@media screen and (min-width: 768px){
    footer.main-footer .social-icons__item {
        color: #fff;
    }

    footer.main-footer .social-icons__item--facebook {
        background: #3b5998;
    }
    footer.main-footer .social-icons__item--twitter {
        background: #000000;
    }
    footer.main-footer .social-icons__item--googleplus {
        background: #dd4b39;
    }
    footer.main-footer .social-icons__item--youtube {
        background: #cd201f;
    }
    footer.main-footer .social-icons__item--instagram {
        background:
        radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
        radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
        radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
        radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
        radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
        linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
    }

    footer.main-footer .sitebyelements {
        text-align: right;
    }
    footer.main-footer .footer__text {
        text-align: left;
    }
    footer.main-footer .footer__legal {
        display: flex;
        gap: calc(32rem / 16);
        align-items: center;
    }
    footer.main-footer .footer__legal .footer__legal-links {
        display: flex;
        gap: calc(32rem / 16);
        justify-content: center;
        width: 100%;
    }

    footer.main-footer .btn-newsletter {
        margin-top: 40px;
    }
}

@media screen and (max-width: 767px){
    .row-flex-xs{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0;
    }


    .col-xs.col{
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        padding: 0;
    }

    .main-footer .col-xs.col:not(:last-child) {
        margin-right: 10px;
    }

    footer.main-footer .footer__legal {
        display: flex;
        flex-direction: column;
        row-gap: calc(24rem / 16);
    }
    footer.main-footer .footer__legal .footer__legal-links {
        display: flex;
        gap: calc(8rem / 16) calc(24rem / 16);
        flex-wrap: wrap;
    }

}
