.navbar-main {
    background: #fff;
    margin: 0;
    border-radius: 0;
    text-transform: uppercase;
    border: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    box-shadow: 0 1px 4px rgba(0,0,0,.1);
    padding: 0;
}
.navbar-search .form-control {
    background: none;
    box-shadow: none;
    border: 0;
    border-radius: 0;
    width: 100px;
    height: 32px;
    display: inline-block;
    font-size: 15px;
}
.navbar-nav>li>a {
    color: #006390;
    font-size: 22px;
    font-family: 'RobotoBold', sans-serif;
    /*font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;*/
    font-weight: 400;
    cursor: pointer;
}
.navbar-nav>li>a:hover, .navbar-nav>li>a:focus {
    background: none;
    outline: none;
}
.navbar-main .navbar-search,
.navbar-main .navbar-search .form-control::-webkit-input-placeholder {
    color: #a5a8ae;
    font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
}
.navbar-main .lang-dropdown .btn, .navbar-main .lang-dropdown .btn:hover,
.navbar-main .lang-dropdown .btn:focus, .mobile-nav .lang-dropdown .btn:focus,
.mobile-nav .lang-dropdown .btn:hover, .mobile-nav .lang-dropdown .btn{
    /*color: #a5a8ae;*/
    font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
}
.navbar-main .lang-dropdown .dropdown-menu {
    text-transform: none;
}
.portal-top .navbar-search .form-control::-webkit-input-placeholder {
    font-family: 'RobotoBold' , 'RobotoBold-latin-ext', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
}
.lang-dropdown {
    margin: 11px 8px;
}
.lang-dropdown .btn {
    background: none;
    border: 0;
    padding: 4px 6px;
    font-size:18px;
    font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    font-weight: 400;
    color: var(--color-grey-dark);
}
.lang-dropdown .caret {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: var(--color-grey-dark) transparent transparent transparent;
    color: var(--color-grey-dark);
}
.portal-top .lang-dropdown .caret {
    border-color: #fff transparent transparent transparent;
}
.nav-teaser .nav-teaser__text{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 45px 20px 20px;
    line-height:1.1;
    white-space:normal;
    /*background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);*/
    background: linear-gradient(to bottom,transparent 0,rgba(0,0,0,.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000',endColorstr='#73000000',GradientType=0);
    z-index: 1;
    font-family: var(--font-default);
}
@media screen and (max-width: 767px) {
    .nav-teaser .nav-teaser__text{
        padding: 0 45px 10px 10px;
    }
}
.nav-teaser .nav-teaser__text .icon-circle-arrow{
    position: absolute;
    right:15px;
    bottom:15px;
}
@media screen and (min-width: 768px){
    .navbar-header__right{
        display: none;
        visibility: hidden;
    }
    .nav-wrapper {
        position: relative;
        height: 100px;
    }
    .navbar-nav {
        text-align: center;
        width: 100%;
        font-size: 0;
    }
    .navbar-main {
        z-index: 1006;
        max-height: 100px;
    }
    .navbar-collapse.navbar-collapse {
        padding-top: 21px;
        width: 690px;
        margin-left: auto;
        margin-right: auto;
    }
    .nav-right {
        padding-top: 22px;
        display:flex;
        align-items: baseline;
        flex-wrap: wrap;
        align-content: center;
        flex-direction: row;
        position: absolute;
        right: 0;
        top: 0;
    }
    .navbar-header {
        max-width: 152px;
    }
    .navbar-header img {
        max-width: 100%;
    }
    .navbar-brand {
        height: 100px;
        padding: 20px 15px 15px 22px;
    }

    .portal-top .navbar-brand {
        padding: 16px 15px 15px 26px;
    }
    .portal-top>.pull-right {
        margin-top: 25px;
    }
    .navbar-nav>li{
        /*padding: 0 8px;*/
        padding: 0;
        padding-bottom: 29px;
        display: inline-block;
        float: none;
    }
    .navbar-nav>li>a {
        font-size: 17px;
        padding: 20px 8px 10px;
    }
    .navbar-nav>li:first-child a {
        padding-left: 0;
    }
    .navbar-nav>li:last-child a {
        padding-right: 0;
    }
    .navbar-nav>li>a:hover, .navbar-nav>li>a:focus, .navbar-nav>li:hover>a, .navbar-nav>li.active>a,
    .navbar-main .lang-dropdown .btn:hover, .navbar-main .lang-dropdown .btn:focus, .navbar-main .lang-dropdown .btn:focus-visible,
    .navbar-main .open-search:hover, .navbar-main .open-search:focus, .navbar-main .open-search:focus-visible,
    .navbar-main .icon-account:hover, .navbar-main .icon-account:focus, .navbar-main .icon-account:focus-visible {
        color: #83b81a;
        outline: none;
    }
    .navbar-main .open-search {
        color: var(--color-grey-dark);
        border: none;
        background-color: unset;
    }
    .navbar-main .search:after {
        background-color: var(--color-grey-dark);
    }

    .navbar-main .account {
        color: var(--color-grey-dark);
    }
    .navbar-main .account:after {
        background-color: var(--color-grey-dark);
    }

    .navbar-main .navbar-nav>li>a{
        display: inline-block;
    }
    .account {
        font-size: 18px;
        margin-right: 22px;
        position: relative;
    }

    .account .icon {
        top: 5px;
        margin-right: 0;
        position: relative;
    }
    .lang-dropdown .btn {
        font-size:15px;
        padding: 5px 6px;
    }
    .lang-dropdown .btn:hover, .lang-dropdown .btn:focus {
        color: #fff;
    }
    .lang-dropdown {
        margin: 8px 28px 8px 8px;
    }
    .nav-teaser {
        position: relative;
        text-align: left;
    }
    .nav-teaser.embed-responsive:after {
        content: '';
        top: 0;
        bottom:0;
        right:0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        background: #00a4d0;
       /* background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYTRkMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjYmQzMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);*/
        background: -moz-linear-gradient(left,  #00a4d0 0%, #cbd300 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%,#00a4d0), color-stop(100%,#cbd300));
        background: -webkit-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
        background: -o-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
        background: -ms-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
        background: linear-gradient(to right,  #00a4d0 0%,#cbd300 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a4d0', endColorstr='#cbd300',GradientType=1 );
        opacity: 0;
        transition: 250ms ease-in-out;
    }
    .nav-teaser.embed-responsive:hover:after {
        opacity: 0.4;
    }
    .nav-teaser--small.nav-teaser--small ul>li {
        margin-bottom: 0;
    }
    .nav-teaser--small .nav-teaser {
        margin-bottom: 5px;
        transform: translateZ(0);
    }
    .nav-teaser--small:hover ul>li{
        color: #00a4d0;
    }
}
@media screen and (min-width: 992px){
    .navbar-nav>li{
        /*padding: 0 8px 29px;*/
        padding: 0 0 29px;
    }
    .navbar-header {
        max-width: 160px;
    }
}
@media screen and (min-width: 1200px){
    .navbar-nav>li {
        /*padding: 0 15px 29px;*/
        padding: 0 0 29px;
    }
    .navbar-nav>li>a {
        font-size: 18px;
        padding: 20px 12px 10px;
    }
    .navbar-nav>li:first-child a {
        padding-left: 0;
    }
    .navbar-nav>li:last-child a {
        padding-right: 0;
    }
    .navbar-collapse.navbar-collapse {
        width: 790px;
    }
    .navbar-header.navbar-header {
        max-width: 200px;
    }
    .lang-dropdown .btn {
        font-size:18px;
    }
}
@media screen and (min-width: 1260px){
    .navbar-nav>li>a {
        font-size: 22px;
    }
    .navbar-collapse.navbar-collapse {
        width: 870px;
    }
}

