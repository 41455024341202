.modal--backdrop:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 164, 208, 0.7);
    z-index: -1;
}
.modal__close {
    background: transparent;
    border: 0;
    box-shadow: none;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    padding: 15px;
}
.modal__close:hover {
    color: #00a4d0;
}

.info-modal .modal-header {
    border-bottom: 0;
    padding-bottom: 5px;
}
.info-modal .modal-content {
    border-radius: 0;
    border: none;
}
.info-modal__booking {
    padding: 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
@media screen and (min-width: 768px) {
    .info-modal__booking{
        padding: 40px 0;
    }
}
.info-modal__booking__logo {
    width: 150px;
    height: auto;
    display: inline-block;
}
.info-modal__booking__divider {
    font-size: 20px;
    white-space: nowrap;
    letter-spacing: -2px;
    color: #ebebeb;
    position: relative;
    top: 5px;
    padding: 0 20px;
}
@media screen and (min-width: 768px) {
    .info-modal__booking__divider{
        font-size: 30px;
        padding: 0 30px;
    }
}
.info-modal .btn.info-modal__booking__submit {
    display: inline-block;
    width: auto;
}

.info-modal__loading {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 5px;
    background: rgb(203,211,0);
    background: -moz-linear-gradient(left, rgba(203,211,0,1) 0%, rgba(0,164,208,1) 100%);
    background: -webkit-linear-gradient(left, rgba(203,211,0,1) 0%,rgba(0,164,208,1) 100%);
    background: linear-gradient(to right, rgba(203,211,0,1) 0%,rgba(0,164,208,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cbd300', endColorstr='#00a4d0',GradientType=1 );
    transform: scaleX(0) translateZ(0);
    transform-origin: left;
    transition: transform 3300ms linear;
}
.is-active.info-modal .info-modal__loading {
    transform: scaleX(1) translateZ(0);
}