.test{
    color: lime;
}


a {
    color: inherit;
    cursor: pointer;
}
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
address {
    margin-bottom: 0;
}
textarea {
    resize: vertical;
}
label {
    font-weight: inherit;
}

::-moz-selection {
    background: #00a4d0;
    color: #fff;
}

::selection {
    background: #00a4d0;
    color: #fff;
}

.bg-primary ::selection {
    background: #fff;
    color: #00a4d0;
}

.italian-share{
    font-size: 39px;
    position: absolute;
    top: -14px;
    right: -46px;
}

#mainLogo{
    background-size: 182px 60px;
}

.show-in-renderlet{
    display: none;
}