.weather-icon {
    height: 97px;
    max-width: 100%;
    display: block;
    background-position: center;
}
.webcams-row .weather-icon {
    height: 47px;
}
.sidebar .weather-icon {
    width: 70px;
}
.weather-max, .weather-min {
    font-family: 'SohoPro', sans-serif;
    font-weight: 400;
}
.weather-max {
    font-size: 40px;
    line-height: 30px;
    margin-bottom: 5px;
}
.weather-info-right {
    font-family: 'SohoPro', sans-serif;
    font-weight: 400;
    border-left: 1px solid #0083a6;
}
.sidebar .weather-max {
    font-size: 25px;
    line-height: inherit;
    margin-bottom: -5px;
}
.sidebar .weather-min {
    font-size: 16px;
}
.weather-info-right .icon {
    margin-right: 5px;
    width: 16px;
}
.weather-info-text {
    font-size: 16px;
    margin-top: 40px;
}
@media screen and (min-width: 768px){
    .weather-max {
        font-size: 55px;
        line-height: 54px;
        margin-bottom: 0;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px){
    .sidebar .weather-day {
        height: 50px;
    }
    .sidebar .weather-icon {
        height: 50px;
    }
}