.filter-divider.filter-divider {
    margin: 0 0 15px 0;
}
.extended-filter {
    position: relative;
    z-index: 1;
    float: left;
}
.expand-container .expand-search {
    display: none;
}
.expand-container.is-expanded .expand-search {
    display: block;
}
.expand-container.is-expanded{
    transform: translateX(0);
}
.expand-search {
    position: relative;
    overflow: hidden;
}
.expandable-search-field{
    background: transparent;
    position: relative;
    display: inline-block;
    padding-right: 43px;
}
.expand-container.is-expanded + .expand-search__btn {
    background-color: transparent;
    color: #00A4D0;
    position: absolute;
    right: 0;
    top: 0;
}
.expand-search__btn:focus,
.expand-search__btn:hover,
.expand-search__btn:focus-visible {
    background-color: #00A4D0;
    outline: none;
}
.filter-collapse {
    padding: 37px 15px 75px;
    position: relative;
}
.filter-collapse-close {
    position: absolute;
    right: 20px;
    top: 20px;
}
.filter-collapse-clear {
    color: #005268;
    position: absolute;
    right: 30px;
    bottom: 30px;
    cursor: pointer;
    padding: 0;
    transition: color 0.2s ease-in-out;
}
.filter-collapse .filter-collapse__label {
    color: #005268;
}
#collapsable-filter .panel {
    margin-bottom: 0;
    border: 0;
}
.filter-collapse__btn {
    position: relative;
}
.filter-collapse__btn__state.icon {
    font-size: 10px;
    margin-left: 5px;
    margin-right: -10px;
    display: none;
    width: 15px;
    pointer-events: none;
}
.filter-collapse__btn__state.icon:before{
    content: "\E040";
}
input[type="checkbox"]:checked ~ .filter-collapse__btn__state {
    display: inline-block;
}

@media screen and (min-width: 768px){
    input[type="checkbox"]:checked:hover ~ .filter-collapse__btn__state:before {
        content: "\E042";
    }
}



/*Dropdown*/
.sortfilter {
    margin-right: 1px;
}
.open>.sortfilter {
    background: #fff;
    color: #00a4d0;
    border: 1px solid #ced2d9;
    border-bottom: 0;
    z-index: 1001;
}
.sortfilter:focus, .open>.sortfilter:focus:hover {
    outline: none;
    color: #86898d;
}
.sortfilter:focus:hover {
    color: #fff;
}
.sortfilter-menu.sortfilter-menu {
    box-shadow: none;
    border: 1px solid #ccc;
    margin: 0;
    width: 240px;
    left: 0px;
    padding: 5px 20px;
    margin-top: -1px;
    font-size: 16px;
    background-color: #fff;
}
.sortfilter-menu li {
    padding: 8px 9px;
    border-bottom: 1px solid #ced2d9;
    position: relative;
}
.sortfilter-menu li:last-child {
    border-bottom: 0;
}
.sortfilter-menu label {
    cursor: pointer;
    margin-bottom: 0;
}
.sortfilter-menu .form-control[type=radio] {
    width: 0;
    height: 0;
    display: none;
}
.sortfilter-menu .form-control[type=radio]:checked ~ label {
    color: #00a4d0;
}
.sortfilter-menu .form-control[type=radio]:focus {
    outline: none;
}
.sortfilter-menu .form-control-radio {
    background: #d8dbe1;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    float: right;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    right: 10px;
}
.sortfilter-menu .form-control[type=radio]:checked ~ .form-control-radio {
    background: #00a4d0;
}
/*TODO: animate*/
.open .sortfilter .icon:before {
    content: "\E042";
}
.sortfilter-menu .form-control[type=radio] {
    position: absolute;
    top: 50%;
    margin-top: -4px;
    right: 10px;
}
@media screen and (min-width: 768px){
    .sortfilter-menu.sortfilter-menu {
        left: -190px;
    }
    .extended-filter {
        float: none;
    }
    .expand-container.is-expanded + .expand-search__btn {
        position: relative;
    }
    .expand-container.is-expanded{
        transform: translateX(50px);
    }
    .expandable-search-field{
        padding-right: 60px;
    }
    .filter-collapse {
        padding: 37px 40px 15px 40px;
    }


}


.events-new-filter .filter-collapse {
    padding: 37px 40px 68px 15px;
}

.events-new-filter .filter-collapse-clear {
    right: 112px;
    bottom: 17px;
    color: #fff;
    background: #006b87;
}
.events-new-filter .filter-collapse-clear:hover{
    background: #00405e;
}
.events-new-filter .filter-collapse-clear.clear-cat-selection{
    background: transparent;
}

.events-new-filter .filter-collapse-clear .icon{
    font-size: 13px;
    margin-right: 4px;
}

.events-new-filter .start-filter-bt{
    position: absolute;
    right: 30px;
}

@media screen and (min-width: 768px) {
.events-new-filter .info-txt{
    position: absolute;
    bottom: 14px;
    left: 17px;
}
}

.events-new-filter .info-txt p{
    color: #005268;
}

.fixedbar > ul {
    list-style: none;
    display: block;
    margin: 0;
    padding: 0;
}

.fixedbar > ul > li {
    display: inline-block;
}


@media screen and (max-width: 767px){


    .events-new-filter .start-filter-bt {
        position: relative;
        right: auto;
        width: 100%;
    }

    .events-new-filter .filter-collapse-clear {
        position: relative;
        left: 0;
        bottom: 0;
        margin-bottom: 10px;
        width: 100%;
    }


    .fixedbar {
        height: 40px;
        position: relative;
        top: 0;
        left: 0;
        z-index: 8;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .fixedbar > ul {
        position: absolute;
        top: auto;
        left: auto;
        white-space: nowrap;
        margin: 0;
        padding: 0;
    }

    .fixedbar > ul > li {
        display: inline-block;
        width: auto;
        height: 40px;
    }

    .panel--fullscreen-xs .filter-collapse {
        position: fixed;
        top: 0;
        bottom: 70px;
        left: 0;
        right: 0;
        z-index: 1000;
        background-color: #00a4d0;
        padding: 55px 20px 20px;
        overflow-y: auto;
        transform: translateX(100%);
        transition: transform 250ms ease-in-out;
    }
    .panel--fullscreen-xs .show .filter-collapse {
        transform: translateX(0);
    }

}