.img-slide {
    position: relative
}
.img-slide--overlay:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .5;
    background: linear-gradient(270deg, rgba(0,0,0,0) 0%, #000000 100%);
    pointer-events: none;
}
.img-slide__content {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    z-index: 1;
}
@media screen and (min-width: 768px) {
    .img-slide__content {
        bottom: auto;
        top: 50%;
        transform: translateY(-50%);
    }
}