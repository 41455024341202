@media screen and (max-width: 767px) {
    .navbar-main {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
    }
    .navbar-nav {
        width: 100%;
        margin: 0;
    }
    .navbar-main .navbar-header {
        height: 60px;
        display: flex;
        align-items: center;
    }
    .navbar-header__right{
        margin-left: auto;
        display: flex;
    }
    .page-wrapper {
        padding-top: 52px;
    }
    .navbar-brand {
        padding: 0px;
        height: fit-content;
        margin-left: calc(17rem/17);
        position: absolute;
    }
    .navbar-main .navbar-header>.navbar-brand>img,
    .mobile-nav > .mobile-nav__main > .navbar-brand > img {
        max-width: 117px;
    }
    .mobile-nav > .mobile-nav__main > .navbar-brand > img {
        margin-top: calc(10rem/17);
    }
    .navbar-form {
        margin: 0;
        padding-left: 0;
        height: 52px;
    }

    /*Toggle Button*/
    .navbar-toggle,
    .navbar-main .navbar-toggle {
        background-color: var(--color-secondary);
        color: #FFFFFF;
        border-radius: 0;
        padding: 9px 0 0 0;
    }
    .navbar-main .navbar-toggle .icon-bar {
        display: block;
        width: 20px;
        background: #FFFFFF;
        height: 2px;
        border-radius: 0;
    }
    .navbar-toggle .icon-bar+.icon-bar {
        margin-top: 4px;
    }
    .navbar__button{
        height: 60px;
        width: 60px;
        gap: 10px;
        font-size: calc(10rem/17);
        line-height: calc(11/10);
        text-transform: uppercase;
        font-family: var(--font-bold);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: none;
        background: #FFFFFF;
        /*color: #A5A8AE;*/
        padding-top: calc(9rem/17);
    }
    .navbar__button.navbar-toggle:focus-visible {
        background: var(--color-primary);
    }
    .open-search, .open-search .icon-search {
        color: var(--color-grey-dark);
    }
    .open-search:focus-visible, .open-search:focus-visible .icon-search{
        color: var(--color-green-dark);
    }
    /*.navbar__button:not(.navbar-toggle){*/
    /*    border-left: solid 1px RGBA(159,162,168,0.50);*/
    /*    border-top: solid 1px RGBA(159,162,168,0.50);*/
    /*}*/
    .navbar__button-icon{
        font-size: calc(20rem/17);
        color: #A5A8AE
    }
    .navbar__button.navbar__button--primary{
        color: #FFFFFF;
        background: var(--color-primary);
    }
    .navbar__button.navbar__button--primary .navbar__button-icon{
        color: #FFFFFF;
    }

    .mobile-nav {
        top: 0;
        left: 0;
        width: 100%;
        text-transform: uppercase;
        bottom: 0;
        position: fixed;
        z-index: 10000;
        transform: translateX(-140%);
        transition: transform 200ms ease-in-out;
    }
    .mobile-nav__main {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: visible;
        width: 100vw;
        background: #fff;
        z-index: 1;
    }
    .console-is-open .mobile-nav__main {
        overflow-x: visible;
    }
    .mobile-nav__content.mobile-nav-container{
        padding: 125px 40px 40px 40px;
    }
    .mobile-nav__console + .mobile-nav__content.mobile-nav-container{
        padding: 50px 40px 40px 40px;
    }
    .mobile-nav-langswitch{
        position: absolute;
        top: 3px;
        right: 70px;
    }
    /*mobile-nav-langswitch .btn.dropdown-toggle {*/
    /*    color: var(--color-grey-dark);*/
    /*}*/
    mobile-nav-langswitch:focus-visible .btn.dropdown-toggle {
        color: var(--color-green-dark);
    }
    .mobile-nav.is-open {
        transform: translateX(0);
    }
    .mobile-nav__close {
        width: 60px;
        height: 60px;
        position: absolute;
        right: 0;
        top: 0;
        background: var(--color-secondary);
        color: #fff;
        font-size: 16px;
        text-align: center;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
    }
    .mobile-nav__close:focus-visible {
        background: var(--color-primary);
        outline: none;
    }
    .navbar-brand--mobile{
        position: relative;
        margin: auto;
    }

    .mobile-nav-container {
        padding: 0 15px;
    }
    .navbar-nav>li>a:focus-visible {
        color: var(--color-green-dark);
    }
    .navbar-nav>li>a {
        position: relative;
        font-size: 20px;
        display: flex;
        align-items: center;
        gap: 13px;
        padding: 0;
    }
    .navbar-nav>li+li{
        margin-top: 54px;
    }
    .navbar-nav>li.is-open>a {
        color: #00a4d0;
    }
    .subnav-toggle {
        font-size: 15px;
        transition: 200ms ease-in-out;
        color: var(--color-green-dark);
    }
    .navbar-nav>li ul>li ul:not(.main-nav__collapse-list) {
        display: none;
    }
    .main-nav__dropdown-collapse{
        padding: 12px 0;
    }
    .main-nav__dropdown ul ul.main-nav__collapse-list a{
        padding: 9px 20px;
    }
    .main-nav__dropdown{
        display: block;
        pointer-events: none;
        opacity: 0;
        position: fixed;
        top: 60px;
        left: 0;
        width: 100vw;
        transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
        transform: translateX(100vw);
        background: #FFFFFF;
        padding: 0;
    }
    .main-nav__dropdown-close{
        width: 100vw;
        border: none;
        background-color: #0082A4;
        padding: 16px 50px;
        color: #FFFFFF;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
        line-height: 1;
        font-family: var(--font-bold);
    }
    .main-nav__dropdown-close .icon{
        font-size: calc(10rem/17);
    }
    .main-nav__dropdown-title{
        margin: 30px 0;
        font-size: calc(20rem/17);
        line-height: calc(24/20);
        color: #026390;
        text-transform: uppercase;
        font-family: var(--font-bold);
        position: relative;
        padding-bottom: 17px;
        width: fit-content;
    }
    .main-nav__dropdown-title:before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--color-green-dark);
    }
    .navbar-nav>li.is-open>.main-nav__dropdown, .navbar-nav>li ul>li.is-open ul {
        display: block;
        opacity: 1;
        /*width: 255px;*/
        transform: translateX(0);
        pointer-events: auto;
    }
    .main-nav__dropdown .container {
        padding: 0 50px;
        position: relative;
        min-height: 72vh;
        max-height: calc(100vh - 110px);
        /*fix for wrong viewport height in mobile*/
        /*max-height: calc(100svh - 110px);*/
        overflow-y: auto;
    }
    .main-nav__dropdown .container:before{
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: #FFFFFF;
    }
    .main-nav__dropdown.main-nav__dropdown-minheight .container:before{
        height: 220px;
    }
    .main-nav__dropdown>ul {
        padding-left: 23px;
    }
    .main-nav__dropdown li a{
        padding: 12px 10px 12px 0;
        display: inline-block;
        width: 100%;
    }
    .main-nav__dropdown li a:focus-visible {
        color: var(--color-green-dark);
    }
    .main-nav__dropdown__subnav>a {
        position: relative;
    }
    .main-nav__dropdown__subnav>a .subnav-toggle {
        font-size: 19px;
        right: 21px;
        margin-top: -9.5px;
    }
    .navbar-header .btn-booking {
        position: absolute;
        right: 15px;
        top: 6px;
        width: 40px;
        height: 40px;
        padding: 12px 0;
        border-radius: 100%;
    }
    .mobile-nav-container .search {
        top: 15px;
        color: #a5a8ae;
    }
}

/*console*/
.mobile-nav__console{
    padding: 80px 20px 0 20px;
}
.mobile-nav__console-item{
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    color: var(--color-secondary);
}
.mobile-nav__console-item-text{
    font-size: 10px;
    line-height: calc(11/10);
    text-align: center;
}
.mobile-nav__console-item-icon{
    font-size: 26px
}
.mobile-nav__console-list{
    display: flex;
    justify-content: space-between;
}