.img-grid-slider {
    margin: 35px -5px;
    position: relative;
}
@media screen and (min-width: 768px) {
    .img-grid-slider {
        margin: 35px -10px;
    }
}
.img-grid-slider__item {
    padding: 0 5px;
}
.swiper-container:not(.is-init) .img-grid-slider__item{
    max-width: 173px
}
@media screen and (min-width: 768px) {
    .img-grid-slider__item {
        padding: 0 10px;
    }
    .swiper-container:not(.is-init) .img-grid-slider__item{
        max-width: calc(303rem/17);
    }
}
.img-grid-slider .slider-arrow.slider-arrow,
.text-with-media__media-container .slider-arrow.slider-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
    color: #fff;
    font-size: 40px;
    z-index: 1;
    cursor: pointer;
    margin-top: 0;
}
.img-grid-slider .slider-arrow.slick-next,
.text-with-media__media-container .slider-arrow.slick-next {
    left: auto;
    right: 25px;
}
.img-grid-slider .slider-arrow.slick-prev:before,
.text-with-media__media-container .slider-arrow.slick-prev:before {
    transform: rotate(180deg);
    display: block;
}

.img-grid-slider .swiper-button--prev{
    margin-left: 8px;
}

.img-grid-slider .swiper-button--next{
    margin-right: 8px;
}