/*.portal-slider, .portal-top {*/
    /*max-width: 1920px;*/
    /*margin-left: auto;*/
    /*margin-right: auto;*/
/*}*/
.portal-slider.slick-initialized .portal-slider__item + .portal-slider__item {
    display: block;
}
.portal-slider__item {
    position: relative;
}
.portal-slider__claim {
    color: #fff;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 0 15px;
}
.portal-slider h1 {
    font-size: 50px;
    color: #fff;
    position: relative;
    z-index: 1;
}
.portal-slider__claim h2 {
    position: relative;
    z-index: 1;
}
.portal-slider__claim:after, .portal-slider__location:after {
    content: '';
    position: absolute;
    left: 30%;
    top: -15%;
    bottom: 15%;
    right: 30%;
    opacity: 0.25;
    border-radius: 100%;
    z-index: 0;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPHJhZGlhbEdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY3g9IjUwJSIgY3k9IjUwJSIgcj0iNzUlIj4KICAgIDxzdG9wIG9mZnNldD0iMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI3MSUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjAiLz4KICA8L3JhZGlhbEdyYWRpZW50PgogIDxyZWN0IHg9Ii01MCIgeT0iLTUwIiB3aWR0aD0iMTAxIiBoZWlnaHQ9IjEwMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-radial-gradient(center, ellipse cover,  rgba(0,0,0,1) 0%, rgba(0,0,0,0) 71%, rgba(0,0,0,0) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(0,0,0,1)), color-stop(71%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0)));
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 71%,rgba(0,0,0,0) 100%);
    background: -o-radial-gradient(center, ellipse cover,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 71%,rgba(0,0,0,0) 100%);
    background: -ms-radial-gradient(center, ellipse cover,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 71%,rgba(0,0,0,0) 100%);
    background: radial-gradient(ellipse at center,  rgba(0,0,0,1) 0%,rgba(0,0,0,0) 71%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 );
}
.portal-slider__claim:after {
    opacity: 0.4;
    left: 20%;
    top: -40px;
    bottom: -40px;
    right: 20%;
}
.portal-slider .slick-next:before, .portal-slider .slick-prev:before {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 768px){
    .portal-slider h1 {
        font-size: 70px;
        margin-left: auto;
        margin-right: auto;
        max-width: 55%;
    }
    .portal-slider h2 {
        font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
        font-weight: 400;
        font-size: 24px;
        margin-bottom: 15px;
    }
    .portal-slider__btn {
        border-radius: 100%;
        width: 70px;
        height: 70px;
        background: #83b81a;
        box-shadow: 0 0 0 5px #fff;
        position: relative;
        margin-top: 10px;
        cursor: pointer;
        z-index: 1;
    }
    .portal-slider__btn .icon {
        font-size: 39px;
        position: relative;
        top: 50%;
        margin-top: -15px;
    }
    .portal-top {
        position: absolute;
        z-index: 100;
        left: 0;
        top: 0;
        width: 100%;
    }
    .portal-slider__location {
        position: absolute;
        bottom: 30px;
        right: 34px;
    }
    .portal-slider__location:after {
        left: -20px;
        right: -20px;
        top: -10px;
        bottom: -10px;
        opacity: 0.15;
    }
    .portal-slider__location a {
        position: relative;
        z-index: 1;
    }
    .portal-slider .slick-next, .portal-slider .slick-prev {
        font-size: 70px;
        margin-top: -35px;
    }
    .portal-slider .slick-prev {
        left: 35px;
    }
    .portal-slider .slick-next {
        right: 35px;
    }
    .slick-dots {
        display: none !important;
    }
}
@media screen and (min-width: 1200px){
    .portal-slider h1 {
        font-size: 95px;
        line-height: 95px;
        max-width: 55%;
    }
    .portal-slider h2 {
        margin-bottom: 20px;
    }
    .portal-slider__btn {
        margin-top: 30px;
    }
}
@media screen and (min-width: 1300px){
    .portal-slider h1 {
        font-size: 120px;
        line-height: 120px;
    }
}