.console-sticky{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #FFFFFF;
    color: var(--color-secondary);
    padding: 10px;
    z-index: 9999;
    box-shadow: 1px 3px 12px rgba(0,0,0,.5);
}
.console-sticky ul li{
    padding: 0 10px;
    display: flex;
    align-items: center;
}
.console-sticky__item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
}
.console-sticky__item-icon{
    font-size: 26px;
    line-height: 1;
}
.console-sticky__item-text{
    font-size: 10px;
    line-height: calc(12/10);
    text-transform: uppercase;
}
.console-sticky__list{
    display: flex;
    justify-content: space-around;
}