.header-image-gallery{
    position: relative;
}

.video.is-playing .header-image-gallery__play-btn {
    opacity: 0;
    pointer-events: none;
}

.header-image-gallery__play-btn {
    transition: opacity 0.3s ease-in-out;

    &:before {
        content: "\E051";
        font-family: "iconfont";
        speak: none;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -30px;
        margin-left: -30px;
        font-size: 60px;
        width: 62px;
        color: #fff;
        z-index: 1;
    }

    &:after {
        content: '';
        background: #00a4d0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.7;
        transition: 250ms ease-in;
    }
}

.header-image-gallery__play-btn-image {
    object-fit: cover;
}

.header-image-gallery__pagination.swiper-pagination-fraction {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    width: max-content;
    padding: 0.2rem 0.5rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}
