.quickfinder__remove {
    font-size: 20px;
    color: currentColor;
    margin-left: 5px;
    background: none;
    border: 0;
    padding: 0;
    display: inline-block;
    vertical-align: -0.15em;
}
.quickfinder__remove:hover {
    color: #006390;
}
.quickfinder__row {
    display: flex;
    flex-wrap: wrap;
}
.quickfinder__row-title {
    margin-top: auto;
    margin-bottom: auto;
}
.quickfinder__add {
    font-size: 16px;
    display: flex;
}
.quickfinder__add:hover {
    color: #006390;
}
.quickfinder__add__icon.quickfinder__add__icon {
    font-size: 20px;
    color: currentColor;
    transform: rotate(45deg);
    margin-right: 5px;
}