.img-text-teaser {
    position: relative;
}
.img-text-teaser__content {
    padding: 20px;
    overflow: hidden;
}
.img-text-teaser__content__title {
    font-size: 24px;
    line-height: calc(32/27);
    margin-bottom: 0;
}
@media screen and (min-width: 768px) {
    .img-text-teaser__content__title {
        font-size: 27px;
    }
}

.img-text-teaser__head {
    position: relative;
}
.img-text-teaser__head__arrow {
    font-size: 24px;
    left: 20px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    z-index: 1;
}
@media screen and (min-width: 768px) {
    .img-text-teaser__head__arrow {
        font-size: 20px;
        color: #00a4d0;
        left: -30px;
    }
}
.img-text-teaser__head__arrow--next {
    left: auto;
    right: 20px;
}
@media screen and (min-width: 768px) {
    .img-text-teaser__head__arrow--next {
        left: auto;
        right: -30px;
    }
}

@media screen and (max-width: 767px) {
    .img-text-teaser__head__arrow {
        display: none;
    }
}

.img-text-teaser--overlay-content .img-text-teaser__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC40NSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.8)));
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
    background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
    background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#73000000',GradientType=0 );
    z-index: 1;
    padding: 10px 20px;
}
@media screen and (min-width: 768px) {
    .img-text-teaser--overlay-content .img-text-teaser__content {
        padding: 20px;
    }
}