.categories{
    /*display: flex;*/
    font-size: 1.2rem;
    color: #00a4d0;
}

.event-category{
    margin-right: 1.2rem;
}

.event-subcategory::before {
    content: ".";
    position: absolute;
    font-size: 1.7rem;
    transform: translateY(-1.2rem) translateX(-0.9rem);
}

.attributes{
    font-size: 1rem;
}

a.event-tag__item {
    white-space: nowrap;
}

.event-dates{
    font-size: 14px;
}

.add-to-calendar .icon{
    position: relative;
    top: 2px;
}