body.search-is-open {
    overflow-y: hidden;
}
.fullpage-search {
    color: #fff;
    visibility: hidden;
    opacity: 0;
    padding: 60px 15px 15px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5000;
    text-transform: none;
    transform: translateX(100%);
    transition: transform 250ms ease-in-out;
    overflow-y: auto;
    overflow-x: hidden;
}
.fullpage-search.is-open {
    transform: translate(0);
    visibility: visible;
    opacity: 1;
}
.fullpage-search__close {
    font-size: 40px;
    position: absolute;
    right: 15px;
    top: 10px;
    cursor: pointer;
    border: none;
    background-color: transparent;
}
.fullpage-search__field .form-control {
    background: none;
    border: 1px solid #fff;
    color: #fff;
    font-family: 'SohoPro', sans-serif;
    font-weight: 400;
    font-size: 20px;
    padding: 15px 20px;
    height: auto;
    box-sizing: border-box;
}
.fullpage-search__field .form-control:focus {
    border-color: #009ac4;
    background: #009ac4;
}
.fullpage-search__field .form-control::-webkit-input-placeholder {
    color: #fff;
}
.fullpage-search__field .form-control::-moz-placeholder {
    color: #fff;
}
.fullpage-search__field {
    position: relative;
}
.fullpage-search__field .icon {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -12px;
    font-size: 24px;
    color: #11c2f1;
    padding: 0;
    background: none;
}
.fullpage-search__field .twitter-typeahead {
    width: 100%;
}
.fullpage-search h2 {
    color: #004152;
}
.fullpage-search h3, .search-result {
    color: #002d39;
}
.fullpage-search .container {
    max-width: 1800px;
}
.search-result__item {
    border-bottom: 1px solid #CED2D9;
    padding-bottom: calc(20rem/16);
}
.search {
    margin-right: 25px;
    position: relative;
    /*top: 6px;*/
    font-size: 18px;
}
.open-search .icon {
    position: relative;
    top: 3px;
}
/*.search:after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    right: -20px;*/
/*    top: 5px;*/
/*    bottom: 0;*/
/*    background-color: #fff;*/
/*    width: 1px;*/
/*}*/

.search-result .highlight {
    padding: 1px 1px;
    background-color: #cbd300;
}
.loading-spinner {
    background-color: rgba(0,0,0,0);
    border: 5px solid rgba(0, 65, 82, 0.9);
    opacity: .9;
    border-top: 5px solid rgba(0,0,0,0);
    border-left: 5px solid rgba(0,0,0,0);
    border-radius: 50px;
    width: 55px;
    height: 55px;
    margin: 0 auto;
    -o-animation: spin .6s infinite linear;
    -webkit-animation: spin .6s infinite linear;
    -moz-animation: spin .6s infinite linear;
    animation: spin .6s infinite linear;
    transition-delay: 150ms;
}
.fullpage-search input[type=text].form-control {
    -webkit-appearance: none;
}
@media screen and (min-width: 768px){
    .open-search .icon {
        top: 5px;
        margin-right: 0;
    }
    .search {
        /*top: 8px;*/
        font-size: 15px;
        margin-right: calc(22.5rem/16);
    }
    .search:after {
        right: -15px;
    }
    .fullpage-search {
        padding: 100px 60px;
    }
    .fullpage-search__close {
        right: 30px;
        top: 30px;
        font-size: 50px;
    }
    .fullpage-search__field .form-control {
        font-size: 60px;
        padding: 29px 50px;
    }
    .fullpage-search__field .icon {
        right: 46px;
        margin-top: -28.5px;
        font-size: 57px;
    }
    .fullpage-search__container {
        /*max-height: 720px;*/
        /*overflow-y: scroll;*/
        /*overflow-x: hidden;*/
        padding: 0 40px;
    }
    /*.fullpage-search__container::-webkit-scrollbar {*/
        /*-webkit-appearance: none;*/
        /*width: 15px;*/
    /*}*/
    /*.fullpage-search__container::-webkit-scrollbar-thumb {*/
        /*border-radius: 10px;*/
        /*background-color: #004152;*/
    /*}*/
    /*.fullpage-search__container::-webkit-scrollbar-track {*/
        /*background: rgba(0,130,164, .5);*/
        /*border-radius: 10px;*/
    /*}*/
}
/*@media screen and (min-width: 1200px){*/
/*    .search {*/
/*        top: 6px;*/
/*        font-size: 18px;*/
/*        margin-right: 25px;*/
/*    }*/
/*    .search:after {*/
/*        right: -20px;*/
/*    }*/
/*    .open-search .icon {*/
/*        margin-right: 10px;*/
/*    }*/
/*}*/

.search__container{
    margin: calc(30rem/16) 0;
    @media screen and (min-width: 768px){
        margin: calc(70rem/16) 0;
    }
}
.search__item{
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: calc(15rem/16);
    @media screen and (min-width: 768px){
        padding-left: calc(30rem/16);
        margin-top: 0;
    }
}
.search__item-image{
    @media screen and (max-width: 767px){
        max-width: 258px;
    }
}
.search__item-title{
    @media screen and (max-width: 767px){
        font-size: 22px;
        line-height: calc(26/22);
    }
}
.fullpage-search__label.floating-label{
    font-size: 20px;
    font-family: 'SoHoPro', sans-serif;
    font-weight: 400;
    padding-left: 10px;
    padding-top: 10px;
    @media screen and (min-width: 768px){
        font-size: 60px;
        padding-left: 40px;
        padding-top: 22px;
        line-height: 85px;
    }
}
.form-control.has-value~.fullpage-search__label.floating-label, .form-control:focus~.fullpage-search__label.floating-label, .form-group.has-value .fullpage-search__label.floating-label, select~.fullpage-search__label.floating-label{
    transform: translateY(-9px) scale(.5) translateX(10px);
    @media screen and (min-width: 768px){
        transform: translateY(0) scale(.3) translateX(100px);
    }
}

.search__recently{
    position: relative;
    top: 3px;
    margin-right: 20px;
    @media screen and (max-width: 767px){
       margin-bottom: 23px!important;
    }
}

.search__recently-btns,
.search__suggesetion{
    @media screen and (max-width: 767px){
       text-align: center;
    }
}

.search__recently-btns .btn{
    margin-right: 7px;
    margin-bottom: 12px;
}
