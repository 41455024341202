:root {
    --teaser-img-height: 80px;
}

.tourism-office__grid {
    display: flex;
    flex-wrap: wrap;
}

.tourism-office__card-teaser {
    display: flex;
    flex-direction: column;
}

.card-teaser__img {
    height: var(--teaser-img-height);
    background-color: var(--color-primary);
    display: flex;
    justify-content: center;
}

.card-teaser__img img {
    object-fit: contain;
}

.card-teaser__body {
    background-color: #fff;
    border: 2px solid var(--color-grey);
    border-top: none;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - var(--teaser-img-height));
}
.card-teaser__content a:hover div{
    text-decoration: underline;
}
.link-icon-text {
    display: flex;
}
.card-teaser__contact {
    display: flex;
    flex-direction: column;
}

.card-teaser__contact a {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.card-teaser__action {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    margin-top: 20px;
}
