.datepicker-static .flatpickr-day:not(.flatpickr-disabled){
    pointer-events: none;
    background: transparent;
    border-color: transparent;
    color: #000;
    outline: calc(2rem/17) solid #82B81A;
    outline-offset: calc(-3rem/17);
}

.datepicker-static .flatpickr-day.flatpickr-disabled{
    cursor: default;
}

.datepicker-static .flatpickr-days{
    width: auto;
}

.datepicker-static .dayContainer{
    width: auto;
    min-width: auto;
    max-width: none;
}

.flatpickr-day.flatpickr-disabled{
    color: rgba(57,57,57,.3);
}


.datepicker-static .flatpickr-calendar{
    border: none;
    box-shadow: none;
    border-radius: 0;
}

@media screen and (min-width: 767px){
    .datepicker-static .flatpickr-calendar{
        width: auto;
    }
    .datepicker-static .flatpickr-day{
        height: 29px;
        line-height: 29px;
    }
}

.datepicker-static .flatpickr-day{
    height: 35px;
    line-height: 35px;
}

.datepicker-static .flatpickr-innerContainer{
    border: 1px solid #ced2d9;
    padding-bottom: 5px;
}




.flatpickr-day{
    border-radius: 0;
}

.flatpickr-day.endRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.endRange.nextMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.endRange:focus,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.selected:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.startRange:focus,
.flatpickr-day.startRange:hover {
    background: #82B81A;
    border-color: #82B81A;
}

.flatpickr-months .flatpickr-next-month:hover svg,
.flatpickr-months .flatpickr-prev-month:hover svg {
    fill: #fff;
}

.flatpickr-months{
    background-color: #00A4D0;
    color: #fff;
    padding: 2px 0 5px;
    font-family: SoHoPro,sans-serif;
}

.flatpickr-months .flatpickr-month {
    color: #fff;
    fill: #fff;
}

.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month{
    color: #fff;
    fill: #fff;
    top: 2px;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month{
    color: #000;
}

span.flatpickr-weekday{
    font-family: RobotoBold,RobotoBold-latin-ext,RobotoBold-cyrillic,sans-serif;
    color: #013E59;
}