.share {
    position: relative;
    margin-top: 30px;
    padding: 0 0 35px 83px;
    display: inline-block;
}
@media screen and (min-width: 768px) {
    .share {
        margin-top: 0;
        display: block;
        position: fixed;
        bottom: 30px;
        right: 20px;
        padding: 0;
        padding-top: 62px;
        z-index: 100;
        opacity: 0;
        transition: opacity 250ms ease-in-out;
    }
    .affix.share,
    .affix-bottom.share {
        opacity: 1;
    }
    .affix-bottom.share {
        position: absolute;
        bottom: auto;
    }
}
@media screen and (min-width: 1400px) {
    .share {
        right: 30px;
    }
}
.share .at-share-tbx-element .at-share-btn {
    margin: 0 8px 8px 0;
}
@media screen and (min-width: 768px) {
    .share .at-share-tbx-element .at-share-btn {
        margin: 0 0 8px 0;
        display: block;
    }
}
.share__text {
    color: #777;
    font-size: 30px;
    display: inline-block;
    position: absolute;
    top: -1px;
    left: 0;
}
@media screen and (min-width: 768px) {
    .share__text {
        font-size: 20px;
        transform: rotate(-90deg);
        top: 0;
        left: -7px;
    }
}