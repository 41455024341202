.quotation-block {
    margin-bottom: 20px;
}
@media screen and (min-width: 768px) {
    .quotation-block {
        margin-bottom: 40px;
    }
}
.quotation-block__hashtag {
    font-size: 25px;
}
@media screen and (min-width: 768px) {
    .quotation-block__hashtag {
        font-size: 32px;
    }
}
.quotation-block__hl {
    font-family: 'RobotoLight', 'RobotoLight-latin-ext', 'RobotoLight-cyrillic', sans-serif;
    font-weight: 400;
    font-size: 35px;
    line-height:calc(44/50);
    color: #404040;
}
@media screen and (min-width: 768px) {
    .quotation-block__hl {
        font-size: 50px;
    }
}
.quotation-block__author {
    font-size: 16px;
}