.poi-detail__list{
    font-family: 'SohoPro', sans-serif;
    font-weight: 400;
    font-size: calc(20rem/17);
    @media screen and (min-width: 768px) {
        margin-top: calc(20rem/17);
    }
}
.poi-detail__list li{
    padding-left: calc(40rem/17);
    position: relative;
    @media screen and (min-width: 768px) {
        padding-left: calc(50rem/17);
    }
}
.poi-detail__list li:before{
    content: '\E039';
    font-family: iconfont;
    font-size: calc(40rem/17);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-green);
    @media screen and (min-width: 768px) {
        font-size: calc(45rem/17);
    }
}
.poi-detail__list li + li {
    margin-top: calc(6rem/17);
    @media screen and (min-width: 768px) {
        margin-top: calc(14rem/17);
    }
}