.poi-weather{
    padding: calc(40rem/17) calc(20rem/17);
    background: var(--color-primary);
    color: #FFFFFF;
    @media screen and (min-width: 768px) {
        padding: calc(60rem/17);
    }
}
.poi-weather__title{
    color: #FFFFFF;
    font-size: calc(25rem/17);
    line-height: calc(43/36);
    @media screen and (min-width: 768px) {
        font-size: calc(36rem/17);
    }
}
.poi-weather__degrees-min{
    font-size: calc(15rem/17);
    font-family: 'SoHoPro', sans-serif; font-weight: 400;
    @media screen and (min-width: 768px) {
        font-size: calc(25rem/17);
    }
}
.poi-weather__degrees-max{
    font-size: calc(20rem/17);
    font-family: 'SoHoPro', sans-serif; font-weight: 400;
    @media screen and (min-width: 768px) {
        font-size: calc(36rem/17);
    }
}