.colteaser__row{
    display: flex;
    flex-wrap: nowrap;
    gap: 15px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
}
.colteaser__item{
    min-width: 80vw;
    scroll-snap-align: start;
}