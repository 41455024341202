/* inline nav */
.inline-nav {
    background: #8BBE50;
    position: relative;
    width: 100%;
    padding: 15px 5px 15px 35px;
    height: 50px;
    overflow: hidden;
}
@media screen and (min-width: 768px) {
    .inline-nav {
        height: auto;
        background: #fff;
        padding: 20px 2px;
    }
}
.inline-nav.is-affix {
    top: 75px;
    position: fixed;
    z-index: 2;
}
@media screen and (min-width: 768px) {
    .inline-nav__state {
        position: absolute;
        bottom: 0;
        left: -100%;
        width: 100%;
        height: 3px;
        background: #8BBE50;
        transition: transform 250ms ease-in-out;
    }
}
.inline-nav > ul > li {
    display: inline-block;
}
@media screen and (max-width: 767px){
    .inline-nav li:not(:last-child) {
        margin-right: 16px;
    }
}
.inline-nav__item {
    font-size: 14px;
    line-height: calc(17/14);
    color: #fff;
    position: relative;
    transition: opacity 250ms ease-in-out;
    display: block;
}
@media screen and (min-width: 768px) {
    .inline-nav__item {
        color: #49494C;
        opacity: .4;
        font-size: 20px;
        line-height: calc(24/20);
    }
    .is-active .inline-nav__item {
        opacity: 1;
    }
}

@media screen and (min-width: 768px){
    .inline-nav__item__count {
        margin-left: 3px;
        color: #A5A8AE;
    }
}
@media screen and (min-width: 768px) {
    .inline-nav li + li .inline-nav__item:before {
        content: "\E085";
        font-family: iconfont;
        position: absolute;
        top: 50%;
        left: 10px;
        font-size: 14px;
        transform: translateY(-50%);
        line-height: 1;
    }
    .inline-nav li + li .inline-nav__item {
        padding-left: 40px;
    }
}

@media screen and (max-width: 767px) {
    .inline-nav ul {
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}