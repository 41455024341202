.panel {
    border-radius: 0;
    box-shadow: none;
    border: 0;
}
.panel-heading {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.panel>.panel-heading {
    color: #fff;
    border-color: #ced2d9;
    padding: 15px 20px;
}
.panel-default>.panel-heading {
    background-color: #82b80d;
}
.panel-secondary>.panel-heading {
    background-color: #cbd300;
    border-color: #cbd300;
}
.panel-body {
    border: 1px solid #ced2d9;
    border-top: 0;
}

.picture-panel .panel-body, .weather-panel .panel-body{
    border: 0;
}
.picture-panel .panel-body {
    padding: 0;
    margin-top: 1px;
}
.picture-panel__item-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 45px 15px 18px;
    z-index: 1;
}
.picture-panel__item-text__link {
    position: absolute;
    right: 18px;
    bottom: 15px;
}
.picture-panel__item-headline {
    line-height: 26px;
    margin-bottom: 5px;
}

/*Conditions Panel*/
.panel-conditions--footer .panel-body {
    border-top: 1px solid #ced2d9;
    border-bottom: 0;
}

.panel-conditions .panel-heading{
    position: relative;
}

.panel-conditions .panel-heading{
    text-align: center;
}

@media screen and (min-width: 768px){
    .panel-conditions .panel-heading .last-update{
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
}


.conditions__icon {
    font-size: 45px;
}
.panel-conditions .col-sm-2,
.panel-conditions .col {
    border-right: 1px solid #ccd1da;
}

@media screen and (max-width: 767px){
    .panel-conditions .col:nth-child(2n+2),
    .panel-conditions .col-sm-2:nth-child(2n+2) {
        border-right: none;
    }
}

.panel-conditions .col:last-child {
    border-right: 0;
}
.panel-conditions__text {
    font-family: 'SohoPro', sans-serif;
    font-weight: 400;
}
.panel-conditions .panel-footer {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 0;
}
.panel-conditions .weather-icon {
    margin-left: auto;
    margin-right: auto;
}
.panel-conditions__top {
    margin-top: 13px;
}
.panel-conditions .panel-conditions__text {
    font-size: 27px;
}
.panel-conditions .weather-condition .weather-icon {
    height: 71px;
}

.bulletinDiski .panel-conditions .panel-body{
    padding: 20px 15px 15px;
}

.panel-conditions .panel-body {
    border-top:1px solid #ced2d9;
    padding: 20px 15px 0 15px;

}

@media screen and (min-width: 768px){
    .panel-conditions .panel-body {
        padding: 35px 15px 0 15px;

    }

    .bulletinDiski .panel-conditions .panel-body{
        padding: 20px 15px 0;
    }
}

.panel-conditions .panel-heading ~ .panel-body {
    border-top:0;
}
/*weather-panel*/
.weather-panel .panel-body {
    padding: 30px 0 0;
}

/*info panel*/
.panel-info {
    border: 0;
}
.panel-info >.panel-heading {
    background-color: inherit;
    padding: 30px 50px;
}
.panel-info .panel-heading .h2 {
    margin-bottom: 5px;
}
.panel-info__address {
    font-size: 16px;
}
@media screen and (min-width: 768px){
    .panel-conditions .weather-icon {
        width: 64px;
        height: 71px;
    }
    .panel-conditions .panel-conditions__text {
        font-size: 36px;
    }
    .panel-conditions .weather-condition .weather-icon {
        height: 71px;
    }
}

/*panel-FAQ*/
.panel-group .panel-primary+.panel-primary {

}

.panel-primary .panel-body{
    border: 0 !important;
    padding: 0 calc(20rem/16) calc(25rem/16) calc(20rem/16);
}
.panel-primary  >.panel-heading{
    background-color: #fff;
    padding: calc(25rem/16) calc(20rem/16);
    color: #00a4d0;
    border-top: 1px solid #ced2d9;
}

.panel-primary  >.panel-heading a{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.panel-primary__icon{
    font-size: 18px;
    transition: 0.2s;
}

@media screen and (max-width: 767px) {
    .panel-primary__icon{
        font-size: 12px;
    }
}

.panel-primary a:not(.collapsed) >.panel-primary__icon{
    transform: rotate(45deg);
}

.panel-collapse a {
    color: #00a4d0;
    text-decoration: underline;
}