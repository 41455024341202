.leaflet-map:not(.affix-map) {
    position: relative;
    z-index: 1;
}
.leaflet-map .leaflet-control-layers {
    display: none;
}
.leaflet-map__map{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.leaflet-map.leaflet-map--fullpage {
    position: fixed;
    left: 0;
    top: 100px;
    bottom: 0;
    right: 0;
    background: #fff;
}
@media screen and (min-width: 768px) {
    .leaflet-map.leaflet-map--fullpage{
        top: 184px;
    }
    .nav-wrapper.affix ~ .main-content .leaflet-map.leaflet-map--fullpage {
        top: 159px;
    }
}

.leaflet-map .marker-cluster {
    width: 48px;
    height: 48px;
    background: #00a4d0;
    color: #fff;
    font-size: 20px;
    font-family: "RobotoBold", sans-serif;
    border-radius: 50%;
    border: 2px solid #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 2px rgba(0,0,0,.5);
}

.leaflet-map__infobox .leaflet-popup-content-wrapper {
    box-shadow: none;
    padding: 0;
    border-radius: 0;
}
.leaflet-map__infobox .leaflet-popup-content {
    background: #e7e9ec;
    padding: 15px;
    margin: 0;
    line-height: calc(17/14);
}
@media screen and (min-width: 768px) {
    .leaflet-map__infobox .leaflet-popup-content {
        padding: 25px 30px;
    }
}
.leaflet-map__infobox a.leaflet-popup-close-button {
    padding: 12px;
    width: 40px;
    height: 40px;
    right: -40px;
    top: 0;
    background-color: #006390;
    font-size: 24px;
    color: #fff;
    font-weight: lighter;
}
.leaflet-map__infobox h2 {
    line-height: 30px;
    margin-bottom: 16px;
}
.leaflet-map__infobox .leaflet-popup-content p {
    margin: 0;
    margin-bottom: 10px;
}
.leaflet-map__infobox a.btn {
    color: #fff;
}
.leaflet-map__infobox .leaflet-popup-tip-container {
    display: none;
}

.leaflet-map-marker__label {
    color: #fff;
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
}

.affix-map:not(.is-fullscreen) .leaflet-map__infobox {
    display: none;
}
.affix-map:not(.is-fullscreen) .leaflet-marker-icon {
    pointer-events: none;
}

.leaflet-map.is-loading:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    background-color: rgba(0,0,0,0);
    border: 5px solid rgba(0,164,208,0.9);
    opacity: .9;
    border-top: 5px solid rgba(0,0,0,0);
    border-left: 5px solid rgba(0,0,0,0);
    border-radius: 100%;
    width: 50px;
    height: 50px;
    animation: mapLoading .6s infinite linear;
}
@-webkit-keyframes mapLoading {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.leaflet-popup-content__pretitle{
    text-transform: uppercase;
    color: var(--color-secondary);
    font-size: 14px;
    margin-bottom: 10px;
    font-family: var(--font-bold);
}
