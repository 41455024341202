.google-map-fullpage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1100;
}
.google-map-fullpage__header,
.affix-map__header {
    padding: 15px;
}

.google-map-fullpage__header .btn-icon .icon{
    pointer-events: none;
}

.google-map-fullpage__header .btn-group.nav-tabs>li.active{
    background-color: var(--color-primary);
}


.google-map-fullpage h3 {
    color: #fff;
    font-size: 30px;
    margin-bottom: 0;
}

@media screen and (max-width: 767px){
    .google-map-fullpage h3 {
        color: #fff;
        font-size: 25px;
        margin-bottom: 0;
        margin-top: -16px;
    }
}

.google-map-fullpage .js-google-map__canvas {
    position: fixed !important;
    left: 0;
    top: 133px;
    bottom: 0;
    right: 0;
}
/*Close button*/
.google-map-fullpage__header__close .btn,
.affix-map__header__close {
    text-transform: none;
    font-size: 14px;
    padding: 14px 8px;
}
.google-map-fullpage__header__close .btn>.icon-close,
.affix-map__header__close >.icon-close{
    position: relative;
    top: 2px;
    padding-left: 5px;
}
/*Infobox*/
.google-map__infobox {
    background: #e7e9ec;
    padding: 15px;
}
@media screen and (min-width: 768px) {
    .google-map__infobox {
        padding: 25px 30px;
    }
}
.google-map__infobox>img {
    position: absolute !important;
    padding: 12px;
    width: 40px;
    height: 40px;
    right: -40px;
    top: 0;
    background-color: #006390;
}
.google-map__infobox h2 {
    line-height: 30px;
    margin-bottom: 16px;
}
.google-map-fullpage.is-hidden {
    display: none;
}
.gm-style-iw h3 {
    font-size: 24px;
    margin-bottom: 2px;
    color: #006390;
}
.stage-map .map-twopoint-infowindow {
    color: #00a4d0;
}
.stage-map .map-twopoint-infowindow:hover {
    color: #286090;
}
@media screen and (min-width: 768px){
    .google-map-fullpage {
        top: 100px;
    }
    .nav-wrapper.affix ~ .main-content .google-map-fullpage {
        top: 75px;
    }
    .google-map-fullpage .js-google-map__canvas {
        top: 182px;
    }
    .nav-wrapper.affix ~ .main-content .google-map-fullpage .js-google-map__canvas {
        top: 159px;
    }
    .google-map-fullpage__header {
        padding: 15px 30px;
    }
    .google-map-fullpage__header__close .btn {
        font-size: 16px;
        padding: 14px 20px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px){
    .google-map-fullpage {
        top: 0;
    }
    .google-map-fullpage .js-google-map__canvas {
        top: 82px;
    }
}

/* affix google map */
.affix-map {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1001;
    transform: translateX(100%);
    transition: transform 250ms ease-in-out;
    display: flex;
    flex-direction: column;
}
@media screen and (min-width: 786px) {
    .affix-map {
        transition: none;
        transform: none;
        bottom: auto;
        left: auto;
        width: 260px;
        height: 406px;
        position: relative;
    }
    .affix.affix-map {
        position: fixed;
        top: 75px;
        right: 0;
        z-index: 101;
    }
    .affix-map-placeholder {
        position: absolute;
        right: 0;
        left: auto;
    }
}
@media screen and (min-width: 1200px) {
    .affix-map.affix-map {
        width: 190px;
    }
}
@media screen and (min-width: 1600px) {
    .affix-map.affix-map {
        width: 320px;
    }
}
.affix-map__map {
    width: 100%;
    z-index: 0;
    flex-grow: 1;
}

.affix-map__toggle{
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    box-shadow: 3px 0 6px 0 rgba(0,0,0,.12);
    width: 50px;
    height: 50px;
    font-size: 20px;
}
@media screen and (min-width: 768px) {
    .affix-map__toggle{
        transform: none;
        top: auto;
        bottom: -32px;
        left: -32px;
        width: 65px;
        height: 65px;
        font-size: 22px;
    }
}
.affix-map__toggle .affix-map__toggle__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transition: transform 250ms ease-in-out;
}
@media screen and (min-width: 768px) {
    .affix-map__toggle .affix-map__toggle__icon {
        transform: translateX(-50%) translateY(-50%) rotate(180deg);
    }
}
.affix-map__header {
    width: 100%;
    z-index: 1;
    overflow: hidden;
    display: none;

}
@media screen and (min-width: 768px) {
    .affix-map__header {
        text-align: center;
    }
}
/* fullscreen */
.affix-map.is-fullscreen {
    transform: translateX(0);
}
@media screen and (min-width: 768px) {
    .affix-map.is-fullscreen {
        transform: none;
        width: 100% !important;
        height: calc(100% - 75px);
        z-index: 10000;
        position: fixed;
        top: 75px !important; /* overwrite inline style affix-bottom */
        right: 0;
        left: 0!important;
    }
    .is-fullscreen .affix-map__toggle {
        position: fixed;
        bottom: 0;
        left: 0;
    }
    .is-fullscreen .affix-map__toggle .affix-map__toggle__icon {
        transform: translateX(-50%) translateY(-50%) rotate(0);
    }
}
.affix-map.is-fullscreen .affix-map__header {
    display: block;
}

.stage-map-detail {
    display: none;
}
.stage-map-detail.is-open {
    display: block;
    width: 100%;
    height: calc(100% - 75px);
    top: 75px !important; /* overwrite inline style affix-bottom */
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    position: fixed;
}


.affix-navbar-container.is-affix ~ .main-content .google-map-fullpage {
    top: 75px;
}
.affix-navbar-container.is-affix ~ .main-content .leaflet-map.leaflet-map--fullpage,
.affix-navbar-container.is-affix ~ .main-content .google-map-fullpage .js-google-map__canvas{
    top: 159px;
}