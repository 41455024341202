.form-control,
.react-select__control.react-select__control {
    box-shadow: none;
    border-radius: 0;
    background-color: #fafbfb;
    border-color: #ced2d9;
    height: 50px;
    color: #48494c;
    font-family: 'RobotoLight', 'RobotoLight-latin-ext', 'RobotoLight-cyrillic', sans-serif;
    font-weight: 400;
    font-size: 16px;
    border-radius: 0;
}
.react-select__placeholder {
    color: #48494c !important;
}
.form-control:focus {
    border-color: #00a4d0;
    box-shadow: none;
}
.form-group label {
    font-size: 16px;
}

/*input groups*/
.input-group-btn {
    border-radius: 0;
    padding: 0;
    border: 0;
    white-space: normal;
}
.input-group-btn .btn {
    padding: 17px;
}

/*Select*/
.select-form {
    position: relative;
}
.select-form select.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 30px;
}
.select-form:after {
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #00a3d0 transparent transparent transparent;
    pointer-events: none;
}
.select-form .select-form__btn {
    width: 49px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    pointer-events: none;
    z-index: 1;
}

.select-form .btn-grey.select-form__btn.icon-rotate-90  {
    width: 50px;
    border: 1px solid #ced2d9;
    border-bottom: 0;
}

.form-group>.btn input[type=radio], .form-group>.btn input[type=checkbox] {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
}

/*datepicker*/
.datepicker-control {
    position: relative;
}
.datepicker-control.datepicker-control .form-control {
    background-color: #fafbfb;
}
.filter-collapse .datepicker-control .form-control {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
}
.datepicker-control .datepicker-control__icon {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -8.5px;
    pointer-events: none;
}
.ui-datepicker {
    background-color: #fff;
}
.ui-datepicker .ui-datepicker-header {
    background: #59c4e0;
    padding: .4em .4em .2em .4em;
    margin: -.2em -.2em 0 -.2em;
    color: #fff;
}
.ui-datepicker-next .ui-icon.ui-icon,
.ui-datepicker-prev .ui-icon.ui-icon {
    display: none;
    visibility: hidden;
    background: none;
}
.ui-datepicker-next .icon.icon,
.ui-datepicker-prev .icon.icon {
    color: #ffffff;
    background: none;
}
.ui-datepicker.ui-datepicker .ui-datepicker-title {
    font-family: SoHoPro,sans-serif;
    font-weight: 400;
    font-size: 20px;
    text-shadow: none;
}
.ui-datepicker th {
    font-family: 'RobotoBold', 'RobotoBold-latin-ext', 'RobotoBold-cyrillic', sans-serif;
    font-weight: 400;
    color: #00405E;
}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
    top: 8px;
}
.ui-datepicker .ui-datepicker-next-hover {
    right: 2px;
}
.ui-datepicker .ui-datepicker-prev-hover {
    left: 2px;
}
.ui-datepicker td a, .ui-datepicker td span {
    text-align: center;
    font-family: 'RobotoLight', 'RobotoLight-latin-ext', 'RobotoLight-cyrillic', sans-serif;
    font-weight: 400;
}
.ui-datepicker td a:hover {
    background: #59c4e0;
    color: #fff;
    text-shadow: none;
}
.ui-state-disabled {
    color: #ced2d9;
}
.ui-datepicker-header .ui-state-disabled {
    opacity: 0.6;
    color: #fff;
}
.ui-state-active.ui-state-active.ui-state-active {
    color: #fff;
    background: #00A4D0;
}

/*Fake Checkbox*/
.checkbox-fake input[type=checkbox] {
    opacity: 0;
}
.checkbox-fake input[type=checkbox]:focus-visible + .checkbox-fake__fake-checkbox{
    border-color: var(--color-primary-light);
}
.checkbox-fake>label {
    padding-left: 0;
}
.checkbox-group-pl .checkbox-fake>label {
    padding-left: 19px;
}
.checkbox-fake>label a {
    text-decoration: underline;
}
.checkbox-fake .checkbox-fake__required {
    position: absolute;
    left: 0;
    top: 0;
}
.checkbox-fake input[type=checkbox]:checked+.checkbox-fake__fake-checkbox {
    text-indent: 0;
}
.checkbox-fake__fake-checkbox.checkbox-fake__fake-checkbox {
    width: 19px;
    height: 19px;
    background: #fff;
    border: 1px solid #ced2d9;
    box-sizing: content-box;
    text-indent: -9999px;
    margin-right: 10px;
    overflow: hidden;
    vertical-align: middle;
    color: #006390;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 5px;
}

/*form box*/
.form-box {
    padding: 30px 20px;
    font-size: 16px;
}
.form-box .form-control {
    background: #fff;
}
.form-box textarea.form-control {
    height: 234px;
}

/*Parsley*/
.parsley-errors-list {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #a94442;
    font-size: 14px;
}
.has-success .form-control:focus {
    border-color: #83b81a;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #83b81a;
}
.has-success .form-control, .has-success .checkbox-fake__fake-checkbox {
    border-color: #83b81a;
}
.has-error .checkbox-fake__fake-checkbox {
    border-color: #a94442;
}

@media screen and (min-width: 768px) {
    .form-box {
        padding: 40px;
    }
}
