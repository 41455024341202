.guest-card {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    transform: translateX(100%);
    opacity: 0;
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    z-index: 1007;
    padding: 40px 0 30px;
}
@media screen and (max-width: 767px){
    .guest-card:after {
        content: '';
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #262626;
        opacity: .4;
        z-index: -1;
    }
}
@media screen and (min-width: 768px) {
    .guest-card {
        width: 543px;
        max-width: 100%;
        padding: 50px 0 70px;
    }
}
.is-open .guest-card {
    transform: translateX(0);
    opacity: 1;
}
.guest-card__content {
    background-color: #00405E;
    color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.guest-card__content__scroll {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px 20px 40px;
}
@media screen and (min-width: 768px) {
    .guest-card__content__scroll {
        padding: 20px 50px 45px;
    }
}
@media screen and (min-width: 1400px) {
    .guest-card__content__scroll {
        padding: 20px 80px 50px 80px;
    }
}
.guest-card__content__title {
    font-size: 50px;
    line-height: calc(60/50);
    margin-bottom: 15px;
    margin-right: 30%;
}
@media screen and (min-width: 768px) {
    .guest-card__content__title {
        font-size: 60px;
        line-height: calc(70/60);
        margin-right: 35%;
        margin-bottom: 25px;
    }
}
.guest-card__content__bottom {
    margin-top: 15px;
}
@media screen and (min-width: 1200px) {
    .guest-card__content__bottom {
        margin-top: 30px;
    }
}
.guest-card__top-img {
    position: absolute;
    right: 0;
    top: 0;
    width: 35%;
    height: auto;
    z-index: 1;
}
@media screen and (min-width: 768px) {
    .guest-card__top-img {
        width: 40%;
    }
}
@media screen and (min-width: 768px) {
    .guest-card-toggle {
        width: 150px;
        right: 70px;
        bottom: 70px;
    }
}
.guest-card-toggle__img {
    transition: opacity 250ms ease-in-out;
}
.is-open .guest-card-toggle__img {
    opacity: 0;
}
.guest-card-toggle__btn {
    height: 50px;
    width: 50px;
    border: 5px solid #FFFFFF;
    background-color: #8BBE50;
    border-radius: 50%;
    color: #fff;
    position: fixed;
    right: 5px;
    bottom: 70px;
    z-index: 1008;
}
.guest-card-toggle__btn:focus,
.guest-card-toggle__btn:hover {
    background: #82b80d;
    color: #fff;
}
.guest-card-toggle__btn__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.is-open .guest-card-toggle__btn__icon:before {
    content: "\E099";
}
.guest-card__content__img {
    display: none;
}
@media screen and (min-height: 800px) {
    .guest-card__content__img {
        display: block;
    }
}

.guest-card__image{
    position: fixed;
    right: 30px;
    bottom: 100px;
    width: 100px;
    z-index: 1007;
}
@media screen and (max-height: 600px) {
    .guest-card__image {
        width: 70px;
    }
}
@media (max-width: 767px) {
    .guest-card__image{
        transition: transform 200ms ease-in-out;
        transform-origin: bottom right;
    }
    .guest-card__image.is-hidden{
        transform: scale(0) rotate(90deg);
    }
}