.embed-responsive-content-video .video-js.embed-responsive-item,
.embed-responsive-hero-video .video-js.embed-responsive-item,
.embed-responsive .video-js.embed-responsive-item {
    width: 100% !important;
    height: 100% !important;
}
.asset-video {
    height: 600px;
}

.video-asset video{
    @media screen and (max-width: 767px){
       width: 100%;
        height: auto;
    }
}

.video__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 10em;
    background: none;
    box-shadow: none;
    width: 100px;
    height: 100px;
    border-radius:100%;
    border: 2px solid #fff;
    transition: opacity 200ms ease-in-out;
}
.video:hover .video__play {
    background: none;
    box-shadow: none;
    border-color: #00A4D0;
}
.video__play-icon.video__play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-40%);
    text-shadow: none;
    color: #fff;
    font-size: 55px;
}
.video:hover .video__play-icon:before {
    color: #00a4d0;
}
@media screen and (max-width: 767px) {
    .asset-video.asset-video {
        height: 250px;
    }
    .embed-responsive.asset-video {
        height: auto;
    }
}

.video.is-playing .video__play {
    opacity: 0;
}

    /* poster images */
.video-iframe__poster{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border: 0;
    outline: none;
    background-position: 50% 50%;
    background-size: cover;
    overflow: hidden;
    opacity: 1;
    transition: opacity 800ms, height 0s;
    transition-delay: 0s, 0s;
}
.video-iframe__poster__play{
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100px;
    height: 100px;
    border-radius:100%;
    border: 2px solid #fff;
    transition: border-color 300ms ease-in-out;
}
.video-iframe__poster__play:before {
    content: "\e001";
    font-family: VideoJS;
    text-align: center;
    position: absolute;
    line-height: 1;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-40%);
    color: #fff;
    font-size: 65px;
    transition: color 250ms ease-in-out;
}
.video-iframe__poster:hover .video-iframe__poster__play {
    border-color: #00a4d0;
}
.video-iframe__poster:hover .video-iframe__poster__play:before{
    color: #00a4d0;
}
.video-iframe__poster__play .icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    margin-left: 2px;
}
.is-active .video-iframe__poster {
    opacity: 0;
    height: 0;
    transition-delay: 0s, 800ms;
}