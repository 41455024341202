.teaser {
    position: relative;
    font-size: 16px;
}
.teaser__body {
    padding: 20px;
    min-height: 201px;
    line-height: 24px;
}
@media screen and (min-width: 768px){
    .teaser__body {
        min-height: 205px;
    }
}
.teaser__body--arrange {
    height: 100%;
}
.teaser__img .icon-circle-arrow, .vertical-teaser__img .icon-circle-arrow{
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 1;
}
.teaser__img__cat {
    position: absolute;
    right: 14px;
    bottom: 16px;
    z-index: 1;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    font-size: 18px;
    padding: 6px 0;
}
.teaser__img__btn {
    position: relative;
    z-index: 1;
    padding: 8px 20px;
}
.teaser:hover .img-overlay:after {
    opacity: 0;
}
.teaser__body h2 {
    margin-bottom: 0;
}
.teaser__body-subline {
    margin-bottom: 15px;
}
.teaser h3.teaser__headline {
    font-family: 'RobotoLight', 'RobotoLight-latin-ext', 'RobotoLight-cyrillic', sans-serif;
    font-weight: 400;
    margin-bottom: 5px;
}

.teaser h4.teaser__subline {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
}

/*Image teaser*/
.image-teaser__body {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 62px 15px 15px;
    z-index: 1;
    white-space: normal;
}

.image-teaser__btn{
    position: absolute;
    right: 10px;
    bottom: 10px;
}

@media screen and (min-width: 768px){
    .image-teaser__btn {
        right: 15px;
    }
}
/*List Teaser*/
.list-teaser, .stage-teaser {
    padding: 10px 0;
    border-top: 1px solid #ced2d9;
}
.list-teaser:last-child, .stage-teaser:last-child {
    border-bottom: 1px solid #ced2d9;
}
.list-teaser .teaser__body {
    padding: 10px 0 10px 20px;
    min-height: inherit;
}
.list-teaser__link {
    position: absolute;
    right: 15px;
    bottom: -10px;
}
.list__teaser__list li {
    padding-right: 40px;
}
.list-teaser .teaser__body h2 {
    margin-bottom: 5px;
}
/*stage teaser*/
.stage-teaser {
    padding-bottom: 20px;
}
.stage-teaser__left {
    padding: 6px 0;
}
.stage-teaser .teaser__body {
    padding: 0;
}
.stage-teaser .teaser__body .collapse.teaser--collapse.in,
.stage-teaser .teaser__body .teaser--collapse.collapsing{
    display: inline;
}
.stage-teaser__list li {
    padding-left: 0;
    padding-right: 25px;
}
.stage-teaser__list li:last-child {
    padding-right: 0;
}
.stage-teaser__count {
    padding: 12px;
    border-radius: 100%;
    width: 47px;
    height: 47px;
    position: absolute;
    right: 15px;
    top: 3px;
}
.stage-teaser .list-inline {
    margin-left: 0;
}

/*Vertical teaser*/
.vertical-teaser .teaser__body {
    padding: 20px 25px;
}

/*picture teaser*/
.picture-teaser .teaser__body {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 0;
}
.picture-teaser .teaser__body {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC40NSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.6)));
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%);
    background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%);
    background: -ms-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#73000000',GradientType=0 );
}
.picture-teaser .special-mark, .gallery .special-mark, .webcams .special-mark {
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 6px 15px;
}

/*brochure teaser*/
.brochure-teaser {
    padding: 10px 30px;
    border-top: 1px solid #ced2d9;
}
.brochure-teaser-row:last-child .brochure-teaser {
    border-bottom: 1px solid #ced2d9;
}
.brochure-teaser__img {
    padding: 15px 25px;
    background-color: #dfe2e6;
}
.brochure-teaser__body {
    padding: 14px 0;
}
@media screen and (min-width: 768px){
    .list-teaser__link {
        position: absolute;
        right: 28px;
        bottom: 16px;
    }
    .list-teaser .teaser__body__right {
        text-align: right;
    }
    .picture-teaser--top {
        height: 56px;
        position: relative;
    }
    .picture-teaser--top h3 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin-bottom: 0;
    }
}

.copypresstext{
    background: linear-gradient(to bottom,transparent 0,rgba(0,0,0,.8) 75%);
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #ffffff;
    height: 50px;
    padding: 16px;
    font-size: 14px;
}