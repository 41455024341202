.text-block {
    font-size: 18px;
    padding: 30px 0 20px;
}
@media screen and (min-width: 768px){
    .text-block {
        font-size: 20px;
        padding: 40px 0;
    }
}
@media screen and (min-width: 1200px){
    .text-block {
        font-size: 25px;
    }
}
.text-block__title {
    font-size: 28px;
    line-height:calc(44/50);
    margin-bottom: 0;
    color: #404040;
}
@media screen and (min-width: 768px){
    .text-block__title {
        font-size: 38px;
    }
}
@media screen and (min-width: 1200px){
    .text-block__title {
        font-size: 50px;
    }
}
.text-block__hashtag {
    font-size: 32px;
    line-height: 1.1;
    margin-bottom: 5px;
}
@media screen and (min-width: 768px){
    .text-block__hashtag {
        font-size: 50px;
    }
}
@media screen and (min-width: 1200px){
    .text-block__hashtag {
        font-size: 64px;
    }
}
.text-block__btn {
    margin-top: 10px;
}
@media screen and (min-width: 768px) {
    .text-block__btn {
        margin-top: 20px;
    }
}

/*large textblock*/
.text-block-lg {
    padding: 40px 0;
}
@media screen and (min-width: 768px){
    .text-block-lg {
        padding: 70px 0;
    }
}
@media screen and (min-width: 1200px){
    .text-block-lg {
        padding: 170px 0;
    }
}
.text-block-lg .text-block__hashtag {
    font-size: 25px;
    margin-bottom: 10px;
}
@media screen and (min-width: 768px){
    .text-block-lg .text-block__hashtag {
        font-size: 32px;
    }
}
.text-block-lg .text-block__title {
    font-size: 30px;
    margin-bottom: 22px;
}
@media screen and (min-width: 768px){
    .text-block-lg .text-block__title {
        font-size: 50px;
    }
}
.text-block-lg .text-block__subline {
    font-size: 20px;
    margin-bottom: 5px;
}
@media screen and (min-width: 768px){
    .text-block-lg .text-block__subline {
        font-size: 25px;
    }
}
.text-block-lg .text-block__btn {
    margin-top: 20px;
}
@media screen and (min-width: 768px){
    .text-block-lg .text-block__btn {
        margin-top: 55px;
        font-size: 18px;
        padding: 13px 40px 10px;
    }
}

/*hero img text block*/
.hero-img .text-block__title,
.hero-img .text-block__hashtag {
    color: #fff;
}
@media screen and (max-width: 767px) {
    .hero-img .text-block {
        font-size: 14px;
    }
    .hero-img .text-block__hashtag {
        font-size: 25px;
    }
    .hero-img .text-block__title {
        font-size: 22px;
        margin-bottom: 5px;
    }
    .hero-img .text-block__btn {
        padding: 10px 15px;
    }
}