/*.lg-backdrop {
    background: #e7e9ec;
}
.lg-next, .lg-prev,
.lg-next, .lg-next {
    background: none;
}*/
/*.lightbox__close-bar.lightbox__close-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 45px;
    padding: 2px 0;
    cursor: pointer;
    color: #fff;
    background: #00a4d0;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYTRkMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjYmQzMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(left,  #00a4d0 0%, #cbd300 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,#00a4d0), color-stop(100%,#cbd300));
    background: -webkit-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: -o-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: -ms-linear-gradient(left,  #00a4d0 0%,#cbd300 100%);
    background: linear-gradient(to right,  #00a4d0 0%,#cbd300 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a4d0', endColorstr='#cbd300',GradientType=1 );
    font-size: 16px;
}
.lightbox__logo {
    position: fixed;
    left: 10px;
    top: 70px;
    cursor: default;
    width: 140px;
}*/
.js-lightbox-overlay {
    position: relative;
}
.js-lightbox-overlay:before {
    content: "\E027";
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    position: absolute;
    top: 100%;
    left: 100%;
    margin-top: -44px;
    margin-left: -44px;
    padding: 0 5px;
    font-size: 24px;
    color: #fff;
    z-index: 1;
    transition: 300ms ease-in-out;
}
.js-lightbox-overlay:hover:before {
    font-size: 100px;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    transform: rotate(360deg);
}
.js-lightbox-overlay.video:before {
    content: "\E051";
}
.js-lightbox-overlay:after {
    content: '';
    background: #00a4d0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 250ms ease-in;
}
.js-lightbox-overlay:hover:after{
    opacity: 0.7;
}

@media screen and (max-width: 767px){
    .lg-next, .lg-prev{
        font-size: 16px;
        padding: 5px 7px 6px;
        background-color: rgb(0 0 0 / 24%)
    }
}

.lg-toolbar .lg-close:after {
    content: var(--icon-close);
    font-family: 'iconfont';
}

.lg-prev {
    transform: rotate(180deg);

    &:after {
        content: var(--icon-arrow-big);
        font-family: 'iconfont';
    }
}

.lg-next:before {
    content: var(--icon-arrow-big);
    font-family: 'iconfont';
}