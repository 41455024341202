/*Dropdown*/
.main-nav__dropdown {
    display: none;
    font-size: 15px;
    font-weight: 400;
    padding: 5px 0;
    z-index: 5;
    text-transform: none;
    color: #292929;
}
.main-nav__dropdown.main-nav__dropdown-minheight{
    min-height: calc(472rem/17);
}
.main-nav__dropdown.main-nav__dropdown li {
    position: relative;
}
.main-nav__dropdown li:hover {
    color: #00a4d0;
}
.main-nav__dropdown ul {
    padding-left: 0;
    list-style: none;
}
.main-nav__dropdown.main-nav__dropdown li>ul>li {
    color: #292929;
    font-family: 'RobotoLight', 'RobotoLight-latin-ext', 'RobotoLight-cyrillic', sans-serif;
    font-weight: 400;
}
.main-nav__dropdown.main-nav__dropdown li>ul>li a {
    position: relative;
}
.main-nav__dropdown.main-nav__dropdown li>ul>li a:hover {
    color: #00a4d0;
}
.main-nav__dropdown-collapse{
    border: none;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: flex-start;
    line-height: 1;
}
.main-nav__dropdown-collapse[aria-expanded="true"]{
    color: var(--color-green-dark);
}
.main-nav__collapse-list > li{
    font-family: var(--font-default);
    padding-left: calc(18rem/17);
}
.main-nav__dropdown ul ul.main-nav__collapse-list a{
    color: #0082a4;
}
.main-nav__dropdown.main-nav__dropdown .main-nav__collapse-list li:last-child{
    margin-bottom: 0;
}
.main-nav__dropdown-icon{
    margin-left: calc(11rem/17);
    font-size: calc(12rem/17);
    color: var(--color-green-dark);
    transition: transform 200ms ease-in-out;
}
.main-nav__dropdown-collapse[aria-expanded="true"] .main-nav__dropdown-icon{
    transform: rotate(270deg);
}
.main-nav__dropdown--fourteaser{
    margin-left: calc(-40rem/17);
    margin-right: calc(-40rem/17);
   @media screen and (max-width: 768px) {
       padding-bottom: 100px;
   }
}
@media screen and (min-width: 768px) {
    .main-nav__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        padding: 35px 25px 15px;
        min-width: 100%;
        white-space: nowrap;
        background: #fff;
        border-top: 1px solid #dfe2e6;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        display: block;
        visibility: hidden;
        opacity: 0;
    }
    .wide-dropdown .main-nav__dropdown {
        padding: 35px 20px 25px;
        width: 100%;
    }
    .main-nav__dropdown--small .container {
        max-width: 930px;
    }
    /*li:hover>.main-nav__dropdown*/
    .nav>li.is-open .main-nav__dropdown {
        transition: opacity 250ms ease-in-out;
        visibility: visible;
        opacity: 1;
    }
    .navbar-main .wide-dropdown {
        position: static;
    }
    .main-nav__dropdown ul {
        text-align: left;
    }
    .main-nav__dropdown ul.two-col {
        -webkit-column-count: 2;
    }
    .navbar-nav>li>a:after, .navbar-nav>li>a:after {
        content: '';
        position: absolute;
        bottom: -30px;
        left: 50%;
        margin-left: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent #e7e9ec transparent;
        transform: scale(0);
        opacity: 0;
        transform-origin: bottom center;
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
    }
    .navbar-nav>li>a:focus-visible {
        color: var(--color-green-dark);
    }

    .navbar-nav>li.is-open a:after {
        transform: scale(1);
        opacity: 1;
    }
    .main-nav__dropdown h2 {
        margin-bottom: 28px;
        text-align: left;
    }
    .main-nav__dropdown.main-nav__dropdown li {
        margin-bottom: 14px;
    }
    .main-nav__dropdown.main-nav__dropdown li.main-nav__dropdown-item-lg {
        margin-bottom: 17px;
    }
    .main-nav__dropdown ul ul {
        margin-top: 14px;
    }
    .main-nav__dropdown ul ul a {
        color: #006390;
    }
    .main-nav__dropdown ul ul a:hover {
        color: #00a4d0;
    }
    .main-nav__dropdown.main-nav__dropdown li>ul>li {
        padding-left: 0;
    }
    .main-nav__dropdown.main-nav__dropdown li>ul>li:before {
        content: none;
    }
    .main-nav__dropdown-close{
        display: none;
    }
    .main-nav__dropdown-title{
        display: none;
    }
}