.breadcrumb {
    background: none;
    margin-bottom: 0;
    border-radius: 0;
    padding: 0;
}
.breadcrumb li {
    color: #86898d;
}
.breadcrumb>li+li:before {
    color: #86898d;
}
@media screen and (min-width: 768px){
    .breadcrumb>li+li:before {
        padding: 0 15px;
    }
    .breadcrumb-row {
        padding-left: 90px;
        height: 60px;
    }
}
@media screen and (min-width: 1200px){
    .breadcrumb-row {
        padding-left: 120px;
    }
}
